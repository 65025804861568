import { Component } from '@angular/core';

@Component({
  selector: 'aff-goa-clerk-landing',
  templateUrl: './goa-clerk-landing.component.html',
  styleUrls: ['./goa-clerk-landing.component.scss']
})
export class GoaClerkLandingComponent {

}
