import { Expose } from 'class-transformer';
import { IsDefined, IsNumber } from 'class-validator';

export class Income {
  @Expose()
  @IsDefined()
  @IsNumber()
  totalIncome: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  splitPensionIncomeDeduction: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  tuitionFeesDeduction: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  medicalExpensesDeduction: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  medicalExpensesOtherDependentsDeduction: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  totalIncomeSpouse: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  splitPensionIncomeDeductionSpouse: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  tuitionFeesDeductionSpouse: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  medicalExpensesDeductionSpouse: number;

  @Expose()
  @IsDefined()
  @IsNumber()
  medicalExpensesOtherDependentsDeductionSpouse: number;
}
