import { Expose, Type } from 'class-transformer';
import { IsNotEmpty, ValidateIf, ValidateNested } from 'class-validator';

import { PaymentMethod } from '../constants/payment-methods';
import { IsBankAccountValid } from '../validators/bank-account.validator';
import { Cheque } from './cheque.entity';
import { DirectDeposit } from './direct-deposit.entity';

export class BankingInfo {
  @Expose()
  @IsNotEmpty()
  paymentMethod: string;

  @Expose()
  @ValidateIf((o) => o.paymentMethod === PaymentMethod.DirectDeposit.code)
  @ValidateNested()
  @IsBankAccountValid()
  @Type(() => DirectDeposit)
  directDeposit?: DirectDeposit;

  @Expose()
  @ValidateIf((o) => o.paymentMethod === PaymentMethod.Cheque.code)
  @ValidateNested()
  @Type(() => Cheque)
  cheque?: Cheque;
}
