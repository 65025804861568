export const Features: { [name: string]: string } = {
  ClerkSearch: 'clerk-search',
  AddDependent: 'add-dependent',
  EditMailingAddress: 'edit-mailing-address',
  SelfServeEditMailingAddress: 'self-serve-edit-mailing-address',
  FlippeningCallout: 'flippening-callout',
  IsTraining: 'is-training',
  TrainingData: 'training-data',
  RedirectOnDuplicates: 'redirect-on-duplicates',
  ResolutionClerkEditPersonalInfo: 'resolution-clerk-edit-personal-info',
  ResolutionClerkEditPersonalInfoComplete: 'resolution-clerk-edit-personal-info-complete',
  ResolutionClerkEditSpouse: 'resolution-clerk-edit-spouse',
  ResolutionClerkEditDependents: 'resolution-clerk-edit-dependents',
  SunsetDate: 'sunset-date',
  HasSunset: 'hasSunset',
};
