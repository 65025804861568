import { Component, OnInit, Input } from '@angular/core';
// import { AppConfigService } from '../ui-shared-components.services';
import { AppConfigService } from '../shared/services/config/config.service';

@Component({
  selector: 'common-environment-header',
  templateUrl: './environment-header.component.html',
  styleUrls: ['./environment-header.component.scss'],
})
export class EnvironmentHeaderComponent implements OnInit {
  /**
   * Has this been dismissed
   */
  _dismissed = false;

  /**
   * The name to show as the environment header
   */
  _environmentName: string;

  @Input() applicationVersion: string;

  constructor(private configService: AppConfigService) {}

  ngOnInit(): void {
    this._environmentName = this.configService.getConfig().ENVIRONMENT_NAME;
  }

  get environmentNameVersion(){
    let _environmentVersionName = "";
    if (this.applicationVersion){
      _environmentVersionName = `${this._environmentName} - v${this.applicationVersion}`;
    }else{
      _environmentVersionName = this._environmentName;
    }
    return _environmentVersionName;
  }

  dismiss() {
    this._dismissed = true;
  }
}
