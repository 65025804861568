import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { AuthenticationService } from '../../services/authentication/authentication-service';
import { ContextProvider } from '../../services/context-provider/context.provider';
import * as Utils from '../../helpers/utils';
import { User } from '@common/entities';

@Injectable({
  providedIn: 'root',
})

//All this does is load the contextProfile so that we can still show logged in user information on public pages
//my-account menu component shows up on all pages so we need contextProfile
//cypress tests are also pull user information in the my-account bar for public pages too
export class AnonymousGuard extends KeycloakAuthGuard {

  constructor(
    protected router: Router,
    protected readonly authenticationService: AuthenticationService,
    private contextProvider: ContextProvider,
    protected readonly keycloakService: KeycloakService
  ) {
    super(router, keycloakService);
  }

  public async isAccessAllowed(): Promise<boolean> {

    if (this.authenticated) {
      const user = await this.authenticationService.getContextUserProfile();
      this.contextProvider.setCurrentUser(user);
    }

    return true;
  }
}
