import { formatNumber } from '@angular/common';
import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({ selector: '[iesNumberOnly]' })
export class NumberOnlyDirective {
  @Input() wholeNumbersOnly = false;
  @Input() showAsCurrency = false;
  @Input() allowNegative = false;

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  isNumberKeyWithinDecimalLimit(event) {
    const validKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']; //KeyboardEvent.key gives strings
    return validKeys.indexOf(event.key) >= 0 && this.hasMaxTwoFractionalDigits(event);
  }

  private hasMaxTwoFractionalDigits(event): boolean {
    const value = event.srcElement.value;
    const decimalPosition = value?.indexOf('.') ?? -1;
    const currentPosition = event.srcElement.selectionStart;
    return decimalPosition < 0 || currentPosition <= decimalPosition || value.substr(decimalPosition).length <= 2;
  }

  isNavKey(event) {
    const validKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];
    return validKeys.indexOf(event.key) >= 0;
  }

  isCutCtrlCopyPaste(event) {
    const validKeys = ['a', 'c', 'v', 'x'];
    return validKeys.indexOf(event.key) >= 0 && (event.ctrlKey || event.metaKey);
  }

  isSpecialKey(event) {
    const validKeys = ['Escape', 'Enter', 'Delete', 'Backspace'];
    return validKeys.indexOf(event.key) >= 0;
  }

  isAcceptableDecimalKey(event) {
    const validKeys = ['Decimal', '.'];
    return (
      validKeys.indexOf(event.key) >= 0 &&
      !this.wholeNumbersOnly &&
      //there can be only one decimal
      event.srcElement.value?.indexOf('.') <= 0
    );
  }

  private isAcceptableNegative(event) {
    return this.allowNegative && event.key === '-' && event.srcElement.selectionStart === 0;
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    if (
      this.isNumberKeyWithinDecimalLimit(event) ||
      this.isNavKey(event) ||
      this.isCutCtrlCopyPaste(event) ||
      this.isSpecialKey(event) ||
      this.isAcceptableDecimalKey(event) ||
      this.isAcceptableNegative(event)
    )
      return;

    event.preventDefault();
  }

  @HostListener('paste', ['$event']) onPaste(event) {
    const e = event as ClipboardEvent;
    const pastedData = e.clipboardData.getData('Text');
    if (isNaN(Number(pastedData))) e.preventDefault();
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    if (this.showAsCurrency) {
      this.elementRef.nativeElement.value = value.replace(/[^0-9.]+/g, '');
      this.elementRef.nativeElement.select();
    }
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    if (this.showAsCurrency)
      this.elementRef.nativeElement.value = value === null || value === '' ? '' : formatNumber(value, 'en-ca', '1.2-2');
  }
}
