<div class="my-account-wrapper">
  <div class="profile-wrapper" *ngIf="profile; else signInBtn">
    <span class="user-name">{{ profile.name }} |</span>
    <button class="link-btn" (click)="logout()">Sign out</button>
  </div>
  <ng-template #signInBtn>
    <div class="profile-wrapper" *ngIf="isSignInButtonVisible()">
      <button class="link-btn" (click)="signIn()">Sign in</button>
    </div>
  </ng-template>
</div>
