import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import { AppConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class KeycloakTokenInitService {
  destroy$ = new Subject<void>();

  constructor(private configService: AppConfigService, private keycloakService: KeycloakService) {}

  async initKeyCloak(kcResult): Promise<boolean> {
    await this.configService.loadConfig();
    const kcConfig = this.configService.getConfig();
    const isAuthenticated = await this.keycloakService.init({
      config: {
        url: kcConfig.KEYCLOAK.URL,
        realm: kcConfig.KEYCLOAK.REALM,
        clientId: kcConfig.KEYCLOAK.CLIENTID,
      },
      bearerPrefix: '',
      initOptions: {
        // enableLogging: true,
        checkLoginIframe: false,
        token: kcResult.access_token,
        refreshToken: kcResult.refresh_token,
      },
    });

    return isAuthenticated;
  }
}
