<div class="print-btn">
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.56063 1.875L6.5625 1.875H13.4375L13.4394 1.875C14.0185 1.87673 14.5734 2.10756 14.9829 2.51707C15.3924 2.92658 15.6233 3.4815 15.625 4.06063L15.625 4.0625V4.375H15.9375L15.9394 4.375C16.5185 4.37673 17.0734 4.60756 17.4829 5.01707C17.8924 5.42658 18.1233 5.9815 18.125 6.56063L18.125 6.5625V12.8125L18.125 12.8144C18.1233 13.3935 17.8924 13.9484 17.4829 14.3579C17.0734 14.7674 16.5185 14.9983 15.9394 15L15.9375 15H15.625V16.55C15.625 17.4198 14.9198 18.125 14.05 18.125H5.95C5.08015 18.125 4.375 17.4198 4.375 16.55V15H4.0625L4.06063 15C3.4815 14.9983 2.92658 14.7674 2.51707 14.3579C2.10756 13.9484 1.87673 13.3935 1.875 12.8144L1.875 12.8125V6.5625L1.875 6.56063C1.87673 5.9815 2.10756 5.42658 2.51707 5.01707C2.92658 4.60756 3.4815 4.37673 4.06063 4.375L4.0625 4.375H4.375V4.0625L4.375 4.06063C4.37673 3.4815 4.60756 2.92658 5.01707 2.51707C5.42658 2.10756 5.9815 1.87673 6.56063 1.875ZM5.625 4.375H14.375V4.06366C14.3741 3.81501 14.2749 3.57679 14.099 3.40095C13.9232 3.22514 13.6851 3.12596 13.4365 3.125H6.56355C6.31494 3.12596 6.07677 3.22514 5.90095 3.40095C5.72513 3.57677 5.62595 3.81496 5.625 4.06358V4.375ZM4.375 13.75V10.325C4.375 9.45515 5.08015 8.75 5.95 8.75H14.05C14.9198 8.75 15.625 9.45515 15.625 10.325V13.75H15.9363C16.185 13.7491 16.4232 13.6499 16.599 13.474C16.7749 13.2982 16.8741 13.06 16.875 12.8113V6.56366C16.8741 6.31501 16.7749 6.07679 16.599 5.90095C16.4232 5.72513 16.185 5.62595 15.9364 5.625H4.06358C3.81496 5.62595 3.57677 5.72513 3.40095 5.90095C3.22514 6.07677 3.12596 6.31494 3.125 6.56355V12.8115C3.12596 13.0601 3.22514 13.2982 3.40095 13.474C3.57679 13.6499 3.81501 13.7491 4.06366 13.75H4.375ZM5.95 10C5.77051 10 5.625 10.1455 5.625 10.325V16.55C5.625 16.7295 5.77051 16.875 5.95 16.875H14.05C14.2295 16.875 14.375 16.7295 14.375 16.55V10.325C14.375 10.1455 14.2295 10 14.05 10H5.95Z"
      fill="#0070C4"
    />
    <path
      d="M15.3125 8.125C15.8303 8.125 16.25 7.70527 16.25 7.1875C16.25 6.66973 15.8303 6.25 15.3125 6.25C14.7947 6.25 14.375 6.66973 14.375 7.1875C14.375 7.70527 14.7947 8.125 15.3125 8.125Z"
      fill="#0070C4"
    />
  </svg>
  <a data-cy="print-btn" (click)="onClick()"> {{ title }}</a>
</div>
