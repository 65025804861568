import { Expose } from 'class-transformer';

export class ResolutionAdjustmentLog {
  @Expose()
  applicationId: string;
  @Expose()
  adjustmentType: string;
  @Expose()
  adjustmentDate: string;
  @Expose()
  adjustedBy: string;
}
