<div
  class="client-intake-form-control"
  [ngClass]="{ 'has-error': hasError }"
  [attr.aria-invalid]="hasError ? 'true' : 'false'"
>
  <ng-content *ngTemplateOutlet="content"></ng-content>

  <ng-template #content>
    <span *ngIf="!hideLabel" class="span-label label" [attr.aria-label]="label">{{ _label }}</span>
    <common-info-icon
      *ngIf="container && info"
      [containerElement]="container"
      [messageElement]="info"
    ></common-info-icon>
    <span class="optional-label" *ngIf="isOptional && !hideOptionalLabel">(Optional)</span>
    <common-info-icon-tooltip *ngIf="infoTooltip" [message]="infoTooltip"></common-info-icon-tooltip>
    <common-help-icon-tooltip
      *ngIf="helpTooltip"
      [title]="helpTitle"
      [content]="helpTooltip"
    ></common-help-icon-tooltip>
    <div class="helper-text" *ngIf="helperText">
      {{ helperText }}
    </div>
    <!-- some content is requiring this as inline/inline-block-->
    <div class="content">
      <ng-content></ng-content>
    </div>
    <div class="helper-message" *ngIf="helperMessage">
      {{ helperMessage }}
    </div>
  </ng-template>
  <div class="error-text" *ngIf="hasError && showErrorMessages && customErrorMessage">{{ customErrorMessage }}</div>
  <div class="error-text" *ngIf="hasError && showErrorMessages && !customErrorMessage && useDefaultErrorMessages">
    <ng-container *ngIf="errors.customMessage"><div [innerHTML]="errors.customMessage"></div></ng-container>
    <ng-container *ngIf="errors.required">{{ abbreviatedLabel }} is required.</ng-container>
    <ng-container
      *ngIf="
        errors.mask ||
        errors.mod10Validation ||
        errors.invalidPostalCode ||
        errors.email ||
        errors.pattern ||
        errors.serviceNowTicket
      "
      >{{ abbreviatedLabel }} is invalid.</ng-container
    >
    <ng-container *ngIf="errors.legalName">{{ abbreviatedLabel }} cannot contain numbers or symbols.</ng-container>

    <ng-container *ngIf="!!errors.minlength">
      {{ abbreviatedLabel }} is invalid. Minimum length required is {{ errors.minlength.requiredLength }}, current
      length is
      {{ errors.minlength.actualLength }}
    </ng-container>
    <ng-container *ngIf="!!errors.maxlength">
      {{ abbreviatedLabel }} is invalid. Maximum length allowed is {{ errors.maxlength.requiredLength }}, current length
      is
      {{ errors.maxlength.actualLength }}
    </ng-container>
    <ng-container *ngIf="errors.minDate">
      <ng-container *ngIf="errors.minDate.message">
        {{ errors.minDate.message }}
      </ng-container>
      <ng-container *ngIf="!errors.minDate.message">
        {{ abbreviatedLabel }} is invalid. It cannot be less than
        {{ errors.minDate.label || (errors.minDate.min | date: 'mediumDate':'UTC') }}
      </ng-container>
    </ng-container>
    <ng-container *ngIf="errors.maxDate">
      <ng-container *ngIf="errors.maxDate.message">
        {{ errors.maxDate.message }}
      </ng-container>
      <ng-container *ngIf="!errors.maxDate.message">
        {{ abbreviatedLabel }} is invalid. It cannot be greater than
        {{ errors.maxDate.label || (errors.maxDate.max | date: 'mediumDate':'UTC') }}
      </ng-container>
    </ng-container>

    <ng-container *ngIf="errors.shouldMatch"
      >{{ abbreviatedLabel }} is invalid. It should match {{ errors.shouldMatch.label }}.</ng-container
    >
    <ng-container *ngIf="errors.shouldNotMatch"
      >{{ abbreviatedLabel }} is invalid. It should not match {{ errors.shouldNotMatch.label }}.</ng-container
    >
    <ng-container *ngIf="errors.invalid_email">Invalid email format</ng-container>
    <ng-container *ngIf="errors.nonUniqueUserEmail">User already exists</ng-container>
    <ng-container *ngIf="errors.invalidIdLength">{{ errors.invalidIdLength }}</ng-container>
  </div>
</div>
