import { Component } from '@angular/core';

@Component({
  selector: 'aff-roads-clerk-landing',
  templateUrl: './roads-clerk-landing.component.html',
  styleUrls: ['./roads-clerk-landing.component.scss']
})
export class RoadsClerkLandingComponent {

}
