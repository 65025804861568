import { AffApplyRoutes, ResolutionRoutes } from '@aff-apply/entities';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MaintenanceRedirectGuard } from '@common/ui/maintenance-mode';
import {
  CognitoAuthenticationGuard,
  CognitoLogoutGuard,
  KeycloakLoginGuard,
  SecurityTxtRedirectGuard,
  KeycloakTokenExchangeGuard,
} from '@common/ui/shared-components';

import { ApplicationComponent } from './components/application/application.component';
import { GoaClerkLandingComponent } from './features/landing/components/goa-clerk-landing/goa-clerk-landing.component';
import { RoadsClerkLandingComponent } from './features/landing/components/roads-clerk-landing/roads-clerk-landing.component';
import { NotAuthorizedPageComponent } from './pages/authentication/not-authorized-page/not-authorized-page.component';
import { ServiceErrorPageComponent } from './pages/service-error-page/service-error-page.component';
import { ApplicationErrorPageComponent } from './pages/application-error-page/application-error-page.component';
import { ProgramClosedRedirectGuard } from './shared/program-closed-redirect.guard';
import { ProgramClosedPageComponent } from './pages/program-closed-page/program-closed-page.component';
import { UserDashboardPageComponent } from './features/user-dashboard/pages/user-dashboard-page/user-dashboard-page.component';
import { LandingComponent } from './features/landing/components/landing/landing.component';
import { UpgradeAccountComponent } from './features/landing/components/upgrade/upgrade.component';

export const ROUTES: Routes = [
  {
    path: '',
    component: ApplicationComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        //component: LandingComponent,
        //canActivate: [AuthenticationGuard],
        redirectTo: AffApplyRoutes.Welcome.baseUrl,
      },
      {
        path: '.well-known/security.txt',
        pathMatch: 'full',
        canActivate: [SecurityTxtRedirectGuard],
        // We need a component here because we cannot define the route otherwise
        component: SecurityTxtRedirectGuard,
      },
      {
        path: 'logout',
        pathMatch: 'full',
        component: NotAuthorizedPageComponent,
        canActivate: [CognitoLogoutGuard],
      },
      {
        path: AffApplyRoutes.ServiceError.baseUrl,
        pathMatch: 'full',
        component: ServiceErrorPageComponent,
      },
      {
        path: AffApplyRoutes.ApplicationError.baseUrl + '/:applicationCode',
        component: ApplicationErrorPageComponent,
      },
      {
        path: AffApplyRoutes.NotAuthorized.baseUrl,
        component: NotAuthorizedPageComponent,
      },
      {
        path: AffApplyRoutes.Welcome.baseUrl,
        component: LandingComponent,
        canActivate: [MaintenanceRedirectGuard],
      },
      {
        path: AffApplyRoutes.UserDashboard.baseUrl,
        component: UserDashboardPageComponent,
        canActivate: [CognitoAuthenticationGuard, MaintenanceRedirectGuard],
      },
      {
        path: AffApplyRoutes.UpgradeAccount.baseUrl,
        component: UpgradeAccountComponent,
        canActivate: [MaintenanceRedirectGuard],
      },
      {
        path: AffApplyRoutes.GoaClerkLanding.baseUrl,
        component: GoaClerkLandingComponent,
        canActivate: [MaintenanceRedirectGuard],
      },
      {
        path: AffApplyRoutes.GoaResClerkLanding.baseUrl,
        component: GoaClerkLandingComponent,
        canActivate: [MaintenanceRedirectGuard],
      },
      {
        path: AffApplyRoutes.RoadsClerkLanding.baseUrl,
        component: RoadsClerkLandingComponent,
        canActivate: [MaintenanceRedirectGuard],
      },
      {
        path: AffApplyRoutes.GoaApply.baseUrl,
        canActivate: [MaintenanceRedirectGuard, KeycloakLoginGuard],
        canActivateChild: [KeycloakLoginGuard],
        loadChildren: () =>
          import('./features/my-application/my-application.module').then((module) => module.MyApplicationModule),
      },
      {
        path: AffApplyRoutes.ClerkApply.baseUrl,
        canActivate: [MaintenanceRedirectGuard, KeycloakTokenExchangeGuard],
        canActivateChild: [KeycloakTokenExchangeGuard],
        loadChildren: () =>
          import('./features/my-application/my-application.module').then((module) => module.MyApplicationModule),
      },
      {
        path: AffApplyRoutes.Apply.baseUrl,
        canActivate: [CognitoAuthenticationGuard, MaintenanceRedirectGuard, ProgramClosedRedirectGuard],
        loadChildren: () =>
          import('./features/my-application/my-application.module').then((module) => module.MyApplicationModule),
        // canActivate: [AuthenticationGuard, AuthorizationGuard],
      },
      {
        path: AffApplyRoutes.ClerkAppPrescreen.baseUrl,
        canActivate: [MaintenanceRedirectGuard, KeycloakTokenExchangeGuard],
        canActivateChild: [KeycloakTokenExchangeGuard],
        loadChildren: () => import('./features/prescreen/prescreen.module').then((module) => module.PrescreenModule),
      },
      {
        path: AffApplyRoutes.GoaAppPrescreen.baseUrl,
        canActivate: [MaintenanceRedirectGuard, KeycloakLoginGuard],
        canActivateChild: [KeycloakLoginGuard],
        loadChildren: () => import('./features/prescreen/prescreen.module').then((module) => module.PrescreenModule),
      },
      {
        path: AffApplyRoutes.Prescreen.baseUrl,
        canActivate: [MaintenanceRedirectGuard, ProgramClosedRedirectGuard],
        loadChildren: () => import('./features/prescreen/prescreen.module').then((module) => module.PrescreenModule),
      },
      {
        path: AffApplyRoutes.MyAppStatus.baseUrl,
        canActivate: [CognitoAuthenticationGuard, MaintenanceRedirectGuard],
        loadChildren: () =>
          import('./features/my-application-status/my-application-status.module').then(
            (module) => module.MyApplicationStatusModule
          ),
      },
      {
        path: AffApplyRoutes.ClerkSupport.baseUrl,
        canActivate: [MaintenanceRedirectGuard, KeycloakTokenExchangeGuard],
        canActivateChild: [KeycloakTokenExchangeGuard],
        loadChildren: () => import('./features/clerk-support/clerk-support.module').then((m) => m.ClerkSupportModule),
      },
      {
        path: AffApplyRoutes.GoaSupport.baseUrl,
        canActivate: [MaintenanceRedirectGuard, KeycloakLoginGuard],
        canActivateChild: [KeycloakLoginGuard],
        loadChildren: () => import('./features/clerk-support/clerk-support.module').then((m) => m.ClerkSupportModule),
      },
      {
        path: AffApplyRoutes.GoaResClerk.baseUrl,
        canActivate: [MaintenanceRedirectGuard, KeycloakLoginGuard],
        canActivateChild: [KeycloakLoginGuard],
        loadChildren: () => import('./features/clerk-support/clerk-support.module').then((m) => m.ClerkSupportModule),
      },
      {
        path: AffApplyRoutes.GoaResClerk.baseUrl + '/' + ResolutionRoutes.Resolution.baseUrl,
        canActivate: [MaintenanceRedirectGuard, KeycloakLoginGuard],
        canActivateChild: [KeycloakLoginGuard],
        loadChildren: () =>
          import('./features/my-application/my-application.module').then((module) => module.MyApplicationModule),
      },
      {
        path: AffApplyRoutes.ClerkSupport.baseUrl + '/' + AffApplyRoutes.MyAppStatus.baseUrl,
        canActivate: [MaintenanceRedirectGuard, KeycloakLoginGuard],
        canActivateChild: [KeycloakLoginGuard],
        loadChildren: () =>
          import('./features/my-application-status/my-application-status.module').then(
            (module) => module.MyApplicationStatusModule
          ),
      },
      {
        path: AffApplyRoutes.GoaSupport.baseUrl + '/' + AffApplyRoutes.MyAppStatus.baseUrl,
        canActivate: [MaintenanceRedirectGuard, KeycloakLoginGuard],
        canActivateChild: [KeycloakLoginGuard],
        loadChildren: () =>
          import('./features/my-application-status/my-application-status.module').then(
            (module) => module.MyApplicationStatusModule
          ),
      },
      {
        path: AffApplyRoutes.GoaResClerk.baseUrl + '/' + AffApplyRoutes.MyAppStatus.baseUrl,
        canActivate: [MaintenanceRedirectGuard, KeycloakLoginGuard],
        canActivateChild: [KeycloakLoginGuard],
        loadChildren: () =>
          import('./features/my-application-status/my-application-status.module').then(
            (module) => module.MyApplicationStatusModule
          ),
      },
      {
        path: AffApplyRoutes.GoaResClerk.baseUrl + '/' + AffApplyRoutes.OverrideIncomeEligibility.baseUrl,
        canActivate: [MaintenanceRedirectGuard, KeycloakLoginGuard],
        canActivateChild: [KeycloakLoginGuard],
        loadChildren: () =>
          import('./features/override-income-eligibility/override-income-eligibility.module').then(
            (module) => module.OverrideIncomeEligibilityModule
          ),
      },
      { path: AffApplyRoutes.Closed.fullUrl, component: ProgramClosedPageComponent },
    ],
  },
  // {
  //   path: 'clerk-support',
  //   loadChildren: () => import('./features/clerk-support/clerk-support.module').then((m) => m.ClerkSupportModule),
  // },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
  providers: [
    { provide: Window, useValue: window },
    { provide: SecurityTxtRedirectGuard, deps: [Window] },
  ],
})
export class AffApplyRoutingModule {}
