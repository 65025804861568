import { ValidationOptions, ValidationArguments, ValidateBy } from 'class-validator';
import { FifDataService } from '../services/fif-data.service';

export function IsKnownBank(validationOptions?: ValidationOptions): PropertyDecorator {
  return ValidateBy(
    {
      name: 'IsKnownBank',
      validator: {
        validate(value: unknown, args: ValidationArguments): boolean {
          if (!value) return true;
          return !!FifDataService.findBankByNumber(value as string);
        },
        defaultMessage: () => 'No such institution.',
      },
    },
    validationOptions
  );
}
