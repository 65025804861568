import { GraphqlService } from './graphql.service';
import {
  AffApplication,
  ApplicationPayments,
  FindAddressResult,
  FindVerifiedApplicationResult,
  FindVerifiedApplicationsInput,
  IncomeEligibilityOverride,
  PaymentLedger,
  ProcessedApplication,
  RetrieveAddressResult,
  StepperStep,
  Tombstone,
} from '@aff-apply/entities';
import { of, Observable, map, delay, BehaviorSubject } from 'rxjs';
import { Periods } from '@common/constants';
import { TrainingDataConstants } from './training-data/training-data-constants';
import { Router } from '@angular/router';

const AppCode = 'TEST';

export class TrainingGraphqlService extends GraphqlService {
  constructor(
    private graphqlService: GraphqlService,
    private verifiedApplications: Array<ProcessedApplication>,
    router: Router,
    redirectOnDuplicates: boolean
  ) {
    super(router, redirectOnDuplicates);
  }

  private static VerifiedApplicationKey = 'TRAINING:VERIFIED-APP:V01';
  private static ApplicationKey = 'TRAINING:APP:V01';

  private applicationWipSubject = new BehaviorSubject<AffApplication>(null);

  private getState<T>(key: string, defaultValue: T): T {
    let output = localStorage.getItem(key);

    if (!output) {
      output = this.setState(key, defaultValue);
    }

    return JSON.parse(output) as T;
  }

  private setState<T>(key: string, state: T): string {
    const data = JSON.stringify(state);
    localStorage.setItem(key, data);
    return data;
  }

  private getAppById(applicationId: string): AffApplication {
    const app = this.Applications.find((c) => c._id === applicationId);
    if (app) return JSON.parse(JSON.stringify(app));
    return this.parseApplication(app);
  }

  private getProcessedAppById(applicationId: string): AffApplication {
    const app = this.VerifiedApplications.find((c) => c._id === applicationId);
    if (app) return JSON.parse(JSON.stringify(app));
    return this.parseApplication(app);
  }

  public get VerifiedApplications() {
    return this.getState(TrainingGraphqlService.VerifiedApplicationKey, this.verifiedApplications);
  }

  public set VerifiedApplications(value: Array<ProcessedApplication>) {
    this.setState(TrainingGraphqlService.VerifiedApplicationKey, value);
  }

  public get Applications() {
    return this.getState(TrainingGraphqlService.ApplicationKey, []);
  }

  public set Applications(value: Array<AffApplication>) {
    this.setState(TrainingGraphqlService.ApplicationKey, value);
  }

  private mapToFindVerifiedApplicationResult(apps: Array<ProcessedApplication>) {
    return apps.map((a) => {
      const tombstone = {
        firstName: a.applicant.firstName,
        lastName: a.applicant.lastName,
        middleName: a.applicant.middleName,
        birthdate: a.applicant.birthdate,
      } as Tombstone;

      return {
        _id: a._id,
        address: a.applicant.address,
        applicant: tombstone,
        applicationCode: a.applicationCode,
      } as FindVerifiedApplicationResult;
    });
  }

  private mergeProperties(existing, updates, names: Array<string>) {
    names.forEach((name) => {
      if (updates[name]) existing[name] = Object.assign({}, existing[name], updates[name]);
    });
  }

  private updateVerifiedApplications(app) {
    const existingApps = this.VerifiedApplications.filter((c) => c._id !== app._id);
    const apps = [...existingApps, app];
    this.VerifiedApplications = apps;
  }

  private updateApplications(app) {
    const existingApps = this.Applications.filter((c) => c._id !== app._id);
    const apps = [...existingApps, app];
    this.Applications = apps;

    this.applicationWipSubject.next(app);
  }

  private updateApplication(
    affApplication: AffApplication,
    updateFunc: (existing: AffApplication, updates: AffApplication) => void
  ) {
    let isProcessed = true;
    let app = this.getProcessedAppById(affApplication._id);

    if (!app) {
      app = this.getAppById(affApplication._id);
      isProcessed = false;
    }

    updateFunc(app, affApplication);

    if (isProcessed) {
      this.updateVerifiedApplications(app);
    } else {
      this.updateApplications(app);
    }

    return this.wait(of(app));
  }

  private wait(obs$: Observable<AffApplication>): Observable<AffApplication> {
    return obs$.pipe(
      delay(50),
      map((c) => c)
    );
  }

  private getDate(from: string) {
    const dateParts = from.split('-');
    const date = new Date(Number.parseInt(dateParts[0]), Number.parseInt(dateParts[1]), Number.parseInt(dateParts[2]));
    return date;
  }

  private yearsAgo(from: string, years: number): string {
    const date = this.getDate(from);
    date.setFullYear(date.getFullYear() - years);
    return date.toISOString().substring(0, 10);
  }

  private submitApplication(existing: AffApplication, affApplication: AffApplication) {
    const processedApplication = JSON.parse(JSON.stringify(existing)) as ProcessedApplication;

    const actualPayments = {
      applicant: [],
      dependents: [],
      spousePartner: [],
    } as ApplicationPayments;
    processedApplication.adjudication = {
      adjudication: 'audit',
      proposedPayments: actualPayments,
    };

    processedApplication.actualPayments = actualPayments;

    (processedApplication.dependents ?? []).forEach((c, index) => {
      const nextIndex = index + 1;
      c._id = affApplication._id + ':' + nextIndex;
      const dependentPayments = this.generateDependentPayments(affApplication._id, c);
      actualPayments.dependents.push(dependentPayments);
    });

    actualPayments.applicant = this.generatePersonPayment(affApplication._id, processedApplication.applicant);

    if (
      processedApplication?.spousePartner?.isApplyingOnBehalfSpousePartner === true &&
      processedApplication.spousePartner?.isAuthorized === true
    ) {
      actualPayments.spousePartner = this.generatePersonPayment(affApplication._id, processedApplication.spousePartner);
    }

    processedApplication.isProcessed = true;
    processedApplication.processedAt = new Date().toISOString();
    this.updateVerifiedApplications(processedApplication);
  }

  public generatePersonPayment(
    _id: string,
    c: {
      birthdate: string;
      socialInsuranceNumber: string;
    }
  ): Array<PaymentLedger> {
    const payments = [];
    const birthDate = this.getDate(c.birthdate);
    const firstOfTheMonth = new Date(birthDate.getFullYear(), birthDate.getMonth(), 1).toISOString().substring(0, 10);

    Periods.forEach((p) => {
      let amount = 0.0;

      const startDate = this.yearsAgo(p.start, 65);

      if (firstOfTheMonth <= startDate) {
        amount = 100.0;
      }
      payments.push({
        personId: c.socialInsuranceNumber,
        sourceId: null,
        paymentMonth: p.start,
        paymentSource: { name: 'Portal' },
        amount: amount,
        applicationId: _id,
        paymentStatus: 'Pending',
        failedMessage: null,
        holdReason: null,
        revertToCheque: null,
        revertToChequeReason: null,
      });
    });

    return payments;
  }

  public generateDependentPayments(
    _id: string,
    c: {
      birthdate?: string;
      isSharedCustody?: boolean;
      _id: string;
    }
  ): Array<PaymentLedger> {
    const payments = [];
    Periods.forEach((p) => {
      let amount = 0.0;
      if (c.birthdate <= p.end) {
        if (c.isSharedCustody) {
          amount = 50.0;
        } else {
          amount = 100.0;
        }
      }
      payments.push({
        personId: c._id,
        sourceId: null,
        paymentMonth: p.start,
        paymentSource: { name: 'Portal' },
        amount: amount,
        applicationId: _id,
        paymentStatus: 'Pending',
        failedMessage: null,
        holdReason: null,
        revertToCheque: null,
        revertToChequeReason: null,
      });
    });

    return payments;
  }

  findVerifiedApplications(
    findVerifiedApplicationsInput: FindVerifiedApplicationsInput
  ): Observable<Array<FindVerifiedApplicationResult>> {
    const compareParams = {
      sensitivity: 'base',
    };

    const output = this.VerifiedApplications.filter((c) => {
      if (findVerifiedApplicationsInput.applicationCode) {
        return (
          c.applicationCode.localeCompare(findVerifiedApplicationsInput.applicationCode, undefined, compareParams) === 0
        );
      }

      return (
        findVerifiedApplicationsInput.firstName.localeCompare(c.applicant.firstName, undefined, compareParams) === 0 &&
        findVerifiedApplicationsInput.lastName.localeCompare(c.applicant.lastName, undefined, compareParams) === 0 &&
        findVerifiedApplicationsInput.birthdate.localeCompare(c.applicant.birthdate, undefined, compareParams) === 0
      );
    });

    return of(this.mapToFindVerifiedApplicationResult(output));
  }

  getProcessedApplicationById(applicationId: string): Observable<ProcessedApplication> {
    const application = this.getProcessedAppById(applicationId);
    return this.wait(of(application));
  }

  getVerifiedApplicationById(applicationId: string): Observable<AffApplication> {
    const application = this.getProcessedAppById(applicationId) as AffApplication;
    return this.wait(of(application));
  }

  getApplicationById(applicationId: string): Observable<AffApplication> {
    const application = this.getAppById(applicationId);
    return this.wait(of(application));
  }

  getApplicationByIdFailSafe(applicationId: string): Observable<AffApplication> {
    const application = this.getAppById(applicationId);
    return this.wait(of(application));
  }

  getStepperApplicationInfo(applicationId: string): Observable<AffApplication> {
    if (!this.applicationWipSubject.value) {
      const app = this.getAppById(applicationId);
      this.applicationWipSubject.next(app);
    }
    return this.applicationWipSubject.asObservable();
  }

  getStepInfo(applicationId: string, step: StepperStep = null): Observable<AffApplication> {
    const application = this.getAppById(applicationId);
    return this.wait(of(application));
  }

  createApplication(affApplication: AffApplication): Observable<AffApplication> {
    const appCodes = [
      ...this.VerifiedApplications.map((c) => c.applicationCode),
      ...this.Applications.map((c) => c.applicationCode),
    ].map((c) => parseInt(c.replace(AppCode, '')));

    const next = Math.max(...appCodes) + 1;
    const id = AppCode + next.toString().padStart(3, '0');

    affApplication.applicationCode = id;
    affApplication._id = id;

    const now = new Date().toISOString();
    const app = {
      _id: id,
      applicationCode: id,
      mySituation: {
        _id: id,
        isIncomeUnderThreshold: null,
        isMarried: null,
        hasDependentChild: null,
        isSomeoneHelping: null,
        nameOrganisation: null,
        phoneNumber: null,
        email: null,
      },
      proofOfIdentity: null,
      applicant: null,
      spousePartner: null,
      dependents: null,
      income: null,
      bankingInfo: null,
      review: null,
      isSubmitted: null,
      applicationErrors: {
        personalInfoErrors: [],
      },
      createdAt: now,
      createdBy: TrainingDataConstants.auditBy,
      updatedAt: now,
      updatedBy: TrainingDataConstants.auditBy,
    } as unknown as AffApplication;

    this.updateApplications(app);

    return this.wait(of(app));
  }

  updateBankingInfo(affApplication: AffApplication): Observable<AffApplication> {
    return this.updateApplication(affApplication, (existing, updates) => {
      existing.bankingInfo = updates.bankingInfo;
    });
  }

  updateAddressInfo(affApplication: AffApplication): Observable<AffApplication> {
    return this.updateApplication(affApplication, (existing, updates) => {
      existing.applicant.address = updates.applicant.address;
    });
  }

  addDependent(affApplication: AffApplication): Observable<AffApplication> {
    return this.updateApplication(affApplication, (existing, updates) => {
      if (existing['actualPayments']) {
        const proposedPayments = existing['adjudication']['proposedPayments'];
        const actualPayments = existing['actualPayments'];
        (updates.dependents ?? []).forEach((c, index) => {
          existing.dependents = existing.dependents ?? [];

          const nextIndex = existing.dependents.length + 1 + index;
          c._id = affApplication._id + ':' + nextIndex;
          const payments = this.generateDependentPayments(affApplication._id, c);

          actualPayments.dependents.push(payments);
          proposedPayments.dependents.push(payments);
          existing.dependents = [...existing.dependents, ...updates.dependents];
        });
      }
    });
  }

  saveApplication(affApplication: AffApplication): Observable<AffApplication> {
    return this.updateApplication(affApplication, (existing, updates) => {
      this.mergeProperties(existing, updates, [
        'applicant',
        'bankingInfo',
        'income',
        'mySituation',
        'spousePartner',
        'proofOfIdentity',
        'review',
        'applicationErrors',
      ]);

      if (updates.dependents) {
        existing.dependents = [...updates.dependents];
      }

      if (updates.isSubmitted) {
        existing.isSubmitted = updates.isSubmitted;

        this.submitApplication(existing, affApplication);
      }
    });
  }

  overrideIncomeEligibility(incomeEligibilityOverride: IncomeEligibilityOverride) {
    const affApplication = this.getProcessedAppById(incomeEligibilityOverride.applicationId);

    const updatedApp = this.updateApplication(affApplication, (existing, updates) => {
      //no actual income verification going on, just call submitApplication as it creates payments as if income is verified
      this.submitApplication(existing, affApplication);
    });

    return of(!!updatedApp);
  }

  findAddress(searchTerm: string): Observable<FindAddressResult[]> {
    return this.graphqlService.findAddress(searchTerm);
  }
  retrieveAddress(id: string): Observable<RetrieveAddressResult> {
    return this.graphqlService.retrieveAddress(id);
  }
}
