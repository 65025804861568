export const USER_FRAGMENT = `
  _id
  name
  email
  primaryRole
`;

export const ADDRESS_FRAGMENT = `
  streetAddress
  suiteNumber
  city
  province
  postalCode
`;

export const FIND_ADDRESS_RESULT_FRAGMENT = `
  _id
  text
  description
`;

export const RETRIEVE_ADDRESS_RESULT_FRAGMENT = `
  _id
  address
  city
  province
  postalCode
`;

export const AUDITABLE_FRAGMENT = `
  createdAt
  createdBy {
    ${USER_FRAGMENT}
  }
  updatedAt
  updatedBy {
    ${USER_FRAGMENT}
  }
`;
export const APPLICATION_ERROR_FRAGMENT = `
  property
  constraints
  type
`;

export const APPLICATION_ERRORS_FRAGMENT = `
  mySituationErrors {
    ${APPLICATION_ERROR_FRAGMENT}
  }
  proofOfIdentityErrors {
    ${APPLICATION_ERROR_FRAGMENT}
  }
  personalInfoErrors {
    ${APPLICATION_ERROR_FRAGMENT}
  }
  spousePartnerErrors {
    ${APPLICATION_ERROR_FRAGMENT}
  }
  dependentsErrors {
    ${APPLICATION_ERROR_FRAGMENT}
  }
  incomeErrors {
    ${APPLICATION_ERROR_FRAGMENT}
  }
  bankingInfoErrors {
    ${APPLICATION_ERROR_FRAGMENT}
  }
`;

export const INCOME_FRAGMENT = `
  income {
    totalIncome
    splitPensionIncomeDeduction
    tuitionFeesDeduction
    medicalExpensesDeduction
    medicalExpensesOtherDependentsDeduction
    totalIncomeSpouse
    splitPensionIncomeDeductionSpouse
    tuitionFeesDeductionSpouse
    medicalExpensesDeductionSpouse
    medicalExpensesOtherDependentsDeductionSpouse
  }
`;

export const BANKING_FRAGMENT = `
  bankingInfo {
    paymentMethod
    directDeposit{
      institutionNumber
      transitNumber
      accountNumber
    }
    cheque {
      isDeclared
    }
  }
`;

export const FIND_ADDRESS_FRAGMENT = `

`;

export const REVIEW_FRAGMENT = `
  review {
    hasBeenVisited
    isDeclared
  }
`;

export const TOMBSTONE_FRAGMENT = `
  firstName
  middleName
  lastName
  birthdate
`;

export const DEPENDENT_FRAGMENT = `
  dependents {
    _id
    ${TOMBSTONE_FRAGMENT}
    isSharedCustody
    isPrimaryCareGiver   
  }
`;

export const SPOUSE_FRAGMENT = `
  spousePartner {
    ${TOMBSTONE_FRAGMENT}
    socialInsuranceNumber
    sinExpiryDate
    isApplyingOnBehalfSpousePartner
    isAuthorized
  }
`;

export const APPLICANT_FRAGMENT = `
  applicant {
    ${TOMBSTONE_FRAGMENT}
    address{
      ${ADDRESS_FRAGMENT}
    }
    socialInsuranceNumber
    sinExpiryDate
    phoneNumber
    email
   
  }
`;

export const PROOF_OF_IDENTITY_FRAGMENT = `
  proofOfIdentity {
    hasAlbertaDriversLicense
    hasAlbertaIdCard
    hasCanadianCitizenshipCert
    hasCanadaUkraineAuthForTravel
    hasDefenseDriverPermit
    hasDriversLicenseOrIdOutsideAlberta
    hasMetisAlbertaCard
    hasMilitaryIdCards
    hasPermanentResidentCard
    hasIndianStatusCard
    hasTemporaryResidentOrRefugeeDoc
    hasCanadianPassport
    hasCertificateOfIdentity
    hasForeignPassport
    hasTravelDocForRefugees
    hasUsPassportCard
    hasFac
    hasNexusCard
    hasInsurancePolicyDoc
    hasBankOrCreditCardStatements
    hasPayStub
    hasResidentialLeaseAgreement
    hasDeclarationThatIsAlbertaResident
    hasUtilityBills
    hasWrittenConfirmationFromAlbertaEdInstitute
    hasWrittenConfirmationOfAlbertaEmployement
    hasBirthCert
    hasMarriageCert
    hasTemporaryConfirmationFromIndigenousServices
    otherIdentityDocs
    otherNameAndDobDocs
    otherResidencyDocs
    hasSingleJourneyTravelDoc
    hasResettlementDoc
    hasEmergencyTravelDoc
    hasStudyPermit
    hasTemporaryResidentPermit
    hasWorkPermit
  }
`;

export const MY_SITUATION_FRAGMENT = `
  mySituation {
    _id
    isIncomeUnderThreshold
    isMarried
    hasDependentChild
    isSomeoneHelping
    nameOrganisation
    phoneNumber
    email
  }
`;

export const AFF_APPLICATION_FRAGMENT = `
  _id
  applicationCode
  ${MY_SITUATION_FRAGMENT}
  ${PROOF_OF_IDENTITY_FRAGMENT}
  ${APPLICANT_FRAGMENT}
  ${SPOUSE_FRAGMENT}
  ${DEPENDENT_FRAGMENT}
  ${INCOME_FRAGMENT}
  ${BANKING_FRAGMENT}
  ${REVIEW_FRAGMENT}
  isSubmitted
  applicationErrors {
    ${APPLICATION_ERRORS_FRAGMENT}
  }
  ${AUDITABLE_FRAGMENT}
  hasPossibleRelatedApplications
`;

export const AFF_PAYMENT_LEDGER_FRAGMENT = `
  personId
  sourceId
  paymentMonth
  amount
  personType
  paymentSource {
    name
  }
  applicationId
  paymentStatus
  ${BANKING_FRAGMENT}
  failedMessage
  holdReason
  revertToCheque
  revertToChequeReason
`;

export const AFF_APPLICATION_PAYMENTS_FRAGMENT = `
  applicant {
    ${AFF_PAYMENT_LEDGER_FRAGMENT}
  }
  spousePartner {
    ${AFF_PAYMENT_LEDGER_FRAGMENT}
  }
  dependents {
    ${AFF_PAYMENT_LEDGER_FRAGMENT}
  }
`;

export const INCOME_ELIGIBILITY_OVERRIDE_FRAGMENT = `
  incomeEligibilityOverride {
    applicationId
    ticketNumber
    isDeclared
  }
`;

export const LAST_RESOLUTION_ADJUSTMENT_FRAGMENT = `
  lastResolutionAdjustment {
    applicationId
    adjustmentType
    adjustmentDate
    adjustedBy
  }
`;

export const AFF_PROCESSED_APPLICATION_FRAGMENT = `
  ${AFF_APPLICATION_FRAGMENT}
  adjudication {
    adjudication
    proposedPayments {
      ${AFF_APPLICATION_PAYMENTS_FRAGMENT}
    }
  }
  applicationSubmitterSource
  isProcessed
  processedAt
  actualPayments {
    ${AFF_APPLICATION_PAYMENTS_FRAGMENT}
  }
  ${INCOME_ELIGIBILITY_OVERRIDE_FRAGMENT}
  ${LAST_RESOLUTION_ADJUSTMENT_FRAGMENT}
`;

export const STEPPER_APPLICATION_FRAGMENT = `
  _id
  applicationCode
  isSubmitted
  applicationErrors {
    ${APPLICATION_ERRORS_FRAGMENT}
  }
`;

export const MY_SITUATION_STEP_FRAGMENT = `
  _id
  ${MY_SITUATION_FRAGMENT}
  applicationErrors {
    ${APPLICATION_ERRORS_FRAGMENT}
  }
  ${AUDITABLE_FRAGMENT}
`;

export const PROOF_OF_IDENTITY_STEP_FRAGMENT = `
  _id
  ${PROOF_OF_IDENTITY_FRAGMENT}
  applicationErrors {
    ${APPLICATION_ERRORS_FRAGMENT}
  }
  ${AUDITABLE_FRAGMENT}
`;

export const APPLICANT_STEP_FRAGMENT = `
  _id
  ${APPLICANT_FRAGMENT}
  spousePartner {
    socialInsuranceNumber
  }
  applicationErrors {
    ${APPLICATION_ERRORS_FRAGMENT}
  }
  ${AUDITABLE_FRAGMENT}
`;

export const SPOUSE_STEP_FRAGMENT = `
  _id
  ${SPOUSE_FRAGMENT}
  applicant {
    socialInsuranceNumber
  }
  applicationErrors {
    ${APPLICATION_ERRORS_FRAGMENT}
  }
  ${AUDITABLE_FRAGMENT}
`;

export const DEPENDENTS_STEP_FRAGMENT = `
  _id
  ${MY_SITUATION_FRAGMENT}
  ${DEPENDENT_FRAGMENT}
  applicationErrors {
    ${APPLICATION_ERRORS_FRAGMENT}
  }
  ${AUDITABLE_FRAGMENT}
`;

export const INCOME_STEP_FRAGMENT = `
  _id
  ${INCOME_FRAGMENT}
  applicationErrors {
    ${APPLICATION_ERRORS_FRAGMENT}
  }
  ${AUDITABLE_FRAGMENT}
`;

export const BANKING_STEP_FRAGMENT = `
  _id
  ${BANKING_FRAGMENT}
  applicationErrors {
    ${APPLICATION_ERRORS_FRAGMENT}
  }
  ${AUDITABLE_FRAGMENT}
`;
