import { Expose } from 'class-transformer';
import { Equals, IsDefined, IsEmail, IsNotEmpty, ValidateIf } from 'class-validator';

export class MySituation {
  @Expose()
  _id?: string;

  @Expose()
  @Equals(true)
  @IsDefined()
  isIncomeUnderThreshold?: boolean;

  @Expose()
  @IsNotEmpty()
  isMarried?: boolean;

  @Expose()
  @IsDefined()
  hasDependentChild?: boolean;

  @Expose()
  @IsDefined()
  isSomeoneHelping?: boolean;

  @Expose()
  @ValidateIf((o) => o.isSomeoneHelping)
  @IsNotEmpty()
  nameOrganisation?: string;

  @Expose()
  @ValidateIf((o) => o.isSomeoneHelping)
  @IsNotEmpty()
  phoneNumber?: string;

  @Expose()
  @ValidateIf((o) => o.isSomeoneHelping)
  @IsEmail()
  @IsNotEmpty()
  email?: string;
}
