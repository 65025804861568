import { KeycloakService } from 'keycloak-angular';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthUserProfile, User } from '@common/entities';
import { KeycloakInstance } from 'keycloak-js';
import { getPrimaryRole } from '../../../shared/helpers/utils';

//for more documentation: https://github.com/keycloak/keycloak-documentation/blob/main/securing_apps/topics/oidc/javascript-adapter.adoc
//contains information such as KeycloakLoginOptions and additional stuff you can configure
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  id;
  constructor(private keycloakService: KeycloakService, private router?: Router) {}

  getUserProfile(attributes?: Array<string>) {
    //need to check if user is authenticated first be seeing if they have a token, otherwise loadUserInfo will fail.
    const keycloakInstance = this.keycloakService.getKeycloakInstance();
    if (!keycloakInstance?.authenticated) {
      return null;
    }

    //get user information that we need
    return keycloakInstance.loadUserInfo().then((data) => {
      const profileData = {
        email: data['email'],
        firstName: data['given_name'],
        lastName: data['family_name'],
        username: data['preferred_username'],
        userid: data['userid'],
        roles: [],
        _rawProfile: data,
      } as AuthUserProfile;

      const customAttributes = {};
      if (attributes && attributes.length > 0) {
        attributes.forEach((attribute) => {
          let attributeValue = null;
          if (data[attribute]) {
            attributeValue = data[attribute];
          }
          customAttributes[attribute] = attributeValue;
        });
      }

      profileData.attributes = customAttributes;
      return profileData;
    });
  }

  getUserRoles(availableRoles?: Array<string>) {
    //get roles that the current user belongs to
    const allUserRoles = this.keycloakService.getUserRoles().sort();
    let userRoles = allUserRoles;
    if (availableRoles && availableRoles.length > 0) {
      //keycloak will return ALL roles and we may only want roles of an existing subset
      //only return roles that exist in this list.
      userRoles = allUserRoles.filter((i) => {
        return availableRoles.includes(i);
      });
    }
    return userRoles;
  }

  async getUserProfileWithRoles(attributes?: Array<string>, availableRoles?: Array<string>) {
    const profile = await this.getUserProfile(attributes);
    if (profile) {
      profile.roles = this.getUserRoles(availableRoles);
    }
    return profile;
  }

  async isLoggedIn() {
    const ili = await this.keycloakService.isLoggedIn();

    return ili;
  }

  async signOut(returnUrl?: string): Promise<void> {
    if (!returnUrl) {
      const originUrl = window.location?.origin;
      if (originUrl) {
        returnUrl = originUrl;
      }
    }
    await this.keycloakService.logout(returnUrl);
  }

  async signIn(originalReturnUrl?: string) {
    const returnUrl = this.getFullReturnUrl(originalReturnUrl);
    if (this.router && this.router?.url) {
      this.router.navigateByUrl(this.router.url + '#signIn'); //this will ensure if user hits back on keycloak, that it goes to the correct page.
    }
    return this.keycloakService.login({
      redirectUri: returnUrl,
    });
  }

  async createAccount(originalReturnUrl?: string) {
    const returnUrl = this.getFullReturnUrl(originalReturnUrl);
    if (this.router && this.router?.url) {
      this.router.navigateByUrl(this.router.url + '#createAccount'); //this will ensure if user hits back on keycloak, that it goes to the correct page.
    }
    return this.keycloakService.register({
      redirectUri: returnUrl,
    });
  }

  async changePassword() {
    if (this.router && this.router?.url) {
      this.router.navigateByUrl(this.router.url + '#changePassword'); //this will ensure if user hits back on keycloak, that it goes to the correct page.
    }
    return this.keycloakService.login({
      action: 'UPDATE_PASSWORD',
    });
  }

  isAuthenticated(refreshToken = true) {
    //a synchronous method to check if a user is logged in based on token exists.
    let isAuthenticated = false;
    const keycloakInstance = this.keycloakService.getKeycloakInstance();
    if (keycloakInstance) {
      isAuthenticated = keycloakInstance.authenticated ?? false;
      //isLoggedIn() calls this as well to refresh token if it expires within 20 seconds.
      if (isAuthenticated && refreshToken) {
        this.keycloakService.updateToken(20);
      }
    }
    return isAuthenticated;
  }

  getKeycloakInstance() {
    const keycloakInstance = this.keycloakService.getKeycloakInstance();
    return keycloakInstance;
  }

  async refreshToken(minValidity: number) {
    const keycloakInstance = this.keycloakService.getKeycloakInstance();
    const tokenRefreshed = await keycloakInstance
      .updateToken(minValidity)
      .then((refreshed) => {
        //token refreshed. set reset keycloak instance
        return refreshed;
      })
      .catch(function () {
        return null;
      });
    return tokenRefreshed;
  }

  //this method will return a full url (with domain) if relative is passed in
  private getFullReturnUrl(originalReturnUrl?: string) {
    let returnUrl = originalReturnUrl;
    const originUrl = window.location?.origin;
    if (!returnUrl) {
      returnUrl = window.location.href;
    } else {
      if (returnUrl.startsWith('/')) {
        returnUrl = originUrl + returnUrl;
      } else if (!returnUrl.startsWith('http')) {
        returnUrl = originUrl + '/' + returnUrl;
      }
    }
    return returnUrl;
  }

  //this is used to real time validate token. helps to detect token status if multiple tabs open
  validateToken(keycloakInstance: KeycloakInstance) {
    return new Promise<any>((resolve) => {
      keycloakInstance
        .loadUserInfo()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          console.log(err?.message + err?.statusText);
          resolve(null);
        });
    });
  }

  async getContextUserProfile() {
    const profile = await this.getUserProfileWithRoles();
    const user = {
      _id: profile.userid,
      name: `${profile.firstName} ${profile.lastName}`,
      roles: profile.roles,
      primaryRole: getPrimaryRole(profile.roles)?.displayValue ?? null,
      email: profile.email,
    } as User;
    return user;
  }
}
