import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe for sin.  Returns XXX XXX XXX
 */
@Pipe({ name: 'socialInsuranceNumber' })
export class SocialInsuranceNumberPipe implements PipeTransform {
  transform(sin: string): string {
    if (!sin || sin.length !== 9) {
      return sin;
    }

    return `${sin.slice(0, 3)} ${sin.slice(3, 6)} ${sin.slice(6, 9)}`;
  }
}
