import { StringMax, StringMin } from '@common/validation';
import { Expose } from 'class-transformer';
import { Equals, IsDefined, IsNotEmpty, IsOptional } from 'class-validator';
import * as dayjs from 'dayjs';
import { min } from 'lodash';
export class Dependent {
  @Expose()
  @IsOptional()
  _id: string;

  @Expose()
  @IsNotEmpty()
  firstName: string;

  @Expose()
  @IsNotEmpty()
  lastName: string;

  @Expose()
  @StringMin('2005-01-01') //on or after 2005-01-01
  @StringMax(min([dayjs().format('YYYY-MM-DD'), '2023-06-30']))
  @IsNotEmpty()
  birthdate?: string;

  @Expose()
  @IsDefined()
  @Equals(true)
  isPrimaryCareGiver?: boolean;

  @Expose()
  @IsDefined()
  isSharedCustody?: boolean;
}
