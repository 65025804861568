// Github: https://github.com/kievsash/ngx-material-tools
// Project: ngx-material-tools
// Original Author: Oleksandr Poshtaruk
// NOTE: this is just a copy of mat-input-commified but to force whole numbers
// If you're using an input type="tel" this will format back to string.

import { Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: 'input[iesMatInputInteger]',
  providers: [
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: MatInputIntegerDirective },
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => MatInputIntegerDirective),
      multi: true,
    },
  ],
})
export class MatInputIntegerDirective {
  private _value: number | null;

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  get value(): number | null {
    if (this._value) return this._value;
  }

  @Input('value')
  set value(value: number | null) {
    this._value = Number(value);
    this.formatValue(Number(value));
  }

  private formatValue(value) {
    if (value !== null) {
      if (value === '') this.elementRef.nativeElement.value = null;
      else this.elementRef.nativeElement.value = this.wholeNumber(value);
    } else this.elementRef.nativeElement.value = null;
  }

  private unFormatValue() {
    const value = this.elementRef.nativeElement.value;
    this._value = value ? Number(value.replace(/[^\d.-]/g, '')) : null;
    if (value) this.elementRef.nativeElement.value = Number(this._value).toFixed(0);
    else this.elementRef.nativeElement.value = '';
  }

  private wholeNumber(x) {
    if (x === '') return '';
    const numberAsString = x >= 0 ? Number(x).toFixed(0) : '';
    return numberAsString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value) {
    this._value = value.replace(/[^\d.-]/g, '');
    this._onChange(Number(this._value));
  }

  @HostListener('blur')
  _onBlur() {
    this.formatValue(this._value);
    this.touched();
  }

  @HostListener('focus')
  onFocus() {
    this.unFormatValue();
  }

  _onChange;
  touched;
  writeValue(value) {
    this._value = value;
    this.formatValue(this._value);
    // this._onChange(this._value);
  }

  registerOnChange(fn) {
    this._onChange = fn;
  }

  registerOnTouched(fn) {
    this.touched = fn;
  }
}
