import { buildMessage, ValidateBy, ValidationOptions } from 'class-validator';

/**
 * Checks if the value is greater than or equal to the allowed minimum value.
 */
export function StringMax(minValue: string, validationOptions?: ValidationOptions): PropertyDecorator {
  return ValidateBy(
    {
      name: 'StringMax',
      constraints: [minValue],
      validator: {
        validate: (value, args): boolean => value?.toString() <= (args?.constraints[0]?.toString() || ''),
        defaultMessage: buildMessage(
          (eachPrefix) => eachPrefix + '$property must not be less than $constraint1',
          validationOptions
        ),
      },
    },
    validationOptions
  );
}
