/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable @typescript-eslint/prefer-namespace-keyword */
import * as dayjs from 'dayjs';
import * as tz from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { v4 as uuidv4 } from 'uuid';

import { AffRoles } from '../constants/aff-roles';
import { Lookup } from '../constants/lookup.interface';
import { RoleLookup } from '../entities/role-lookup.interface';
import { SortField } from '../entities/sort-field.entity';
import { User } from '../entities/user.entity';
import { SortDirection } from '../enums/sort-direction.enum';
import { EncryptionUtils } from './../encryption/encryption-utils';

dayjs.extend(tz);
dayjs.extend(utc);

const EdmontonTimezoneCode = 'America/Edmonton';

export function isNullOrUndefined(val) {
  return val === null || val === undefined;
}

export function isNullUndefinedOrEmpty(val: string) {
  return isNullOrUndefined(val) || val === '';
}

export function createGuid() {
  return uuidv4();
}
export function createObjectId() {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
}
export function hasValue(item) {
  let hasVal = true;

  if (typeof item === 'undefined') hasVal = false;
  else if (item === null) hasVal = false;
  else if (String(item) === '') hasVal = false;

  return hasVal;
}
export function findObjectByKey(array, key: string, value) {
  if (hasValue(array) && array.length > 0) {
    const item = array.find((i) => i[key] === value);
    if (hasValue(item)) return item;
  }
  return null;
}

export function displayBoolean(value, replacementTrue = 'Yes', replacementFalse = 'No', defaultValueIfNull?) {
  //handles if value is null, show replacement value
  //?? operator returns error in {{ }} brackets
  if (!hasValue(value)) {
    value = false;
    if (hasValue(defaultValueIfNull)) return defaultValueIfNull;
  }
  if (hasValue(replacementTrue) && value === true) return replacementTrue;
  else if (hasValue(replacementFalse) && value === false) return replacementFalse;

  return value;
}

export function convertToDate(value: string): Date {
  //converts string "202002" to date February 2020
  if (value) {
    const year = parseInt(value.substr(0, 4), 10);
    const month = parseInt(value.substr(4, 2), 10) - 1; //month is index based. so february would be 01
    const day = 1;
    const date = new Date(year, month, day);
    return date;
  }
  return null;
}

export function calculateAge(dob: Date) {
  const today = dayjs();
  return today.diff(dob, 'years');
}

export function isBetween18And20(value: Date) {
  if (!value) return false;
  //birthdate should not include hours (which it is). this is causing issues when calculating age in MST evenings if date is exactly 18 years
  //if the date is exactly 18 years, but the 'today' time is less than dob time, it will evaluate to 17 years old
  const safeDate = new Date(value.getFullYear(), value.getMonth(), value.getDate(), 0);
  const age = calculateAge(safeDate);
  return age >= 18 && age < 20;
}
export function is20Older(value: Date) {
  return value && calculateAge(value) >= 20;
}

export function is18Older(value: Date) {
  return value && calculateAge(value) >= 18;
}

export function isOver17Years6Mos(value: Date) {
  if (!value) return false;
  const birthDate = dayjs(value);
  const now = dayjs();
  const ageInMonths = now.diff(birthDate, 'month');
  return ageInMonths >= 210;
}

export function omitKeyFromObject(obj, key) {
  //local function to remove key from array (cyclical function call)
  //@typescript-eslint/no-use-before-define lint warning occurs if moved outside of function
  const omitKeyFromArray = function (arr, key) {
    return arr.map((val) => {
      if (Array.isArray(val)) return omitKeyFromArray(val, key);
      else if (typeof val === 'object') return omitKeyFromObject(val, key);
      return val;
    });
  };

  if (obj !== null && obj !== undefined) {
    const keys = Object.keys(obj);
    const newObj = {};
    keys.forEach((i) => {
      if (i !== key) {
        const val = obj[i];
        if (val instanceof Date) newObj[i] = val;
        else if (Array.isArray(val)) newObj[i] = omitKeyFromArray(val, key);
        else if (typeof val === 'object' && val !== null) newObj[i] = omitKeyFromObject(val, key);
        else newObj[i] = val;
      }
    });
    return newObj;
  } else return obj;
}

export function removeTypeNameFromObject<T>(obj: T): T {
  return omitKeyFromObject(obj, '__typename');
}

export function removeTypeNameFromArray<T>(arr: T[]): T[] {
  if (!arr) return null;
  const cleanedArr = [];
  arr.forEach((obj) => cleanedArr.push(omitKeyFromObject(obj, '__typename')));
  // for (let i = 0; i < arr.length; i++) {
  //   arr[i] = omitKeyFromObject(arr[i], '__typename');
  // }
  return cleanedArr;
}

export function removeDeletedFromSubCollections<T>(object: T): T {
  const deleteRemoved = Object.entries(object).reduce(
    (patchedObject, [key, value]) => ({
      ...patchedObject,
      [key]:
        Array.isArray(value) && value.find((o) => o.isDeleted != undefined) ? value.filter((o) => !o.isDeleted) : value,
    }),
    {} as T
  );

  return deleteRemoved;
}

export function getKey(obj) {
  return new Proxy(obj, {
    get(_, key) {
      return key;
    },
  });
}

export function unsort() {
  return 0;
}

export function getSessionStorageArray<Type>(key: string, encryptionKey = null) {
  const sessionValue = this.getSessionStorageValue(key, encryptionKey);
  if (sessionValue) {
    const storedArray = !Array.isArray(sessionValue) ? (JSON.parse(sessionValue) as Array<Type>) : sessionValue; //no brackets
    return storedArray;
  }
  return [];
}

export function addSessionStorageArray<Type>(key: string, value: Type, clear?: boolean, encryptionKey = null) {
  let sessionValue = this.getSessionStorageValue(key, encryptionKey);
  if (clear !== null && clear) sessionValue = []; //clear arrays
  else if (sessionValue !== null) {
    const arrayTest = JSON.parse(sessionValue.toString());
    if (!Array.isArray(arrayTest)) sessionValue = []; //existing data is not an array. clear
  }
  if (!sessionValue) {
    const arrayValue = [value];
    const stringArrayValue = JSON.stringify(arrayValue);
    this.setSessionStorageValue(key, stringArrayValue, encryptionKey);
  } else {
    //push to array
    const storedArray = !Array.isArray(sessionValue) ? (JSON.parse(sessionValue) as Array<Type>) : sessionValue;
    storedArray.push(value);
    const stringArrayValue = JSON.stringify(storedArray);
    this.setSessionStorageValue(key, stringArrayValue, encryptionKey);
  }
}

export function setSessionStorageValue<Type>(key: string, value: Type, encryptionKey = null) {
  let storeValue = null;
  if (encryptionKey) {
    const encUtils = new EncryptionUtils(encryptionKey);
    const encryptValue = encUtils.encrypt(JSON.stringify(value));
    storeValue = encryptValue;
  } else if (value !== null) storeValue = JSON.stringify(value);

  window.sessionStorage.setItem(key, storeValue);
}

export function getSessionStorageValue(key: string, encryptionKey = null) {
  const sessionStorageValue = window.sessionStorage.getItem(key);
  let objValue = null;

  if (sessionStorageValue) {
    if (encryptionKey) {
      const encUtils = new EncryptionUtils(encryptionKey);
      const decryptValue = encUtils.decrypt(sessionStorageValue);
      if (decryptValue) objValue = JSON.parse(decryptValue);
    } else if (sessionStorageValue !== null) {
      objValue = JSON.parse(sessionStorageValue);
    }
  }

  return objValue;
}

export function getLocalStorageValue(key: string, encryptionKey = null) {
  const localStorageValue = window.localStorage.getItem(key);
  let objValue = null;
  if (encryptionKey) {
    const encUtils = new EncryptionUtils(encryptionKey);
    const decryptValue = encUtils.decrypt(localStorageValue);
    if (decryptValue) objValue = JSON.parse(decryptValue);
  } else if (localStorageValue !== null) {
    objValue = JSON.parse(localStorageValue);
  }
  return objValue;
}

export function setLocalStorageValue<Type>(key: string, value: Type, encryptionKey = null) {
  let storeValue = null;
  if (encryptionKey) {
    const encUtils = new EncryptionUtils(encryptionKey);
    const encryptValue = encUtils.encrypt(JSON.stringify(value));
    storeValue = encryptValue;
  } else if (value !== null) storeValue = JSON.stringify(value);

  window.localStorage.setItem(key, storeValue);
}

export function removeLocalStorageValue(key: string) {
  window.localStorage.removeItem(key);
}

export function removeSessionStorageValue(key: string) {
  window.sessionStorage.removeItem(key);
}

export function flattenObject(obj) {
  let flattened = {};

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && !(obj[key] instanceof Date) && obj[key] !== null)
      flattened = { ...flattened, ...flattenObject(obj[key]) };
    else flattened[key] = obj[key];
  });

  return flattened;
}

export function randomBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function mapUserToDto(user: User) {
  // keep null/undefined by returning the blank user passed in
  return user
    ? {
        _id: user._id,
        name: user.name,
        email: user.email,
        primaryRole: user.primaryRole,
      }
    : user;
}

function getPrimaryRole(userRoleNames: string[]) {
  if (!userRoleNames) return null;
  const userRoles = AffRoles.AllValues.filter((r) => userRoleNames.includes(r.code));
  const userRolesByPriority = userRoles.sort((a, b) => a.priority - b.priority);
  return userRolesByPriority[0];
}

export function mapKeycloakUserToUser(user) {
  if (!user) return;

  return {
    _id: user.userid,
    name: user.name,
    email: user.email,
    phone: user.phone,
    district: user.district,
    roles: user.roles || user.realm_access?.roles,
    primaryRole: getPrimaryRole(user.roles || user.realm_access?.roles)?.displayValue ?? user.defaultRole,
  } as User;
}

export function objectContains(obj, searchTerm: string): boolean {
  for (const key in obj) {
    if (obj[key]) {
      if (obj[key] instanceof Date) {
        if (obj[key].toString().includes(searchTerm)) return true;
      } else if (obj[key] instanceof Object) {
        const matchFound = objectContains(obj[key], searchTerm);
        if (matchFound) return true;
      } else {
        const matchFound = obj[key].toString().indexOf(searchTerm) !== -1;
        if (matchFound) return true;
      }
    }
  }
  return false;
}

export function hrTimeToNanoseconds(hrTime) {
  //hrTime is [seconds, nanoseconds]
  const nanoseconds = hrTime[0] * 1000000000 + hrTime[1];
  return nanoseconds;
}

export function hrTimeToMicroseconds(hrTime) {
  //hrTime is [seconds, nanoseconds]
  //return hrTime[0] * 1000000 + hrTime[1] / 1000;
  const nanoseconds = this.hrTimeToNanoseconds(hrTime);
  return nanoseconds / 1000;
}

export function hrTimeToMilliseconds(hrTime) {
  //hrTime is [seconds, nanoseconds]
  const nanoseconds = this.hrTimeToMicroseconds(hrTime);
  return nanoseconds / 1000;
}

/**
 * Returns true if user is in roles
 */
export function isInRoles(user: User, roles: RoleLookup[]) {
  if (!user || !user.roles || !roles) return false;

  const approvedRoles = roles.map((r) => r.code);
  return user.roles.filter((userRole) => approvedRoles.includes(userRole)).length > 0;
}

/**
 * Gets the value of the specified property in the object provided.
 * Use dot notation for nested fields.
 *
 * Note: This function calls itself recursively chaining down each field in
 * a dot notation formatted fieldName
 *
 * @param obj
 *    The object to get the field of
 * @param fieldName
 *    The name of the field to retrieve.  Use dot notation for nested fields
 * @returns
 *    The value of the property specified
 */
export function getField(obj: unknown, fieldName: string) {
  const fields = fieldName.split('.');
  if (fields.length === 1) return obj[fields[0]];

  let currentFieldName = fields[0];
  let field = obj[currentFieldName];
  let openBracketIdx = currentFieldName.indexOf('[');
  while (openBracketIdx !== -1) {
    //if the open bracket is NOT at the start of the string,
    //then there is a field name to grab, not just the indexer
    if (openBracketIdx > 0) {
      const namePart = currentFieldName.substring(0, openBracketIdx);
      currentFieldName = currentFieldName.substring(openBracketIdx);
      field = obj[namePart];
    }

    const closeBracketIdx = currentFieldName.indexOf(']');
    const indexer = currentFieldName.substring(0, closeBracketIdx);
    currentFieldName = currentFieldName.substring(closeBracketIdx);

    const idx = parseInt(indexer.replace('[', '').replace(']', ''));
    field = field[idx];

    openBracketIdx = currentFieldName.indexOf('[');
  }

  return getField(field, fields.slice(1).join('.'));
}

/**
 * Sorts an array of any type of object by the field names and sort directions provided.
 * Use dot notation to specify sorts on nested properties.  Objects are sorted in the order
 * the sort fields are provided.
 * 
 * @param sortFields 
 *    array of the properties to sort by
 *    if a property you want to sort by is a nested property, use dot notation
 *    i.e. 
      const sortFields = [
        { fieldName: 'createdBy.name', direction: SortDirection.Asc} as SortField,
        { fieldName: 'createdAt', direction: SortDirection.Desc} as SortField,
      ];
 * @returns 
 */
export function sortBy(sortFields: SortField[]) {
  return (a, b) => {
    let result;

    //the OR functionality will check each sortField in order passed in
    sortFields.forEach((sortField) => {
      let x, y;
      if (sortField.direction === SortDirection.Asc) {
        x = getField(a, sortField.fieldName);
        y = getField(b, sortField.fieldName);
      } else {
        x = getField(b, sortField.fieldName);
        y = getField(a, sortField.fieldName);
      }

      if (x instanceof Date) result = result || x.getTime() - y.getTime();
      else if (typeof x === 'string') result = result || x.localeCompare(y);
      else result = result || x - y;
    });

    return result;
  };
}
export function getMoreRecentUpdatedAt(prev?: Date, curr?: { updatedAt?: Date }) {
  return ((!prev || (curr?.updatedAt && curr.updatedAt.getTime() > prev.getTime())) && curr?.updatedAt) || prev;
}

export function getTypeName(val) {
  if (val instanceof Date) return 'Date';
  else return typeof val;
}

export function namedLookupToArray(namedLookup: { [name: string]: Lookup }) {
  return (Object.values(namedLookup) as Lookup[]).map((r) => ({ value: r.code, displayValue: r.displayValue }));
}

/**
 * Transpose the provided date to UTC timezone and format using the provided template.
 * @param date Date to be formatted.  Now when undefined.
 * @param format Date format template. ISO8601 when undefined.
 * @returns Formatted date.
 */
export function utcTime(date?: Date, format?: string): string {
  return dayjs(date).utc().format(format);
}

/**
 * Transpose the provided date to 'America/Edmonton' (MST or MDT) timezone and format using the provided template.
 * @param date Date to be formatted. Now when undefined.
 * @param format Date format template.  ISO8601 when undefined.
 * @returns Formatted date.
 */
export function edmontonTime(date?: Date, format?: string): string {
  return dayjs(date).tz(EdmontonTimezoneCode).format(format);
}

export function toEdmontonTime(date) {
  return dayjs(date).tz(EdmontonTimezoneCode);
}
export function newDateWithEdmontonTimeZone(dateTimeString: string) {
  return dayjs.tz(dateTimeString, EdmontonTimezoneCode).toDate();
}
export function getPermutations(n, elements) {
  let results = [];
  if (n == 1) results.push(elements.join(' '));
  else {
    let recursiveVal;
    for (let i = 0; i < n - 1; i++) {
      recursiveVal = this.getPermutations(n - 1, elements);
      results = [...results, ...recursiveVal];
      if (n % 2 == 0) this.swapElementsInArray(elements, i, n - 1);
      else this.swapElementsInArray(elements, 0, n - 1);
    }
    recursiveVal = this.getPermutations(n - 1, elements);
    results = [...results, ...recursiveVal];
  }

  return results;
}

export function swapElementsInArray(input, a, b) {
  const tmp = input[a];
  input[a] = input[b];
  input[b] = tmp;
}

export function regExpEscape(s: string) {
  return s.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
}

export function MapMaritalSatus(maritalStatus: string) {
  return !maritalStatus || maritalStatus === 'Unknown'
    ? null
    : maritalStatus === 'Common Law'
    ? 'commonLaw'
    : maritalStatus.toLowerCase();
}

export function MapGender(gender: string) {
  return !gender || gender === 'Unknown' ? null : gender === 'X' ? 'preferNotToSay' : gender.toLowerCase();
}

export function wildcardToRegExp(s: string) {
  if (s?.trim()) return new RegExp(s.split(/\*+/).map(regExpEscape).join('.*'), 'ims');
  return new RegExp('.*', 'ims');
}

export function getNameVariants(arr: string[]) {
  const results = [];
  for (let i = 0; i <= arr.length; i++) {
    const firstName = arr.slice(0, i).join(' ');
    const lastName = arr.slice(i).join(' ');

    results.push({ firstName: firstName, lastName: lastName });
  }

  return results;
}

export function createCursorCriteria(sortCriteria, cursor) {
  if (!cursor) return {};

  const orList = [];
  const runningMatch = {};
  for (const [key, value] of Object.entries(sortCriteria)) {
    const orVal = { ...runningMatch };
    const cursorEntry = cursor.find((c) => c.key === key);
    let cursorVal;
    switch (cursorEntry.type) {
      case 'Date':
        cursorVal = new Date(+cursorEntry.value);
        break;
      case 'number':
        cursorVal = +cursorEntry.value;
        break;
      case 'boolean':
        cursorVal = cursorEntry.value === 'true';
        break;
      default:
        cursorVal = cursorEntry.value;
        break;
    }

    if (cursorVal === null) orVal[key] = { $ne: cursorVal };
    else if (value === SortDirection.Asc) orVal[key] = { $gt: cursorVal };
    else orVal[key] = { $lt: cursorVal };

    runningMatch[key] = cursorVal;

    orList.push(orVal);
  }

  return { $or: orList };
}

export function createNextCursor(sortCriteria, cursorData) {
  if (!cursorData) return [];

  const nextCursor = [];
  for (const key of Object.keys(sortCriteria)) {
    const keySplit = key.split('.');
    let data = cursorData;
    keySplit.forEach((k) => (data = data[k]));

    nextCursor.push({ key: key, value: data, type: this.getTypeName(data) });
  }

  return nextCursor;
}

function isIso8601(value): boolean {
  const ISO_8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;
  if (value === null || value === undefined) return false;
  return ISO_8601.test(value);
}

export function deserializeToDates(object) {
  if (object === null || object === undefined) return object;
  if (typeof object !== 'object') return object;

  for (const key of Object.keys(object)) {
    const value = object[key];
    if (isIso8601(value)) object[key] = new Date(value);
    else if (typeof value === 'object') deserializeToDates(value);
  }
}

export function getMonthNumber(monthName: string): number {
  const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

  const monthAbrvLower = monthName.toLowerCase().substring(0, 3);

  const idx = months.findIndex((m) => m === monthAbrvLower);

  //convert from 0 based to 1 based
  return idx + 1;
}

export function removeAuditFieldsFromEntity(entity: any): void {
  delete entity.createdBy;
  delete entity.createdAt;
  delete entity.updatedBy;
  delete entity.updatedAt;
}

export function getDateFromString(dateString: string | undefined): Date | undefined {
  const ticks = Date.parse(`${dateString}T00:00:00-0700`);
  return ticks ? new Date(ticks) : undefined;
}
