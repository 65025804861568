import { ValidationIssue } from '@common/entities';

export class AffApplicationErrors {
  mySituationErrors: ValidationIssue[];
  proofOfIdentityErrors: ValidationIssue[];
  personalInfoErrors: ValidationIssue[];
  spousePartnerErrors: ValidationIssue[];
  dependentsErrors: ValidationIssue[];
  incomeErrors: ValidationIssue[];
  bankingInfoErrors: ValidationIssue[];
  reviewErrors: ValidationIssue[];
}
