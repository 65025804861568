import { Component, Input } from '@angular/core';

@Component({
  selector: 'print-button',
  templateUrl: './print-button.component.html',
  styleUrls: ['./print-button.component.scss'],
})
export class PrintButtonComponent {
  @Input() title: string;

  onClick() {
    window.print();
  }
}
