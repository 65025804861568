import { AffApplyRoutes } from '@aff-apply/entities';
import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Auth } from 'aws-amplify';
import { KeycloakService } from 'keycloak-angular';
import { AppConfigService, KeycloakTokenExchangeService, KeycloakTokenInitService } from '../../../ui-shared-components.services';
import { Features } from '@common/constants';

@Injectable({
  providedIn: 'root',
})
export class KeycloakTokenExchangeGuard implements CanActivate, CanActivateChild {
  // export class KeycloakTokenExchangeGuard extends KeycloakAuthGuard {
  defaultNotAuthorized = AffApplyRoutes.NotAuthorized.baseUrl;

  constructor(
    protected router: Router,
    protected readonly keycloakService: KeycloakService,
    private keycloakTokenExchangeService: KeycloakTokenExchangeService,
    private keycloakTokenInitService: KeycloakTokenInitService,
    protected configService: AppConfigService
  ) {}
  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkAuth(childRoute, state);
  }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkAuth(route, state);
  }

  public async checkAuth(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const features = this.configService.getFeatures();

    if (features?.[Features.HasSunset] === true) {
      this.router.navigate([this.defaultNotAuthorized]);
      return false;
    }

    try {
      // 60000 = 1 standard Earth minute
      const expirationDate = new Date().getTime() + 60000;
      const redirect = {
        expires: expirationDate,
        value: state.url,
      };
      window?.sessionStorage?.setItem('redirect', JSON.stringify(redirect));
      await Auth.signOut();
    } catch (ex) {}

    const keycloakInstance = this.keycloakService.getKeycloakInstance();
    let isLoggedIn = false;
    if (keycloakInstance) {
      //   isLoggedIn = await keycloakInstance.authenticated;

      isLoggedIn = await this.keycloakService.isLoggedIn();
    }
    if (!isLoggedIn) {
      // if (this.router?.navigateByUrl && this.router?.url) {
      //   this.router.navigateByUrl(this.router.url + '#auth'); //this will ensure if user hits back on keycloak, that it goes to the correct page.
      // }

      const params = route.queryParams;
      if (!params.access_token) {
        this.router.navigate([this.defaultNotAuthorized]);
      }
      try {
        const exchangeResult = await this.keycloakTokenExchangeService.performTokenExchange(params.access_token);

        const granted = await this.keycloakTokenInitService.initKeyCloak(exchangeResult);

        if (!granted) {
          this.router.navigate([this.defaultNotAuthorized]);
        }

        return true;
      } catch (ex) {
        // console.log('Auth Ex: ' + ex);
        this.router.navigate([this.defaultNotAuthorized]);
      }
    } else {
      const params = route.queryParams;
      if (params.access_token) {
        this.router.navigate(['/clerk-support/dashboard'], { queryParams: { access_token: null }, replaceUrl: true });
      }
    }

    return isLoggedIn;
  }
}
