import { Lookup } from '@common/constants';

export class ETransferMethod {
  static readonly Email: Lookup = { displayValue: 'Email address', code: 'Email' };
  static readonly Phone: Lookup = { displayValue: 'Phone number', code: 'Phone' };

  static readonly ALL = [ETransferMethod.Email, ETransferMethod.Phone];
}

export const ETransferMethods = {};
for (const [key, value] of Object.entries(ETransferMethod)) {
  ETransferMethods[key] = value as Lookup;
}
