import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'common-management-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent implements OnInit {
  form: UntypedFormGroup;
  title: string;
  description: string;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.description = data.description;
  }

  ngOnInit() {
    this.form = this.fb.group({
      title: this.title,
      description: this.description,
    });
  }

  // save() {
  //     this.dialogRef.close(this.form.value);
  // }

  close() {
    this.dialogRef.close();
  }
}
