export class PaymentSources {
  static readonly Portal = 'Portal';
  static readonly ClerkPortal = 'ClerkPortal';
}

export type PaymentSource = typeof PaymentSources.Portal | typeof PaymentSources.ClerkPortal;

export const PaymentSourceLookup = {};
for (const [key, value] of Object.entries(PaymentSources)) {
  PaymentSourceLookup[key] = value;
}
