import { Component, Input } from '@angular/core';

@Component({
  selector: 'aff-detail-card',
  templateUrl: './detail-card.component.html',
  styleUrls: ['./detail-card.component.scss'],
})
export class DetailCardComponent {
  @Input() isPrimary = true;
  @Input() hasFooter = false;
}
