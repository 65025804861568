import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '../config/config.service';
import * as jsonwebtoken from 'jsonwebtoken';

export class KeyCloakTokenResult {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  session_state: string;
  scope: string;
  //not-before-policy: number;
}

@Injectable({
  providedIn: 'root',
})
export class KeycloakTokenExchangeService {
  constructor(private configService: AppConfigService, protected readonly router: Router) {}

  public async performTokenExchange(jwt: string) {
    await this.configService.loadConfig();
    const kcConfig = this.configService.getConfig();

    // this._ngZone.runOutsideAngular(() => {
    const tokenUrl = kcConfig.KEYCLOAK.URL + 'realms/' + kcConfig.KEYCLOAK.REALM + '/protocol/openid-connect/token';

    const bodyObj = {
      grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
      requested_token_type: 'urn:ietf:params:oauth:token-type:refresh_token',
      client_id: 'affordability-agent-portal',
      subject_token_type: 'urn:ietf:params:oauth:token-type:jwt',
      subject_token: jwt,
      //   subject_issuer: 'urn:RSG.TokenService:DevR',
      subject_issuer: kcConfig.TOKEN_EXCHANGE_ISS,
      audience: 'affordability-agent-portal',
    };

    const result1 = await this.makeXhrRequestPromise({
      method: 'POST',
      url: tokenUrl,
      params: bodyObj,
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
      withCredentials: true,
    });

    //Due to bug in keycloak token exchange, need to do this twice for new users to ensure
    //all claims are properly transfered
    //Decode the token and check for presence of necessary
    //claims
    const exchangeObj = JSON.parse(result1);
    const decodedToken = jsonwebtoken.decode(exchangeObj.access_token);
    // console.log(decodedToken);

    if (!decodedToken['rsg-loginOrg']) {
      const result2 = await this.makeXhrRequestPromise({
        method: 'POST',
        url: tokenUrl,
        params: bodyObj,
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
        withCredentials: true,
      });

      //   console.log(result2);

      return JSON.parse(result2);
    } else {
      return exchangeObj;
    }
  }

  //Not sure why this works but angular http client does not
  makeXhrRequestPromise(opts) {
    return new Promise<string>(function (resolve, reject) {
      const xhr = new XMLHttpRequest();
      xhr.open(opts.method, opts.url);
      xhr.onload = function () {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText,
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: xhr.status,
          statusText: xhr.statusText,
        });
      };
      if (opts.withCredentials) {
        xhr.withCredentials = opts.withCredentials;
      }
      if (opts.headers) {
        Object.keys(opts.headers).forEach(function (key) {
          xhr.setRequestHeader(key, opts.headers[key]);
        });
      }
      let params = opts.params;
      // We'll need to stringify if we've been given an object
      // If we have a string, this is skipped.
      if (params && typeof params === 'object') {
        params = Object.keys(params)
          .map(function (key) {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
          })
          .join('&');
      }
      xhr.send(params);
    });
  }
}
