import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, ContextProvider } from '@common/ui/shared-components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Auth } from 'aws-amplify';
import { KeycloakUserService } from '../../shared/keycloak-user.service';
import { AffApplyRoutes, ClerkType } from '@aff-apply/entities';

@UntilDestroy()
@Component({
  selector: 'aff-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnInit {
  constructor(
    private contextProvider: ContextProvider,
    private authenticationService: AuthenticationService,
    private keycloakUserService: KeycloakUserService,
    private router: Router
  ) {}

  isAuthenticated?: boolean = null;
  profile$;
  profile;
  userRoles = [];
  isExpanded = false;
  clickedInsideComponent = false;

  async ngOnInit() {
    this.profile$ = this.contextProvider.getCurrentUser();
    try {
      const cognitoAuth = await Auth.currentSession();
      this.contextProvider.setCurrentUser({
        name: `${cognitoAuth?.getIdToken()?.payload?.['given_name']} ${
          cognitoAuth?.getIdToken()?.payload?.['family_name']
        }`,
        roles: ['public user'],
      });
    } catch (ex) {
      const user = await this.getUser();
      if (user?.fullName) {
        this.contextProvider.setCurrentUser({
          name: user.fullName,
          roles: [user.clerkType],
        });
      }
    }

    this.profile$.pipe(untilDestroyed(this)).subscribe((data) => {
      if (!this.profile) {
        this.profile = data;
      }
    });
  }

  async logout() {
    const userInfo = await this.keycloakUserService.userInfo();

    let logoutUrl = window.location.origin + '/logout';
    if (userInfo?.clerkType === ClerkType.GOA) {
      logoutUrl = window.location.origin + `/${AffApplyRoutes.GoaClerkLanding.baseUrl}`;
    } else if (userInfo?.clerkType === ClerkType.ROADS) {
      logoutUrl = window.location.origin + `/${AffApplyRoutes.RoadsClerkLanding.baseUrl}`;
    } else if (userInfo?.clerkType === ClerkType.RESOLUTION) {
      logoutUrl = window.location.origin + `/${AffApplyRoutes.GoaResClerkLanding.baseUrl}`;
    }

    this.contextProvider.setCurrentUser(null);

    if (userInfo) {
      await this.authenticationService.signOut(logoutUrl);
    } else {
      window.location.href = logoutUrl;
    }
  }

  isSignInButtonVisible(): boolean {
    return window.location.href.indexOf(AffApplyRoutes.ClerkSupport.baseUrl) < 0;
  }

  signIn() {
    if (window.location.href.indexOf(AffApplyRoutes.GoaSupport.baseUrl) > -1) {
      this.router.navigate([AffApplyRoutes.GoaSupport.baseUrl]);
    } else {
      this.router.navigate([AffApplyRoutes.MyAppStatus.baseUrl]);
    }
  }

  toggleExpandCollapse() {
    this.isExpanded = !this.isExpanded;
  }

  async getUser() {
    const userInfo = await this.keycloakUserService.userInfo();
    return userInfo;
  }
}
