import { User } from '@common/entities';

export class BaseAuditableEntity {
  _id?: string;

  createdBy?: User;

  createdAt?: Date;

  updatedBy?: User;

  updatedAt?: Date;
}
