import { AffApplication, AffApplyRoutes } from '@aff-apply/entities';
import { Component, OnInit } from '@angular/core';
import { User } from '@common/entities';
import { ContextProvider } from '@common/ui/shared-components';
import { Auth } from 'aws-amplify';
import { filter, Observable, switchMap, tap } from 'rxjs';

import { GraphqlService } from '../../../../shared/graphql.service';
import { Router } from '@angular/router';

@Component({
  selector: 'aff-user-dashboard-page',
  templateUrl: './user-dashboard-page.component.html',
  styleUrls: ['./user-dashboard-page.component.scss'],
})
export class UserDashboardPageComponent implements OnInit {
  application$: Observable<AffApplication>;
  currentUser$: Observable<User>;
  isLoading = true;

  constructor(
    private router: Router,
    private graphqlService: GraphqlService,
    private contextProvider: ContextProvider
  ) {}

  async ngOnInit(): Promise<void> {
    const redirect = window?.sessionStorage?.getItem('redirect');
    if (redirect) {
      const val = JSON.parse(redirect);
      window.sessionStorage.removeItem('redirect');

      if (val?.expires > new Date().getTime() && val?.value) {
        if (val.value.indexOf('?') > -1) {
          window.location.href = window.location.origin + val.value;
        } else {
          this.router.navigate([val.value]);
        }
      }
    }

    this.application$ = this.contextProvider.getCurrentUser().pipe(
      filter((user) => !!user),
      switchMap(async (user) => {
        const session = await Auth.currentSession();
        const applicationId = session?.getIdToken()?.payload?.sub;
        return applicationId;
      }),
      switchMap((applicationId) => {
        return this.graphqlService.getProcessedApplicationById(applicationId).pipe(
          tap((application) => {
            if (application) {
              this.router.navigate([AffApplyRoutes.MyAppStatus.baseUrl]);
            } else {
              this.isLoading = false;
            }
          })
        );
      })
    );
  }
}
