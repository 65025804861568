import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Auth } from 'aws-amplify';
import { AppConfigService } from '../../services/config/config.service';

@Injectable({
  providedIn: 'root',
})
export class CognitoAuthenticationGuard implements CanActivate {
  constructor(protected readonly router: Router, private config: AppConfigService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    return Auth.currentSession()
      .then(
        (value) =>
          ['http://identity.alberta.ca/ivsStatus/verified', 'http://identity.alberta.ca/ivsStatus/pending'].includes(
            value?.getIdToken()?.payload?.['custom:ivsStatus']
          ) || this.router.createUrlTree(['/upgrade'])
      )
      .catch<boolean>((error) => {
        //this.router.navigate([`/prescreen/eligible`]);
        Auth.federatedSignIn({
          customProvider: this.config.getConfig().USER_POOL_MADI_PROVIDER_NAME,
          customState: state.url,
        });
        return false;
      });
  }
}
