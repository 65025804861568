import { registerDecorator } from 'class-validator';

//import { MarkRequired } from 'ts-essentials';

type AnyClass = { new (...args: any[]): any };

type PublicConstructor = new (...args: any[]) => any;

/** The response type for registerClassValidator() */
export type ClassValidationDecorator = <T extends AnyClass>(target: T) => PublicConstructor;

/** A helper method to create a new Class-level validation decorator. */
export function registerClassValidator(options: {
  name: string;
  validator;
  // validator: new () => MarkRequired<
  //   ValidatorConstraintInterface,
  //   'defaultMessage'
  // >;
  constraints: any[];
}): ClassValidationDecorator {
  return function decorateClass<T extends AnyClass>(target: T): PublicConstructor {
    const { name, validator, constraints } = options;

    registerDecorator({
      name,
      target,
      propertyName: target.name,
      constraints,
      validator,
      options: {
        always: true,
      },
    });

    return target;
  };
}
