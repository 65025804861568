import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakUserService } from '../../shared/keycloak-user.service';
import { Observable, map } from 'rxjs';
import { AffApplyRoutes, KeycloakUser } from '@aff-apply/entities';

@Component({
  selector: 'aff-application-error-page',
  templateUrl: './application-error-page.component.html',
  styleUrls: ['./application-error-page.component.scss'],
})
export class ApplicationErrorPageComponent implements OnInit {
  userInfo: KeycloakUser;
  applicationCode$: Observable<string>;

  constructor(route: ActivatedRoute, private router: Router, private keycloakUserService: KeycloakUserService) {
    this.applicationCode$ = route.paramMap.pipe(
      map((p) => {
        return p.get('applicationCode');
      })
    );
  }

  async ngOnInit() {
    this.userInfo = await this.keycloakUserService.userInfo();
  }

  onCloseApplication() {
    if (this.userInfo?.org) {
      this.router.navigate([AffApplyRoutes.ClerkSupport.baseUrl]);
    } else if (this.userInfo) {
      this.router.navigate([AffApplyRoutes.GoaSupport.baseUrl]);
    } else {
      this.router.navigate([AffApplyRoutes.Welcome.baseUrl]);
    }
  }
}
