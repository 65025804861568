import { Directive, HostListener } from '@angular/core';

export interface AbstractComponentCanDeactivateReturn {
  canDeactivate: boolean;
  title?: string;
  message?: string;
  noLabel?: string;
  yesLabel?: string;
}

@Directive()
export abstract class AbstractComponentCanDeactivateDirective {
  abstract canDeactivate(): AbstractComponentCanDeactivateReturn;

  @HostListener('window:beforeunload', ['$event']) unloadNotification($event): void {
    if (!this.canDeactivate().canDeactivate) {
      $event.returnValue = true;
    }
  }
}
