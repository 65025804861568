<h2>404 - Page not found</h2>
<p>
  If you entered a web address, check it is correct.<br /><br />

  You can <a href="/">Apply for the Affordabiltiy benefit online</a>.<br /><br />

  You can view
  <a href="https://www.alberta.ca/affordability.aspx" target="_blank" rel="noopener noreferrer"
    >information about the Affordability Initiativeon Alberta.ca</a
  >. <br /><br />
</p>
