import { registerDecorator, ValidationOptions, ValidationArguments } from 'class-validator';
import * as bankValidator from '@govalta/bank-validation';

export function IsBankAccountValid(validationOptions?: ValidationOptions) {
  return (object: any, propertyName: string) => {
    registerDecorator({
      name: 'IsBankAccountValid',
      target: object.constructor,
      propertyName,
      constraints: [],
      options: validationOptions,
      validator: {
        validate(value: any, args: ValidationArguments) {
          const institutionNumber = value?.institutionNumber;
          const transitNumber = value?.transitNumber;
          const accountNumber = value?.accountNumber;

          if (!institutionNumber || !transitNumber || !accountNumber) {
            return null;
          }
          const result = bankValidator.bankValidation(institutionNumber, transitNumber, accountNumber);

          return result.isValid;
        },
      },
    });
  };
}
