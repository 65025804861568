<div class="application-error-container">
  <p *ngIf="userInfo">
    <print-button title="Print Summary"></print-button>
  </p>
  <p>
    Application ID: <strong>{{ applicationCode$ | async }}</strong>
  </p>
  <div class="heading with-icon-container">
    <div class="icon">
      <img src="assets/images/x_circle_icon.svg" />
    </div>
    <h2 class="with-icon">We can’t proceed with the application.</h2>
  </div>
  <div class="divider-line"></div>
  <h3>Error code 100</h3>
  <div class="message">
    For assistance, please call the Alberta Affordability Program Information Line toll-free anywhere in the province.
    <br />
    <br />
    Toll-free number:
    <span class="tel"><a href="tel:18446449955">1-844-644-9955 </a><span>1-844-644-9955</span></span>
  </div>
  <button
    *ngIf="userInfo"
    data-cy="close-application"
    class="goa--primary print-hide"
    goa-button
    (click)="onCloseApplication()"
  >
    Close application
  </button>
</div>
