import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import {Injectable, Injector} from '@angular/core';
import { Observable, of } from "rxjs";
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class CompositeRouteGuard implements CanActivate {

  constructor(  protected router: Router,
                protected injector: Injector ) {
  }

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> {
    let compositeCanActivateObservable: Observable<boolean> = of(true);

    const routeGuards = route.data.routeGuards;

    if(routeGuards){
      for (let i = 0; i < routeGuards.length; i++){
        const routeGuard = this.injector.get(routeGuards[i]);
        const canActivateObservable: Observable<boolean> = routeGuard.canActivate(route, state);

        compositeCanActivateObservable = compositeCanActivateObservable.pipe(
            mergeMap((bool) => {
            if(!bool){
              return of(false);
            }
            else{
              return canActivateObservable;
            }
        }));
      }
    }

    return compositeCanActivateObservable;
  }
}