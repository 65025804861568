import { Component, Input, OnDestroy } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PhoneNumber } from '@common/entities';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Utilities } from '@common/utils';

/**
 * Form component for entry of multiple phone numbers with primary
 */
@Component({
  selector: 'common-phone-number-primary',
  templateUrl: './phone-number-primary.component.html',
  styleUrls: ['./phone-number-primary.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: PhoneNumberPrimaryComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: PhoneNumberPrimaryComponent,
      multi: true,
    },
  ],
})
export class PhoneNumberPrimaryComponent implements ControlValueAccessor, OnDestroy {
  _destroy$ = new Subject<void>();

  _uniqueGroupId = Utilities.createObjectId();

  /**
   * Should the control allow zero numbers
   */
  @Input() allowNoNumbers = false;

  /**
   * Is touched.  This is a bit of a hack, because CVA doesn't propogate markAllAsTouched
   */
  @Input() set isTouched(value) {
    if (value) {
      this.formArray.markAllAsTouched();
    } else {
      this.formArray.markAsUntouched();
    }
  }

  formArray = this.formBuilder.array([]);

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  validate() {
    return !this.hasInvalidOrEmptyPhoneNumber() ? null : { invalid: true };
  }

  writeValue(obj: PhoneNumber[]): void {
    const formArrayLength = this.formArray.length;
    const phoneArrayLength = obj?.length ?? 0;

    if (phoneArrayLength > formArrayLength) {
      for (let i = phoneArrayLength - 1; i > formArrayLength - 1; i--) {
        this.formArray.push(
          this.formBuilder.control({
            phoneNumber: obj[i].phoneNumber,
            type: obj[i].type,
            isPrimary: obj[i].isPrimary ?? false,
          }),
          {
            emitEvent: false,
          }
        );
      }
    } else if (phoneArrayLength < formArrayLength) {
      for (let i = formArrayLength - 1; i > phoneArrayLength - 1; i--) {
        this.formArray.removeAt(i, { emitEvent: false });
      }
    }
    this.formArray.setValue(
      obj?.map((phone) => {
        return { phoneNumber: phone.phoneNumber, type: phone.type, isPrimary: phone.isPrimary ?? false };
      }) ?? [],
      { emitEvent: false }
    );

    if (this.formArray.length === 0 && !this.allowNoNumbers) {
      this.formArray.push(
        this.formBuilder.control({
          phoneNumber: '',
          type: 'Mobile',
          isPrimary: true,
        }),
        { emitEvent: false }
      );
    }

    if (this.formArray.touched) {
      this.formArray.markAllAsTouched();
    }
  }

  registerOnChange(fn) {
    this.formArray.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(fn);
  }
  touched;
  registerOnTouched(fn) {
    this.touched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.formArray.disable({ emitEvent: false });
    } else {
      this.formArray.enable({ emitEvent: false });
    }
  }

  addNumber() {
    this.formArray.push(
      this.formBuilder.control({
        phoneNumber: '',
        type: 'Mobile',
        isPrimary: !this.formArray.value.find((p) => p.isPrimary), //new phones default to primary if there is no primary yet
      })
    );

    if (this.formArray.touched) {
      this.formArray.markAllAsTouched();
    }
  }

  hasInvalidOrEmptyPhoneNumber(): boolean {
    return this.formArray.invalid || this.isAnyPhoneNumberEmpty();
  }

  isAnyPhoneNumberEmpty(): boolean {
    return this.formArray.value.some((x) => x.phoneNumber === '');
  }
  /**
   * Called when the delete number button is clicked
   * @param $index The index of the number being deleted
   */
  deleteNumber(index) {
    this.formArray.removeAt(index);
    if (!this.formArray.value.find((p) => p.isPrimary) && this.formArray.value.length > 0) {
      this.formArray.get('0').setValue({
        ...this.formArray.value[0],
        isPrimary: true,
      });
    }
  }

  isPrimaryChanged(phoneNumber: AbstractControl) {
    this.formArray.controls.forEach((c) => {
      c.setValue({ ...c.value, isPrimary: false }, { emitEvent: false });
    });

    const phoneNumberValue = phoneNumber.value;
    phoneNumber.setValue({
      ...phoneNumberValue,
      isPrimary: !phoneNumberValue.isPrimary,
    });
  }
}
