import { Address } from '@common/entities';
import { SinFormat, StringMax, StringMin } from '@common/validation';
import { Expose, Type } from 'class-transformer';
import { IsDefined, IsEmail, IsNotEmpty, IsOptional, MinLength, ValidateIf, ValidateNested } from 'class-validator';
import * as dayjs from 'dayjs';
import { OneOfFieldsRequired } from '../validators/one-of-fields-required.validator';
import { NameChangeReason } from './name-change-reason.entity';

@OneOfFieldsRequired(['phoneNumber', 'email'])
export class Applicant {
  @Expose()
  @IsNotEmpty()
  firstName: string;

  @Expose()
  @IsOptional()
  middleName?: string;

  @Expose()
  @IsNotEmpty()
  lastName: string;

  @Expose()
  @IsNotEmpty()
  @StringMin('1900-01-01') //on or after 1900-01-01
  @StringMax(dayjs().format('YYYY-MM-DD'))
  birthdate: string;

  @Expose()
  @IsNotEmpty()
  @SinFormat()
  socialInsuranceNumber: string;

  @Expose()
  @ValidateIf((o) => o.socialInsuranceNumber?.startsWith('9'))
  @IsNotEmpty()
  @StringMin(dayjs().format('YYYY-MM-DD'))
  sinExpiryDate: string;

  @Expose()
  @ValidateIf((o) => o.email?.length > 0)
  @IsEmail()
  email?: string;

  @Expose()
  @ValidateIf((o) => o.phoneNumber?.length > 0)
  @MinLength(10)
  phoneNumber?: string;

  @Expose()
  @Type(() => Address)
  @IsDefined()
  @ValidateNested()
  address: Address;

  @Expose()
  @Type(() => NameChangeReason)
  nameChangeReason?: NameChangeReason;
}
