import { UntypedFormControl, ValidatorFn } from '@angular/forms';

export class RadioButtonValidator {
  static validValueSelected(validValues: unknown[]): ValidatorFn {
    return function validate(formControl: UntypedFormControl) {
      const isValid = validValues.includes(formControl.value);
      return isValid
        ? null
        : { validValueSelected: { value: `One of the following values must the selected ${validValues.join(',')}` } };
    };
  }
}
