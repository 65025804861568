import { NgModule } from '@angular/core';
import { from, InMemoryCache } from '@apollo/client/core';
import { AppConfigService, UnauthenticatedErrorLink } from '@common/ui/shared-components';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { KeycloakService } from 'keycloak-angular';

export function createApollo(
  httpLink: HttpLink,
  configService: AppConfigService,
  apolloLink: UnauthenticatedErrorLink
) {
  const config = configService.getConfig();

  // const authAPIKey = setContext(() => ({
  //   headers: new HttpHeaders()
  //     .set('Content-Type', 'application/graphql')
  //     .set('x-api-key', config.AFF_APPLY_SERVICE_API_KEY),
  // }));

  return {
    // link: from([apolloLink.errorLink, authAPIKey, httpLink.create({ uri: config.AFF_APPLY_SERVICE_URL })]),
    link: from([apolloLink.errorLink, httpLink.create({ uri: config.AFF_APPLY_SERVICE_URL })]),
    cache: new InMemoryCache({
      addTypename: true,
      typePolicies: {
        UserDto: {
          keyFields: ['_id', 'email'],
        },
        //AffApplicationDto: {},
      },
    }),
  };
}

@NgModule({
  imports: [ApolloModule],
  providers: [
    UnauthenticatedErrorLink,
    KeycloakService,
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AppConfigService, UnauthenticatedErrorLink],
    },
  ],
})
export class GraphQLModule {}
