<div class="date-picker-container" [formGroup]="formGroup">
  <input
    maxlength="2"
    class="day-input control-margin"
    formControlName="day"
    [attr.data-cy]="dataCyPrefix + 'day'"
    aria-label="Day"
    type="text"
    (blur)="onBlur()"
    placeholder="Day"
    iesNumberOnly
    [wholeNumbersOnly]="true"
    *ngIf="!hideDay"
    inputmode="numeric"
  />
  <mat-select
    class="month-dropdown control-margin"
    [attr.data-cy]="dataCyPrefix + 'month'"
    aria-label="Month"
    (selectionChange)="onYearMonthChanged()"
    (blur)="onBlur()"
    formControlName="month"
    placeholder="{{ defaultSelectionLabel }}"
  >
    <mat-option [disabled]="!allowDefaultSelection" value="">{{ defaultSelectionLabel }}</mat-option>
    <mat-option *ngFor="let month of _months" [value]="month.value">
      {{ month.label }}
    </mat-option>
  </mat-select>
  <input
    #yearControl
    minlength="4"
    maxlength="4"
    class="year-input"
    formControlName="year"
    [attr.data-cy]="dataCyPrefix + 'year'"
    aria-label="Year"
    type="text"
    (input)="onYearMonthChanged()"
    (blur)="onBlur()"
    placeholder="Year"
    iesNumberOnly
    [wholeNumbersOnly]="true"
    inputmode="numeric"
  />
</div>
<ng-container *ngIf="showErrorMessages">
  <ng-container *ngIf="dayControl.touched && dayControl.invalid">
    <div
      class="error-text"
      *ngIf="(dateFormControl.untouched || dateFormControl.errors?.datePicker) && dayControl.errors.required"
    >
      Day is required.
    </div>
    <div class="error-text" *ngIf="dayControl.errors?.minlength">
      Day is invalid. Required minimum length:
      {{ dayControl.errors.minlength.requiredLength }}, actual:
      {{ dayControl.errors.minlength.actualLength }}
    </div>
    <div class="error-text" *ngIf="dayControl.errors?.min">
      Day is invalid. minimum value:
      {{ dayControl.errors.min.min }}, actual:
      {{ dayControl.errors.min.actual }}
    </div>
    <div class="error-text" *ngIf="dayControl.errors?.max">
      Day is invalid. max value:
      {{ dayControl.errors.max.max }}, actual:
      {{ dayControl.errors.max.actual }}
    </div>
  </ng-container>
  <ng-container *ngIf="monthControl.touched && monthControl.invalid">
    <div
      class="error-text"
      *ngIf="(dateFormControl.untouched || dateFormControl.errors?.datePicker) && monthControl.errors.required"
    >
      Month is required.
    </div>
  </ng-container>
  <ng-container *ngIf="yearControl.touched && yearControl.invalid">
    <div
      class="error-text"
      *ngIf="(dateFormControl.untouched || dateFormControl.errors?.datePicker) && yearControl.errors.required"
    >
      Year is required.
    </div>

    <div class="error-text" *ngIf="yearControl.errors.minlength">
      Year is invalid. minimum length:
      {{ yearControl.errors.minlength.requiredLength }}, actual:
      {{ yearControl.errors.minlength.actualLength }}
    </div>
  </ng-container>
</ng-container>
