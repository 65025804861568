// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/* eslint @typescript-eslint/no-var-requires: "off" */
export const environment = {
  production: false,
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  appVersion: require('../../../../../package.json').version,
  // NOTE: Do not add any additional config values here.  We are using dynamic/runtime configuration (this file is for compile time config)
  // so that we can build once and deploy to all environments.  The only compile time config we are using is the 'production' flag to indicate
  // if the build was done with the PROD flag or not (i.e. nx build --PROD)
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
