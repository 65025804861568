import { AffApplyRoutes } from '@aff-apply/entities';
import { Component, OnInit } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { NavigationEnd, Router } from '@angular/router';
import { User } from '@common/entities';
import { AppConfigService, ContextProvider } from '@common/ui/shared-components';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ToastrService } from 'ngx-toastr';
import { filter, map } from 'rxjs/operators';

import { ServiceErrorPageComponent } from '../../pages/service-error-page/service-error-page.component';
import { Features } from '@common/constants';

@Component({
  selector: 'aff-app',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 0,
        hideDelay: 0,
        touchGestures: 'auto',
        position: 'before',
        touchendHideDelay: 0,
        disableTooltipInteractivity: true,
      },
    },
  ],
})
@UntilDestroy()
export class ApplicationComponent implements OnInit {
  user!: User;
  isDisabled = false;
  showHelpLine = true;
  copyrightYear = new Date().getFullYear();
  showGoABanner = true;
  todayDate = new Date();
  features: { [key: string]: boolean };

  constructor(
    private router: Router,
    private toasterService: ToastrService,
    private contextProvider: ContextProvider,
    configService: AppConfigService
  ) {
    this.features = configService.getFeatures();
  }

  isTrainingActive() {
    return this.features && this.features[Features.IsTraining] === true;
  }

  async ngOnInit() {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        map((e) => e as NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.toasterService.clear();

        // if we navigated to service error, remove all routes
        if (this.router.url.includes(AffApplyRoutes.ServiceError.baseUrl)) {
          this.isDisabled = true;
          this.router.resetConfig([{ path: 'service-error', component: ServiceErrorPageComponent }]);
        }
      });

    this.contextProvider
      .getCurrentUser()
      .pipe(untilDestroyed(this))
      .subscribe((currentUser) => {
        this.user = currentUser;
        //roles here is public user, or clerk-type from the token
        this.showHelpLine = !this.user?.roles?.includes('goa-resolution-clerk');
      });
  }
}
