<div class="prescreen-container">
  <goa-callout type="important" title="You need to upgrade your Alberta.ca account.">
    <div class="callout-paragraph">
      To view your benefit details, you need an Alberta.ca account that is either pending verified or already verified.
    </div>
  </goa-callout>

  <div class="information">
    If you believe you have reached this page in error and have the correct pending account, then choose sign in.
  </div>
  <div class="mt24 mb24">
    <button data-cy="sign-in" class="apply-button" buttonType="secondary" goa-button (click)="signIn()">Sign in</button>
    <button data-cy="upgrade-account" class="apply-button" goa-button (click)="upgradeAccount()">
      Upgrade account
    </button>
  </div>
</div>
