<div class="application-container" [ngClass]="{ training: isTrainingActive() }">
  <common-environment-header class="env-header"></common-environment-header>
  <div class="header">
    <goa-header serviceHome="/" serviceName="Affordability application portal" serviceLevel="live"> </goa-header>
    <div class="print-header">
      <div>Protected B</div>
      <div>{{ todayDate | date: 'MMM dd, yyyy' }}</div>
    </div>
    <div class="my-account">
      <aff-my-account></aff-my-account>
    </div>
  </div>
  <div class="content-section">
    <div class="goa-banner" iesGoaBanner *ngIf="showGoABanner">
      <h1 id="intake-section-label">Affordability application portal</h1>
      <div class="descriptor" id="intake-section-sublabel">Apply for monthly inflation relief payments.</div>
    </div>
    <div class="print-hide">
      <maintenance-mode-banner appName="Affordability Initiative"></maintenance-mode-banner>
    </div>
    <main class="content">
      <router-outlet></router-outlet>
    </main>
  </div>
  <div class="contact-us print-hide" *ngIf="showHelpLine">
    <div class="contact-desktop">
      <strong>Have any questions? </strong> Call our Affordability Action Plan information line at 1-844-644-9955
    </div>
    <div class="contact-mobile">
      <strong>Have any questions? </strong> <br />Call our Affordability Action Plan <br />information line at<br />
      <a href="tel:18446449955 ">1-844-644-9955 </a>
    </div>
  </div>
  <footer class="goa-footer print-hide">
    <div class="link">
      <a href="https://alberta.ca">Alberta.ca</a>
      <a class="disclaimer-link" href="https://www.alberta.ca/disclaimer.aspx">Disclaimer</a>
    </div>

    <div class="copyright">
      <span>© {{ copyrightYear }} Government of Alberta</span>
    </div>
  </footer>
</div>
