<div class="multi-select-container">
  <mat-chip-list
    selectable
    multiple
    #chipList
    [ngClass]="{ focused: isFocused, invalid: showError, disabled: disabled }"
  >
    <mat-chip
      #chip="matChip"
      *ngFor="let option of options; let $index = index"
      [value]="option.value"
      (click)="toggleSelection(chip)"
    >
      <mat-icon *ngIf="chip.selected" [tabindex]="$index">check</mat-icon>
      {{ option.displayValue }}
    </mat-chip>
  </mat-chip-list>
</div>
