import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { KeycloakOptions, KeycloakService } from 'keycloak-angular';

import { AffApplyRoutes } from '@aff-apply/entities';
import { AppConfigService } from '../../../ui-shared-components.services';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class KeycloakLoginGuard implements CanActivate, CanActivateChild {
  defaultNotAuthorized = AffApplyRoutes.NotAuthorized.baseUrl;

  constructor(
    protected router: Router,
    protected readonly keycloakService: KeycloakService,
    private configService: AppConfigService
  ) {}
  async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkAuth(childRoute, state);
  }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkAuth(route, state);
  }

  async checkAuth(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      // 60000 = 1 standard Earth minute
      const expirationDate = new Date().getTime() + 60000;
      const redirect = {
        expires: expirationDate,
        value: state.url,
      };
      window?.sessionStorage?.setItem('redirect', JSON.stringify(redirect));
      await Auth.signOut();
    } catch (ex) {}

    let loggedIn: boolean;
    try {
      const keycloakInstance = this.keycloakService.getKeycloakInstance();

      if (keycloakInstance) {
        loggedIn = await this.keycloakService.isLoggedIn();
      }

      if (!loggedIn) {
        await this.configService.loadConfig();

        const configValues = this.configService.getConfig();

        const keycloakLoginConfig = configValues.KEYCLOAK_LOGIN;
        const config: KeycloakOptions = {
          config: {
            url: keycloakLoginConfig.URL,
            realm: keycloakLoginConfig.REALM,
            clientId: keycloakLoginConfig.CLIENTID,
          },
          initOptions: {
            // onLoad: 'check-sso',
            checkLoginIframe: true,
          },
          bearerPrefix: '',
        };
        const initAndReturnLoginStatus = await this.keycloakService.init(config);

        if (!initAndReturnLoginStatus) {
          // if (this.router?.navigateByUrl && this.router?.url) {
          //   this.router.navigateByUrl(this.router.url + '#auth'); //this will ensure if user hits back on keycloak, that it goes to the correct page.
          // }
          await this.keycloakService.login({
            redirectUri: window.location.origin + state.url,
            idpHint: 'core',
            // prompt: 'login',
          });
          return initAndReturnLoginStatus;
        }
        loggedIn = initAndReturnLoginStatus;
        const realm_access = this.keycloakService.getKeycloakInstance().tokenParsed.realm_access;
        const goaSupportRole = realm_access.roles.find((r) => r === 'aff:goa-support');
        const goaResoltionClerkRole = realm_access.roles.find((r) => r === 'aff:resolution-clerk');
        if (route.url.find((s) => s.path === 'goa-clerk')) {
          loggedIn = goaSupportRole !== undefined;
        } else if (route.url.find((s) => s.path === 'goa-resolution-clerk')) {
          loggedIn = goaResoltionClerkRole !== undefined;
        }

        if (!loggedIn) {
          this.router.navigate([this.defaultNotAuthorized]);
        }
      }
    } catch (ex) {
      //   console.log('Auth Ex: ' + ex);
      this.router.navigate([this.defaultNotAuthorized]);
    }
    return loggedIn;
  }
}
