/* eslint-disable @typescript-eslint/ban-types */
import { FindAddressResult, RetrieveAddressResult } from '@aff-apply/entities';
import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import {
  Validators,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  NG_VALIDATORS,
  FormGroup,
} from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Address } from '@common/entities';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime, map, startWith, takeUntil } from 'rxjs/operators';
import { StringValidator } from '../shared/validators/string.validator';

@Component({
  selector: 'common-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: AddressComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: AddressComponent,
      multi: true,
    },
  ],
})
@UntilDestroy()
export class AddressComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Output() findAddress = new EventEmitter<string>();
  @Output() retrieveAddress = new EventEmitter<string>();
  isFindingAddresses = false;

  _findAddressResults: FindAddressResult[];
  @Input() set findAddressResults(value: FindAddressResult[]) {
    this._findAddressResults = value;
    if (value) {
      this.isFindingAddresses = false;
    }
  }
  get findAddressResults() {
    return this._findAddressResults;
  }

  @Input() set retrieveAddressResult(value: RetrieveAddressResult) {
    if (value) {
      this.formGroup.patchValue({
        city: value.city,
        postalCode: value.postalCode,
        streetAddress: value.address,
        suiteNumber: null,
        province: 'Alberta',
      });
    }
  }

  private defaultProvince = 'Alberta';

  private allProvinces = [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon',
  ];

  get provinces() {
    return this.allProvinces.filter((p) => !this.filterProvinces || this.filterProvinces.indexOf(p) >= 0);
  }

  _destroy$ = new Subject<void>();

  @Input() dataCyPrefix = '';
  @Input() hideProvince = false;
  @Input() hideOptionalLabel = false;
  @Input() postalCodeFormat = '';

  /**
   * Is touched.  This is a bit of a hack, because CVA doesn't propogate markAllAsTouched
   */
  @Input() set isTouched(value) {
    value ? this.formGroup.markAllAsTouched() : this.formGroup.markAsUntouched();
  }

  _isRequired: boolean;
  @Input() set isRequired(value: boolean) {
    this._isRequired = value;
    if (value) {
      this.formGroup.controls.streetAddress.setValidators([Validators.required, StringValidator.notEmpty]);
      this.formGroup.controls.streetAddress.updateValueAndValidity();
      this.formGroup.controls.city.setValidators([Validators.required, StringValidator.notEmpty]);
      this.formGroup.controls.city.updateValueAndValidity();
      this.formGroup.controls.postalCode.setValidators([Validators.required]);
      this.formGroup.controls.postalCode.updateValueAndValidity();
    } else {
      this.formGroup.controls.streetAddress.clearValidators();
      this.formGroup.controls.streetAddress.updateValueAndValidity();
      this.formGroup.controls.city.clearValidators();
      this.formGroup.controls.city.updateValueAndValidity();
      this.formGroup.controls.postalCode.clearValidators();
      this.formGroup.controls.postalCode.updateValueAndValidity();
    }
  }

  _enableProvince: boolean;
  @Input() set enableProvince(value: boolean) {
    this._enableProvince = value;
    if (value) {
      //enabled
      this.formGroup.controls.province.enable();
    } else {
      this.formGroup.controls.province.disable();
    }
  }

  @Input() filterProvinces?: String[] = null;

  formGroup: FormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.formGroup = this.formBuilder.group(
      {
        streetAddress: [''],
        suiteNumber: [''],
        city: [''],
        province: [{ value: this.defaultProvince, disabled: true }],
        postalCode: [''],
      }
      // { updateOn: 'blur' }
    );
  }

  ngOnInit(): void {
    this.formGroup.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((value) => {
      if (!value.province) {
        value.province = this.defaultProvince;
      }
      this.changed(value);
    });

    this.formGroup.controls.streetAddress.valueChanges
      .pipe(
        untilDestroyed(this),
        startWith(null),
        debounceTime(500),
        map(() => {
          this._findAddressResults = [];
          const findAddressVal = this.formGroup.controls.streetAddress.value;
          if (findAddressVal?.length > 2) {
            this.isFindingAddresses = true;
            this.findAddress.emit(findAddressVal);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  onClearFindAddress(): void {
    this.formGroup.controls.streetAddress.setValue(null);
    this._findAddressResults = null;
  }

  onSelectAddress(event: MatAutocompleteSelectedEvent): void {
    if (!event.option.value) {
      return;
    }

    this.formGroup.controls.streetAddress.setValue(null);
    this._findAddressResults = null;
    // prevent the form error from appearing while retrieving the address
    this.formGroup.controls.streetAddress.markAsUntouched();
    this.formGroup.controls.city.markAsUntouched();
    this.formGroup.controls.postalCode.markAsUntouched();

    this.retrieveAddress.emit(event.option.value._id);
  }

  validate() {
    return !this._isRequired || this.formGroup.valid ? null : { invalid: true };
  }

  writeValue(address: Address): void {
    this.setFormData(address);
  }

  private changed;
  registerOnChange(fn) {
    this.changed = fn;
  }
  private touched;
  registerOnTouched(fn) {
    this.touched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable({ emitEvent: false }) : this.formGroup.enable({ emitEvent: false });
  }

  private setFormData(address) {
    if (address === null) this.formGroup.reset({}, { emitEvent: false });
    if (address && !address.province) {
      address.province = this.defaultProvince;
    }
    const patchValue = (address && { province: this.defaultProvince, ...address }) || {
      province: this.defaultProvince,
    };
    this.formGroup.patchValue(patchValue, { emitEvent: false });
  }
}
