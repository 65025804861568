import { ClerkType, KeycloakUser } from '@aff-apply/entities';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root',
})
export class KeycloakUserService {
  private _userInfo: KeycloakUser = null;

  constructor(private keycloakService: KeycloakService) {}

  async userInfo(): Promise<KeycloakUser> {
    const isLoggedIn = await this.keycloakService.isLoggedIn();
    if (!isLoggedIn) {
      return null;
    }

    if (this._userInfo !== null) {
      return this._userInfo;
    }

    const instance = await this.keycloakService.getKeycloakInstance();
    const userInfo = (await instance.loadUserInfo()) as any;

    const hasName = userInfo?.given_name && userInfo?.family_name;
    const userName = userInfo.email ?? userInfo.preferred_username;

    this._userInfo = {
      keycloakUserId: userInfo.sub,
      username: userName,
      fullName: hasName ? `${userInfo.given_name} ${userInfo.family_name}` : userName,
      clerkType: userInfo['clerk-type'],
      org: userInfo['rsg-loginOrg'],
    };

    //kludgy workaround to get this working without major changes because ROADS and GOA clerks are in the same role but we need to differentiate them
    //cant just use clerk-type because that gets mapped from the IDP login and both GOA and RESOLUTION clerks use the same IDP, so RESOLUTION clerks were getting
    //the same clerk-type as GOA clerks
    if (userInfo.roles?.includes('aff:resolution-clerk')) {
      this._userInfo.clerkType = ClerkType.RESOLUTION;
    }

    return this._userInfo;
  }
}
