<div [formGroup]="formGroup">
  <common-form-control
    [label]="label"
    controlName="emailAddress"
    [isOptional]="!isRequired"
    [hideOptionalLabel]="hideOptionalLabel"
  >
    <input
      type="text"
      formControlName="emailAddress"
      data-cy="emailAddress"
      class="email-input"
      [placeholder]="emailPlaceHolder"
      (blur)="onEmailBlur()"
      iesNoSpaces
      email
    />
  </common-form-control>

  <div *ngIf="formGroup.controls.confirmEmailAddress">
    <common-form-control [label]="confirmLabel" controlName="confirmEmailAddress">
      <input
        type="text"
        formControlName="confirmEmailAddress"
        data-cy="confirmEmailAddress"
        class="email-input"
        [placeholder]="emailPlaceHolder"
        iesBlockCopyCutPaste
        (blur)="onEmailBlur()"
      />
    </common-form-control>
  </div>
</div>
