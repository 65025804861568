import { registerDecorator } from 'class-validator';
import { mod10Validation } from './luhn';

export function PhnFormat() {
  return function (object, propertyName: string) {
    registerDecorator({
      name: 'PhnFormat',
      target: object.constructor,
      propertyName: propertyName,
      validator: {
        validate(phn: string) {
          const mod10 = mod10Validation(phn, 5);
          return mod10 === null; //{ mod10Validation: true } result means failure!
        },
        defaultMessage(): string {
          return 'PHN must be a valid PHN';
        },
      },
    });
  };
}
