import { Platform } from '@angular/cdk/platform';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppDateFormats } from '../calendar/date-format';
import { DayJsDateAdapter } from '../calendar/dayjs-date-adapter';
import * as dayjs from 'dayjs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Component for date/time entry using a calendar
 */
@Component({
  selector: 'common-calendar-range',
  templateUrl: './calendar-range.component.html',
  styleUrls: ['./calendar-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: CalendarRangeComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: CalendarRangeComponent,
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: DayJsDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
    { provide: MAT_DATE_FORMATS, useValue: AppDateFormats },
  ],
})
export class CalendarRangeComponent implements OnInit, OnDestroy, ControlValueAccessor {
  _destroy$ = new Subject<void>();

  dateRangeGroup: UntypedFormGroup;

  /**
   * Is touched.  This is a bit of a hack, because CVA doesn't propogate markAllAsTouched
   */
  @Input() set isTouched(value) {
    value ? this.dateRangeGroup?.markAllAsTouched() : this.dateRangeGroup?.markAsUntouched();
  }

  /**
   * The max allowed date
   */
  @Input() set maxDate(value) {
    if (value) {
      this._maxDateDayJs = dayjs(value);
    }
  }
  _maxDateDayJs;

  /**
   * The min allowed date
   */
  @Input() set minDate(value) {
    if (value) {
      this._minDateDayJs = dayjs(value);
    }
  }
  _minDateDayJs;

  /**
   * Text entry for date
   */
  /*
  @ViewChild('startInput') _input;
  */

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    /*
    this.dateRangeGroup.controls.fromDate.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((value) => {
      this.onChange(value?.startOf('day')?.toDate() || null);
      this.touched();
    });
    this.dateRangeGroup.controls.toDate.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((value) => {
      this.onChange(value?.endOf('day')?.toDate() || null);
      this.touched();
    });
    */
    this.dateRangeGroup = new UntypedFormGroup({
      //this.formBuilder.group({
      fromDate: new UntypedFormControl(null, { updateOn: 'blur' }),
      toDate: new UntypedFormControl(null, { updateOn: 'blur' }),
    });
    //});

    this.dateRangeGroup.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((value) => {
      this.onChange({
        fromDate: value?.fromDate ? dayjs(value.fromDate).startOf('day')?.toDate() : null,
        toDate: value?.toDate ? dayjs(value.toDate).endOf('day')?.toDate() : null,
      });
      this.touched();
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  validate() {
    if (
      this.dateRangeGroup.value &&
      this.dateRangeGroup.value.fromDate &&
      this.dateRangeGroup.value.toDate &&
      this.dateRangeGroup.value.toDate.diff(this.dateRangeGroup.value.fromDate) < 0
    )
      return { invalidRange: true };

    if (
      this.dateRangeGroup.value &&
      !(this.dateRangeGroup.value.fromDate && this.dateRangeGroup.value.toDate) &&
      (this.dateRangeGroup.value.fromDate || this.dateRangeGroup.value.toDate)
    )
      return { incompleteRange: true };
    if (this.dateRangeGroup?.invalid)
      return this.dateRangeGroup.controls.fromDate.errors || this.dateRangeGroup.controls.toDate.errors;
  }

  writeValue(obj): void {
    this.dateRangeGroup.controls.fromDate.setValue(obj?.fromDate ? dayjs(obj?.fromDate).startOf('day') : obj?.fromDate);
    this.dateRangeGroup.controls.toDate.setValue(obj?.toDate ? dayjs(obj?.toDate).endOf('day') : obj?.toDate);
  }
  onBlur() {
    this.touched();
  }
  // ! We need the empty function defined here or the onChange doesnt bind properly and never fires
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange = (dateRange) => {
    //implementation to be replaced
  };
  registerOnChange(fn) {
    this.onChange = fn;
  }
  // ! We need the empty function defined here or the touched doesnt bind properly and never fires
  touched = () => {
    //implementation to be replaced
  };  registerOnTouched(fn) {
    this.touched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    /*
    this.dateRangeGroup.controls.fromDate[isDisabled ? 'disable' : 'enable']({ emitEvent: false });
    this.dateRangeGroup.controls.toDate[isDisabled ? 'disable' : 'enable']({ emitEvent: false });
    */
    isDisabled ? this.dateRangeGroup.disable({ emitEvent: false }) : this.dateRangeGroup.enable({ emitEvent: false });
  }

  onKeyDown(control: AbstractControl, event: KeyboardEvent) {
    if (event.key === 'Enter' && event.target) {
      control.setValue(dayjs(event.target['value']));
    }
  }
}
