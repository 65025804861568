import { Component, Input } from '@angular/core';

@Component({
  selector: 'aff-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss'],
})
export class UserDashboardComponent {
  @Input() isLoading: boolean;
}
