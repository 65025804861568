import { AffApplyRoutes } from '@aff-apply/entities';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '@common/ui/shared-components';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'aff-upgrade-account',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss'],
})
export class UpgradeAccountComponent {
  constructor(private router: Router, private config: AppConfigService) {}

  upgradeAccount() {
    window.open(
      `https://${
        this.config.getConfig().USER_POOL_MADI_PROVIDER_NAME == 'MADIUAT' ? 'uat.' : ''
      }account.alberta.ca/login`
    );
    this.router.navigate(['/logout'], { queryParams: { redirect: AffApplyRoutes.UpgradeAccount.baseUrl } });
  }
  signIn() {
    Auth.federatedSignIn({
      customProvider: this.config.getConfig().USER_POOL_MADI_PROVIDER_NAME,
      customState: `/${AffApplyRoutes.Apply.baseUrl}`,
    });
  }
}
