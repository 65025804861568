import { CursorValue } from "./cursor-value.entity";
import { KeyValue } from "./key-value.entity";

export interface MultiFieldPagedResult<T> {
  docs?: T[];
  hasNext: boolean;
  next?: CursorValue[];
  totalDocs?: number;
  additionalInfo?: KeyValue[];
}
