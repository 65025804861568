<div class="day">
  <div class="header">
    {{ showFullDate ? (viewDate | date: 'EEE') : (viewDate | date: 'EEE') }}
    <div class="day-number" [ngClass]="{ today: _isToday }">
      <span>{{ viewDate | date: 'd' }}</span>
    </div>
  </div>
  <div class="day-group">
    <ng-container *ngFor="let event of _filteredEvents">
      <div class="event-container">
        <ng-container *ngTemplateOutlet="eventTemplate; context: { event: event }"> </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="_filteredEvents && _filteredEvents.length === 0 && !isLoading">
      <div class="event-container">{{ hasMore ? 'No events yet loaded.' : 'No events on this day.' }}</div>
    </ng-container>
  </div>
</div>
