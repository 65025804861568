<div class="week">
  <!--div class="header" *ngIf="_startDay && _endDay">
    {{ _startDay | date: 'mediumDate' }} to {{ _endDay | date: 'mediumDate' }}
  </div-->
  <div class="week-grid" [ngClass]="{ 'include-weekends': _includeWeekends }">
    <ng-container *ngFor="let day of _weekDays; trackBy: trackBy">
      <div
        *ngIf="_includeWeekends || !isWeekend(day)"
        class="weekday"
        iesIconButton
        tabindex="0"
        (click)="setActiveDay(day)"
        [ngClass]="{ today: isToday(day), active: isActiveDay(day) }"
      >
        <common-worker-calendar-day-view
          [viewDate]="day"
          [isLoading]="isLoading"
          [hasMore]="hasMore"
          [showFullDate]="false"
          [events]="events"
          [eventTemplate]="eventTemplate"
        ></common-worker-calendar-day-view>
      </div>
    </ng-container>
  </div>
</div>
