import { Component, Input, OnDestroy, TemplateRef } from '@angular/core';
import { Subject } from 'rxjs';
import { CalendarEvent } from 'angular-calendar/modules/calendar.module';
import * as dayjs from 'dayjs';
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

@Component({
  selector: 'common-worker-calendar-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
})
export class WorkerCalendarListView implements OnDestroy {
  _destroy$ = new Subject<void>();

  _allOverdueEvents: CalendarEvent[] = [];
  _allUpcomingEvents: CalendarEvent[] = [];

  _overdueEvents0to7: CalendarEvent[] = [];
  _overdueEvents7to14: CalendarEvent[] = [];
  _overdueEvents14to30: CalendarEvent[] = [];
  _overdueEventsOther: CalendarEvent[] = [];

  _upcomingEventsToday: CalendarEvent[] = [];
  _upcomingEventsOther: CalendarEvent[] = [];

  private _events: CalendarEvent[];
  @Input() set events(value) {
    this._events = value;
    if (value) {
      const valueSorted = [...value];
      valueSorted.sort((a, b) => {
        if (!a.start && !b.start) return 0;
        if (!a.start) return -1;
        if (!b.start) return 1;

        if (dayjs(a.start).isBefore(b.start)) return -1;
        if (dayjs(a.start).isAfter(b.start)) return 1;
        return 0;
      });

      const today = dayjs(new Date()).startOf('day');
      this._allOverdueEvents = valueSorted.filter((e) => today.isAfter(e.start));
      this._allUpcomingEvents = valueSorted.filter((e) => today.isSameOrBefore(e.start));

      this._overdueEvents0to7 = this._allOverdueEvents.filter((e) => today.subtract(7, 'day').isBefore(e.start));
      this._overdueEvents7to14 = this._allOverdueEvents.filter(
        (e) => today.subtract(7, 'day').isSameOrAfter(e.start) && today.subtract(14, 'day').isBefore(e.start)
      );
      this._overdueEvents14to30 = this._allOverdueEvents.filter(
        (e) => today.subtract(14, 'day').isSameOrAfter(e.start) && today.subtract(30, 'day').isBefore(e.start)
      );
      this._overdueEventsOther = this._allOverdueEvents.filter((e) => today.subtract(30, 'day').isSameOrAfter(e.start));

      this._upcomingEventsToday = this._allUpcomingEvents.filter((e) => today.add(1, 'day').isAfter(e.start));
      this._upcomingEventsOther = this._allUpcomingEvents.filter((e) => today.add(1, 'day').isSameOrBefore(e.start));
    }
  }
  get events() {
    return this._events;
  }

  @Input() eventTemplate: TemplateRef<any>;
  @Input() isLoading = false;
  @Input() hasMore = false;

  constructor() {}

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
