import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

export const GOA_SECURITY_TXT_LOCATION = 'https://www.alberta.ca/.well-known/security.txt';

@Injectable({
  providedIn: 'root',
})
export class SecurityTxtRedirectGuard implements CanActivate {
  constructor(private window: Window) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.window.location = GOA_SECURITY_TXT_LOCATION;

    return false;
  }
}
