import { Pipe, PipeTransform } from '@angular/core';
import { Utilities } from '@common/utils';

@Pipe({ name: 'addYears' })
export class AddYearsPipe implements PipeTransform {
  transform(dateString: string, increment: number): Date | undefined {
    const date = Utilities.getDateFromString(dateString);
    if (!date) return undefined;

    date.setFullYear(date.getFullYear() + increment);
    return date;
  }
}
