import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer, UntypedFormGroup } from '@angular/forms';

/**
 * Component which wraps an input with optional, helper message, and validation functionality.
 */
@Component({
  selector: 'common-form-control',
  templateUrl: './form-control.component.html',
  styleUrls: ['./form-control.component.scss'],
})
export class FormControlComponent implements OnInit {
  /**
   * The label text.
   */
  @Input() abbreviatedLabel = 'Field';
  _label: string;
  @Input() set label(value: string) {
    this._label = value;
    if (this.abbreviatedLabel === 'Field') this.abbreviatedLabel = value;
  }

  /**
   * Should the formcontrol wrap content in label
   */
  @Input() shouldUseLabel = true;

  /**
   * Controls if the error text shows
   */
  @Input() showErrorMessages = true;

  @Input() labelOnTop = false;

  /**
   * Whether this form is optional
   */
  @Input() isOptional = false;
  /**
   * Hide (Optional) label
   */
  @Input() hideOptionalLabel = false;
  /**
   * Help message for the form
   */
  @Input() helperMessage: string;
  /**
   * Help text for the form
   */
  @Input() helperText: string;
  /**
   * The form control
   */
  @Input() control: AbstractControl;
  /**
   * Manual control over if form has error (as opposed to using formControl state.)
   */
  @Input() controlName: string;
  @Input() info;
  @Input() container;
  @Input() infoTooltip: string;
  @Input() helpTitle: string;
  @Input() helpTooltip: string;

  @Input() customErrorMessage: string;
  @Input() useDefaultErrorMessages = true;

  /**
   * will hide the label entirely
   */
  @Input() hideLabel = false;

  constructor(private controlContainer: ControlContainer) {}
  ngOnInit(): void {
    if (!this.control && this.controlName) {
      const formGroup = this.controlContainer.control as UntypedFormGroup;
      this.control = formGroup.controls[this.controlName];
    }
  }
  get hasError(): boolean {
    return this.control?.touched && this.control?.invalid;
  }
  get errors() {
    return this.control?.errors;
  }
}
