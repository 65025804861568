import {
  AffApplication,
  FindVerifiedApplicationResult,
  FindVerifiedApplicationsInput,
  FindAddressResult,
  ProcessedApplication,
  StepperStep,
  StepperSteps,
  RetrieveAddressResult,
  IncomeEligibilityOverride,
} from '@aff-apply/entities';
import { Utilities } from '@common/utils';
import { Apollo, gql } from 'apollo-angular';
import { map, share } from 'rxjs/operators';

import {
  ADDRESS_FRAGMENT,
  AFF_APPLICATION_FRAGMENT,
  AFF_PROCESSED_APPLICATION_FRAGMENT,
  APPLICANT_STEP_FRAGMENT,
  BANKING_STEP_FRAGMENT,
  DEPENDENTS_STEP_FRAGMENT,
  INCOME_STEP_FRAGMENT,
  MY_SITUATION_STEP_FRAGMENT,
  PROOF_OF_IDENTITY_STEP_FRAGMENT,
  SPOUSE_STEP_FRAGMENT,
  FIND_ADDRESS_RESULT_FRAGMENT,
  TOMBSTONE_FRAGMENT,
  RETRIEVE_ADDRESS_RESULT_FRAGMENT,
} from '../gql-fragments';
import { GraphqlService } from './graphql.service';
import { Router } from '@angular/router';

export const GET_APPLICATION_BY_ID = gql`
  query getApplicationById($id: String!) {
    getApplicationById(id: $id) {
      ${AFF_APPLICATION_FRAGMENT}
    }
  }
`;

export const GET_PROCESSED_APPLICATION_BY_ID = gql`
  query getProcessedApplicationById($id: String!) {
    getProcessedApplicationById(id: $id) {
      ${AFF_PROCESSED_APPLICATION_FRAGMENT}
    }
  }
`;

export const GET_VERIFIED_APPLICATION_BY_ID = gql`
  query getProcessedApplicationById($id: String!) {
    getProcessedApplicationById(id: $id) {
      ${AFF_APPLICATION_FRAGMENT}
    }
  }
`;

export const GET_STEPPER_APPLICATION_INFO = gql`
  query getApplicationById($id: String!) {
    getApplicationById(id: $id) {
      ${AFF_APPLICATION_FRAGMENT}
    }
  }
`;

export const GET_MY_SITUATION = gql`
  query getApplicationById($id: String!) {
    getApplicationById(id: $id) {
      ${MY_SITUATION_STEP_FRAGMENT}
    }
  }
`;

export const GET_PROOF_OF_IDENTITY = gql`
  query getApplicationById($id: String!) {
    getApplicationById(id: $id) {
      ${PROOF_OF_IDENTITY_STEP_FRAGMENT}
    }
  }
`;

export const GET_PERSONAL_INFO = gql`
  query getApplicationById($id: String!) {
    getApplicationById(id: $id) {
      ${APPLICANT_STEP_FRAGMENT}
    }
  }
`;

export const GET_SPOUSE_INFO = gql`
  query getApplicationById($id: String!) {
    getApplicationById(id: $id) {
      ${SPOUSE_STEP_FRAGMENT}
    }
  }
`;

export const GET_DEPENDENTS_INFO = gql`
  query getApplicationById($id: String!) {
    getApplicationById(id: $id) {
      ${DEPENDENTS_STEP_FRAGMENT}
    }
  }
`;

export const GET_INCOME_INFO = gql`
  query getApplicationById($id: String!) {
    getApplicationById(id: $id) {
      ${INCOME_STEP_FRAGMENT}
    }
  }
`;

export const GET_BANKING_INFO = gql`
  query getApplicationById($id: String!) {
    getApplicationById(id: $id) {
      ${BANKING_STEP_FRAGMENT}
      applicant {
        address {
          ${ADDRESS_FRAGMENT}
        }
      }
    }
  }
`;

export const FIND_ADDRESS = gql`
  query findAddress($searchTerm: String!) {
    findAddress(searchTerm: $searchTerm) {
      ${FIND_ADDRESS_RESULT_FRAGMENT}
    }
  }
`;

export const RETRIEVE_ADDRESS = gql`
  query retrieveAddress($id: String!) {
    retrieveAddress(id: $id) {
      ${RETRIEVE_ADDRESS_RESULT_FRAGMENT}
    }
  }
`;

export const CREATE_APPLICATION = gql`
  mutation createApplication($affApplicationInput: AffApplicationInput!) {
    createApplication(affApplicationInput: $affApplicationInput) {
      ${AFF_APPLICATION_FRAGMENT}
    }
  }
`;

export const UPDATE_APPLICATION = gql`
  mutation updateApplication($affApplicationInput: AffApplicationInput!) {
    updateApplication(affApplicationInput: $affApplicationInput) {
      ${AFF_APPLICATION_FRAGMENT}
    }
  }
`;

export const UPDATE_BANKING = gql`
  mutation updateBankingInfo($affApplicationInput: AffApplicationInput!) {
    updateBankingInfo(affApplicationInput: $affApplicationInput) {
      ${AFF_APPLICATION_FRAGMENT}
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation updateAddressInfo($affApplicationInput: AffApplicationInput!) {
    updateAddressInfo(affApplicationInput: $affApplicationInput) {
      ${AFF_APPLICATION_FRAGMENT}
    }
  }
`;

export const ADD_DEPENDENT = gql`
  mutation addDependent($affApplicationInput: AffApplicationInput!) {
    addDependent(affApplicationInput: $affApplicationInput) {
      ${AFF_APPLICATION_FRAGMENT}
    }
  }
`;

export const OVERRIDE_INCOME_ELIGIBILITY = gql`
  mutation overrideIncomeEligibility($incomeEligibilityOverrideInput: IncomeEligibilityOverrideInput!) {
    overrideIncomeEligibility(incomeEligibilityOverrideInput: $incomeEligibilityOverrideInput)
  }
`;

export const FIND_VERIFIED_APPLICATON = gql`
  query findVerifiedApplications($findVerifiedApplicationsInput: FindVerifiedApplicationsInput!) {
    findVerifiedApplications(findVerifiedApplicationsInput: $findVerifiedApplicationsInput) {
      _id
      applicationCode
      address {
        ${ADDRESS_FRAGMENT}
      }
      applicant {
        ${TOMBSTONE_FRAGMENT}
      }
    }
  }
`;

export class DefaultGraphqlService extends GraphqlService {
  constructor(private apollo: Apollo, router: Router, redirectOnDuplicates: boolean) {
    super(router, redirectOnDuplicates);
  }

  findVerifiedApplications(findVerifiedApplicationsInput: FindVerifiedApplicationsInput) {
    const gqlQuery = FIND_VERIFIED_APPLICATON;
    return this.apollo
      .watchQuery<{ findVerifiedApplications: Array<FindVerifiedApplicationResult> }>({
        query: gqlQuery,
        variables: {
          findVerifiedApplicationsInput: findVerifiedApplicationsInput,
        },
        fetchPolicy: 'cache-and-network',
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return data?.findVerifiedApplications as Array<FindVerifiedApplicationResult>;
        })
      )
      .pipe(share());
  }

  getApplicationById(applicationId: string) {
    const gqlQuery = GET_APPLICATION_BY_ID;

    return this.apollo
      .watchQuery<{ getApplicationById: AffApplication }>({
        query: gqlQuery,
        variables: {
          id: applicationId,
          errorMessage: `There was a problem retrieving the application.`,
        },
        fetchPolicy: 'cache-and-network',
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return Utilities.removeTypeNameFromObject(data?.getApplicationById) as AffApplication;
        })
      )
      .pipe(share());
  }

  getApplicationByIdFailSafe(applicationId: string) {
    const gqlQuery = GET_APPLICATION_BY_ID;

    return this.apollo
      .watchQuery<{ getApplicationById: AffApplication }>({
        query: gqlQuery,
        variables: {
          id: applicationId,
          errorMessage: `hide_error`,
        },
        fetchPolicy: 'network-only',
      })
      .valueChanges.pipe(
        map(({ data }) => {
          return Utilities.removeTypeNameFromObject(data?.getApplicationById) as AffApplication;
        })
      )
      .pipe(share());
  }

  getProcessedApplicationById(applicationId: string) {
    const gqlQuery = GET_PROCESSED_APPLICATION_BY_ID;

    return this.apollo
      .query<{ getProcessedApplicationById: ProcessedApplication }>({
        query: gqlQuery,
        variables: {
          id: applicationId,
          errorMessage: `hide_error`,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map(({ data }) => {
          return Utilities.removeTypeNameFromObject(data?.getProcessedApplicationById) as ProcessedApplication;
        })
      )
      .pipe(share());
  }

  getVerifiedApplicationById(applicationId: string) {
    const gqlQuery = GET_VERIFIED_APPLICATION_BY_ID;

    return this.apollo
      .query<{ getProcessedApplicationById: AffApplication }>({
        query: gqlQuery,
        variables: {
          id: applicationId,
          errorMessage: `hide_error`,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map(({ data }) => {
          return Utilities.removeTypeNameFromObject(data?.getProcessedApplicationById) as AffApplication;
        })
      )
      .pipe(share());
  }

  getStepperApplicationInfo(applicationId: string) {
    const gqlQuery = GET_STEPPER_APPLICATION_INFO;

    return this.apollo
      .watchQuery<{ getApplicationById: AffApplication }>({
        query: gqlQuery,
        variables: {
          id: applicationId,
          errorMessage: `There was a problem retrieving the application.`,
        },
        fetchPolicy: 'cache-and-network',
      })
      .valueChanges.pipe(this.mapOutputForApplication((d) => d?.getApplicationById))
      .pipe(share());
  }

  getStepInfo(applicationId: string, step: StepperStep = null) {
    let gqlQuery = GET_APPLICATION_BY_ID;
    switch (step?.code) {
      case StepperSteps.MySituation.code:
        gqlQuery = GET_MY_SITUATION;
        break;
      case StepperSteps.ProofOfIdentity.code:
        gqlQuery = GET_PROOF_OF_IDENTITY;
        break;
      case StepperSteps.PersonalInfo.code:
        gqlQuery = GET_PERSONAL_INFO;
        break;
      case StepperSteps.SpousePartner.code:
        gqlQuery = GET_SPOUSE_INFO;
        break;
      case StepperSteps.Dependents.code:
        gqlQuery = GET_DEPENDENTS_INFO;

        break;
      // case StepperSteps.Income.code:
      //   gqlQuery = GET_INCOME_INFO;
      //   break;
      case StepperSteps.BankingInfo.code:
        gqlQuery = GET_BANKING_INFO;
        break;
      case StepperSteps.Review.code:
        gqlQuery = GET_APPLICATION_BY_ID;
        break;
      default:
        gqlQuery = GET_APPLICATION_BY_ID;
        break;
    }

    return this.apollo
      .query<{ getApplicationById: AffApplication }>({
        query: gqlQuery,
        variables: {
          id: applicationId,
          errorMessage: `There was a problem retrieving the application.`,
        },
      })
      .pipe(this.mapOutputForApplication((d) => d?.getApplicationById))
      .pipe(share());
  }

  createApplication(affApplication: AffApplication) {
    Utilities.removeAuditFieldsFromEntity(affApplication);

    return this.apollo
      .mutate<{ createApplication: AffApplication }>({
        mutation: CREATE_APPLICATION,
        variables: {
          affApplicationInput: affApplication,
          errorMessage: `There was a problem saving the application`,
        },
      })
      .pipe(
        map(({ data }) => {
          return data.createApplication;
        })
      );
  }

  updateBankingInfo(affApplication: AffApplication) {
    console.log('updating banking info');
    Utilities.removeAuditFieldsFromEntity(affApplication);

    return this.apollo
      .mutate<{ updateBankingInfo: AffApplication }>({
        mutation: UPDATE_BANKING,
        variables: {
          affApplicationInput: affApplication,
          errorMessage: `There was a problem saving the banking info`,
        },
      })
      .pipe(
        map(({ data }) => {
          return data.updateBankingInfo;
        })
      );
  }

  updateAddressInfo(affApplication: AffApplication) {
    console.log('updating address info');
    Utilities.removeAuditFieldsFromEntity(affApplication);

    return this.apollo
      .mutate<{ updateAddressInfo: AffApplication }>({
        mutation: UPDATE_ADDRESS,
        variables: {
          affApplicationInput: affApplication,
          errorMessage: `There was a problem saving the address`,
        },
      })
      .pipe(
        map(({ data }) => {
          return data.updateAddressInfo;
        })
      );
  }

  addDependent(affApplication: AffApplication) {
    console.log('adding dependent');
    return this.apollo
      .mutate<{ addDependent: AffApplication }>({
        mutation: ADD_DEPENDENT,
        variables: {
          affApplicationInput: affApplication,
          errorMessage: `There was a problem saving the application`,
        },
      })
      .pipe(
        map(({ data }) => {
          return data.addDependent;
        })
      );
  }

  saveApplication(affApplication: AffApplication) {
    Utilities.removeAuditFieldsFromEntity(affApplication);

    return this.apollo
      .mutate<{ updateApplication: AffApplication }>({
        mutation: UPDATE_APPLICATION,
        variables: {
          affApplicationInput: affApplication,
          errorMessage: `There was a problem saving the application`,
        },
      })
      .pipe(this.mapOutputForApplication((d) => d.updateApplication, false));
  }

  overrideIncomeEligibility(incomeEligibilityOverride: IncomeEligibilityOverride) {
    return this.apollo
      .mutate<{ overrideIncomeEligibility: boolean }>({
        mutation: OVERRIDE_INCOME_ELIGIBILITY,
        variables: {
          incomeEligibilityOverrideInput: incomeEligibilityOverride,
          errorMessage: `There was a problem overriding income eligibility`,
        },
      })
      .pipe(
        map(({ data }) => {
          return data.overrideIncomeEligibility;
        })
      );
  }

  findAddress(searchTerm: string) {
    const gqlQuery = FIND_ADDRESS;
    const regex = /[#$%^&*()[\]]/g;
    const newStr = searchTerm.replace(regex, '');

    return this.apollo
      .query<{ findAddress: FindAddressResult[] }>({
        query: gqlQuery,
        variables: {
          searchTerm: newStr,
          errorMessage: `There was a problem finding addresses.`,
        },
      })
      .pipe(
        map(({ data }) => {
          return data.findAddress;
        })
      )
      .pipe(share());
  }

  retrieveAddress(id: string) {
    const gqlQuery = RETRIEVE_ADDRESS;

    return this.apollo
      .query<{ retrieveAddress: RetrieveAddressResult }>({
        query: gqlQuery,
        variables: {
          id: id,
          errorMessage: `There was a problem retrieving the address.`,
        },
      })
      .pipe(
        map(({ data }) => {
          return data.retrieveAddress;
        })
      )
      .pipe(share());
  }
}
