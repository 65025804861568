import { RouteInfo } from '../interfaces/route-info.interface';

export class AffApplyRoutes {
  public static readonly Login: RouteInfo = { baseUrl: 'login', fullUrl: 'login', name: 'Login' };
  public static readonly NotAuthorized: RouteInfo = {
    baseUrl: 'not-authorized',
    fullUrl: 'not-authorized',
    name: 'Not Authorized',
  };
  public static readonly Welcome: RouteInfo = {
    baseUrl: 'welcome',
    fullUrl: 'welcome',
    name: 'Welcome',
  };
  public static readonly UserDashboard: RouteInfo = {
    baseUrl: 'dashboard',
    fullUrl: 'dashboard',
    name: 'Dashboard',
  };
  public static readonly UpgradeAccount: RouteInfo = {
    baseUrl: 'upgrade',
    fullUrl: 'upgrade',
    name: 'Upgrade',
  };
  public static readonly GoaClerkLanding: RouteInfo = {
    baseUrl: 'goa-clerk/sign-in',
    fullUrl: 'goa-clerk/sign-in',
    name: 'GoA Clerk Sign In',
  };
  public static readonly GoaResClerkLanding: RouteInfo = {
    baseUrl: 'goa-resolution-clerk/sign-in',
    fullUrl: 'goa-resolution-clerk/sign-in',
    name: 'GoA Resolution Clerk Sign In',
  };
  public static readonly RoadsClerkLanding: RouteInfo = {
    baseUrl: 'clerk-support/signed-out',
    fullUrl: 'clerk-support/signed-out',
    name: 'Signed Out',
  };
  public static readonly ClerkAppPrescreen: RouteInfo = {
    baseUrl: 'clerk-support/prescreen',
    fullUrl: 'clerk-support/prescreen',
    name: 'Clerk Application Prescreen',
  };
  public static readonly GoaAppPrescreen: RouteInfo = {
    baseUrl: 'goa-clerk/prescreen',
    fullUrl: 'goa-clerk/prescreen',
    name: 'GoA Application Prescreen',
  };
  public static readonly Prescreen: RouteInfo = {
    baseUrl: 'prescreen',
    fullUrl: 'prescreen',
    name: 'Prescreen',
  };
  public static readonly Knockout: RouteInfo = {
    baseUrl: 'knockout',
    fullUrl: 'knockout',
    name: 'Ineligible',
  };
  public static readonly AlreadyEnrolled: RouteInfo = {
    baseUrl: 'enrolled',
    fullUrl: 'enrolled',
    name: 'Enrolled',
  };
  public static readonly Eligible: RouteInfo = {
    baseUrl: 'eligible',
    fullUrl: 'eligible',
    name: 'Eligible',
  };
  public static readonly Upgrade: RouteInfo = {
    baseUrl: 'prescreen/upgrade',
    fullUrl: 'prescreen/upgrade',
    name: 'Upgrade',
  };
  public static readonly Apply: RouteInfo = {
    baseUrl: 'apply',
    fullUrl: 'apply',
    name: 'Apply',
  };
  public static readonly ClerkApply: RouteInfo = {
    baseUrl: 'clerk-support/apply',
    fullUrl: 'clerk-support/apply',
    name: 'Clerk Apply',
  };
  public static readonly GoaApply: RouteInfo = {
    baseUrl: 'goa-clerk/apply',
    fullUrl: 'goa-clerk/apply',
    name: 'GoA Apply',
  };
  public static readonly MyAppStatus: RouteInfo = {
    baseUrl: 'my-application-status',
    fullUrl: 'my-application-status',
    name: 'My application status',
  };

  public static readonly ServiceError: RouteInfo = {
    baseUrl: 'service-error',
    fullUrl: 'service-error',
    name: 'Service Error',
  };

  public static readonly ApplicationError: RouteInfo = {
    baseUrl: 'application-error',
    fullUrl: 'application-error',
    name: 'Application Error',
  };

  public static readonly MySituation: RouteInfo = {
    baseUrl: 'my-situation',
    fullUrl: 'my-situation',
    name: 'My situation',
  };

  public static readonly ProofOfIdentity: RouteInfo = {
    baseUrl: 'proof-of-identity',
    fullUrl: 'proof-of-identity',
    name: 'Identity and Residency',
  };

  public static readonly PersonalInfo: RouteInfo = {
    baseUrl: 'personal-info',
    fullUrl: 'personal-info',
    name: 'Personal information',
  };
  public static readonly Spouse: RouteInfo = {
    baseUrl: 'spouse-partner',
    fullUrl: 'spouse-partner',
    name: 'Spouse / Partner',
  };
  public static readonly Dependents: RouteInfo = {
    baseUrl: 'dependents',
    fullUrl: 'dependents',
    name: 'Dependants',
  };
  public static readonly Income: RouteInfo = {
    baseUrl: 'income',
    fullUrl: 'income',
    name: 'Income',
  };
  public static readonly BankingInfo: RouteInfo = {
    baseUrl: 'banking-info',
    fullUrl: 'banking-info',
    name: 'Banking information',
  };
  public static readonly Review: RouteInfo = {
    baseUrl: 'review',
    fullUrl: 'review',
    name: 'Review',
  };
  public static readonly ClerkSupport: RouteInfo = {
    baseUrl: 'clerk-support',
    fullUrl: 'clerk-support',
    name: 'Clerk Support',
  };

  public static readonly GoaSupport: RouteInfo = {
    baseUrl: 'goa-clerk',
    fullUrl: 'goa-clerk',
    name: 'GoA Clerk Support',
  };

  public static readonly GoaResClerk: RouteInfo = {
    baseUrl: 'goa-resolution-clerk',
    fullUrl: 'goa-resolution-clerk',
    name: 'GoA Resolution Clerk',
  };

  public static readonly OverrideIncomeEligibility: RouteInfo = {
    baseUrl: 'override-income-eligibility',
    fullUrl: 'override-income-eligibility',
    name: 'Override Income Eligibility',
  };

  public static readonly Closed: RouteInfo = {
    baseUrl: 'closed',
    fullUrl: 'closed',
    name: 'Closed',
  };

  public static readonly ALL = [
    AffApplyRoutes.Login,
    AffApplyRoutes.NotAuthorized,
    AffApplyRoutes.Apply,
    AffApplyRoutes.ClerkApply,
    AffApplyRoutes.GoaApply,
    AffApplyRoutes.Prescreen,
    AffApplyRoutes.ServiceError,
    AffApplyRoutes.ApplicationError,
    AffApplyRoutes.MySituation,
    AffApplyRoutes.ProofOfIdentity,
    AffApplyRoutes.PersonalInfo,
    AffApplyRoutes.Spouse,
    AffApplyRoutes.Dependents,
    AffApplyRoutes.Income,
    AffApplyRoutes.BankingInfo,
    AffApplyRoutes.Review,
    AffApplyRoutes.MyAppStatus,
    AffApplyRoutes.ClerkSupport,
    AffApplyRoutes.GoaSupport,
    AffApplyRoutes.ClerkAppPrescreen,
    AffApplyRoutes.GoaAppPrescreen,
    AffApplyRoutes.GoaResClerk,
    AffApplyRoutes.GoaResClerkLanding,
    AffApplyRoutes.OverrideIncomeEligibility,
    AffApplyRoutes.Closed,
  ];
}
