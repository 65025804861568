import { Expose } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';
import { PostalCodeFormat } from '../custom-validation-functions/postal-code-format';

export class Address {
  @Expose()
  @IsNotEmpty()
  streetAddress: string;

  @Expose()
  suiteNumber?: string;

  @Expose()
  @IsNotEmpty()
  city: string;

  @Expose()
  @IsNotEmpty()
  province: string;

  @Expose()
  @IsNotEmpty()
  @PostalCodeFormat()
  postalCode: string;
}
