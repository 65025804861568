export const TrainingDataConstants = {
  auditDate: '2023-02-01T14:48:00.000Z',
  submitterType: 'GoAClerk',
  auditBy: {
    _id: 'diotest1.t@gov.ab.ca',
    name: 'DIO Test',
    email: 'diotest1.t@gov.ab.ca',
    primaryRole: null,
  },
};
