import { Adjudication } from './adjudication.entity';
import { AffApplication } from './aff-application.entity';
import { ApplicationPayments } from './application-payments.entity';
import { ResolutionAdjustmentLog } from './resolution-adjustment-log.entity';

export class ProcessedApplication extends AffApplication {
  adjudication?: Adjudication;
  isProcessed?: boolean;
  processedAt?: string;
  actualPayments?: ApplicationPayments;
  lastResolutionAdjustment?: ResolutionAdjustmentLog;
  applicationSubmitterSource?: string;
}
