import { AngularComponentsModule } from '@abgov/angular-components';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@common/ui/material';
import {
  ContextProvider,
  PendingChangesGuard,
  RouteHistoryService,
  UiSharedComponentsModule,
} from '@common/ui/shared-components';
import { NgxMaskModule } from 'ngx-mask';

import { ApplicationComponent } from './components/application/application.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { AffApplyRoutingModule } from './aff-apply-routing.module';
import { ApplicationPageComponent } from './pages/application-page/application-page.component';
import { NotAuthorizedPageComponent } from './pages/authentication/not-authorized-page/not-authorized-page.component';
import { LandingComponent } from './features/landing/components/landing/landing.component';
import { MaintenanceModeModule } from '@common/ui/maintenance-mode';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { GoaClerkLandingComponent } from './features/landing/components/goa-clerk-landing/goa-clerk-landing.component';
import { RoadsClerkLandingComponent } from './features/landing/components/roads-clerk-landing/roads-clerk-landing.component';
import { GraphqlFactory } from './shared/graphql.factory';
import { ResolutionClerkLandingComponent } from './features/landing/components/resolution-clerk-landing/resolution-clerk-landing.component';
import { ApplicationErrorPageComponent } from './pages/application-error-page/application-error-page.component';

import { UpgradeAccountComponent } from './features/landing/components/upgrade/upgrade.component';
import { UserDashboardComponent } from './features/user-dashboard/components/user-dashboard/user-dashboard.component';
import { UserDashboardPageComponent } from './features/user-dashboard/pages/user-dashboard-page/user-dashboard-page.component';

@NgModule({
  imports: [
    AngularComponentsModule,
    UiSharedComponentsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    NgxMaskModule.forRoot(),
    AffApplyRoutingModule,
    MaintenanceModeModule,
  ],
  declarations: [
    ApplicationPageComponent,
    ApplicationComponent,
    NotAuthorizedPageComponent,
    MyAccountComponent,
    LandingComponent,
    NotFoundComponent,
    GoaClerkLandingComponent,
    ResolutionClerkLandingComponent,
    RoadsClerkLandingComponent,
    ApplicationErrorPageComponent,

    UpgradeAccountComponent,
    UserDashboardComponent,
    UserDashboardPageComponent,
  ],
  providers: [PendingChangesGuard, ContextProvider, RouteHistoryService, GraphqlFactory.getProvider()],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AffApplyModule {}
