import { BankingInfo } from './banking-info.entity';

export class PaymentLedger {
  personId: string;
  paymentMonth: string; //"2023-01-01"
  personType: string; // should be PersonTypes
  paymentSource: {
    name: string;
  };
  paymentStatus?: string;
  bankingInfo?: BankingInfo;
  failedMessage?: string;
  holdReason?: string;
  revertToCheque?: boolean;
  revertToChequeReason?: string;
  amount: number;
  sourceId: string;
  //when we return the info from the ledger db to the front,
  //if applicationId doesn't match, we know the person is "used" in another application
  applicationId?: string;
}
