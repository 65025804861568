<div class="flex-row middle top-controls">
  <div class="today-button flex-row middle" *ngIf="calendarView !== CalendarView.List">
    <button goa-button buttonType="tertiary" buttonSize="small" (click)="goToToday()">Today</button>
  </div>
  <div class="current-month" [formGroup]="_formGroup" *ngIf="calendarView !== CalendarView.List">
    <!--button *ngIf="!_editDate" (click)="_editDate = true" goa-button buttonType="tertiary" buttonSize="small">
      <span class="material-icons-outlined calendar-icon"> date_range </span>
      <span class="date-label">{{ activeDay | date: 'MMMM, yyyy' }}</span>
    </button-->
    <div class="date-picker" *ngIf="calendarView !== CalendarView.List">
      <common-calendar formControlName="activeDay" data-cy="calendar-active-date"></common-calendar>
    </div>
  </div>
  <div class="month-navigator flex-row middle" *ngIf="calendarView !== CalendarView.List">
    <div class="month-change-icon" iesIconButton (click)="previousMonthClick()">
      <span class="material-icons"> chevron_left </span>
    </div>
    <div class="month-change-icon" iesIconButton (click)="nextMonthClick()">
      <span class="material-icons"> chevron_right </span>
    </div>
  </div>
  <div *ngIf="!hideLegend" class="legend">
    <div class="item" *ngFor="let category of _eventCategories">
      <span
        class="color-indicator"
        [ngStyle]="{ backgroundColor: category.cssEventColor, color: category.cssFontColor }"
      ></span>
      <span>{{ category.name }}</span>
    </div>
  </div>
  <div class="flex-grow"></div>
  <div class="loading-indicator">
    <mat-progress-spinner mode="indeterminate" diameter="25" *ngIf="isLoading"> </mat-progress-spinner>
  </div>
  <div class="day-week-month">
    <mat-button-toggle-group>
      <mat-button-toggle [checked]="calendarView === CalendarView.Month" (click)="setCalendarView(CalendarView.Month)">
        Month
      </mat-button-toggle>
      <mat-button-toggle [checked]="calendarView === CalendarView.Week" (click)="setCalendarView(CalendarView.Week)">
        Week
      </mat-button-toggle>
      <mat-button-toggle [checked]="calendarView === CalendarView.Day" (click)="setCalendarView(CalendarView.Day)">
        Day
      </mat-button-toggle>
      <mat-button-toggle [checked]="calendarView === CalendarView.List" (click)="setCalendarView(CalendarView.List)">
        List
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="filters-button" *ngIf="!hideFilters">
    <button
      goa-button
      buttonType="secondary"
      buttonSize="small"
      [attr.aria-label]="_showFilters ? 'Hide filters' : 'Show filters'"
      (click)="_showFilters = !_showFilters"
      data-cy="calendar-toggle-filters-button"
    >
      <span *ngIf="!_showFilters" class="material-icons-outlined filter-icon"> filter_list </span>
      <span *ngIf="_showFilters" class="material-icons-outlined filter-icon"> filter_list_off </span>
      <span class="filter-label">{{ _showFilters ? 'Hide filters' : 'Filters' }}</span>
    </button>
  </div>
  <div class="add-button">
    <button
      [disabled]="!allowEventsInPast && isInPast(activeDay)"
      goa-button
      buttonType="primary"
      buttonSize="small"
      (click)="addEventClick()"
      data-cy="add-reminder-button"
    >
      + Add
    </button>
  </div>
</div>
<div class="flex-row controls" *ngIf="_showFilters && !hideFilters">
  <div class="filters flex-grow">
    <ng-content select="[filters]"></ng-content>
  </div>
</div>
<div class="filter-chips" [ngClass]="{ controls: !_showFilters }" *ngIf="!hideFilters">
  <ng-content select="[filter-chips]"></ng-content>
</div>
<div [ngSwitch]="calendarView" class="calendar">
  <div class="month-view" *ngSwitchCase="CalendarView.Month">
    <mwl-calendar-month-view
      [viewDate]="activeDay"
      [events]="_events"
      [activeDayIsOpen]="true"
      [cellTemplate]="customCellTemplate"
      [openDayEventsTemplate]="openDayTemplate"
      (dayClicked)="onDayClicked($event.day.date)"
    ></mwl-calendar-month-view>
  </div>
  <div class="week-view" *ngSwitchCase="CalendarView.Week">
    <common-worker-calendar-week-view
      [events]="_events"
      [isLoading]="isLoading"
      [viewDate]="activeDay"
      [eventTemplate]="eventTemplate"
      (dayClicked)="onDayClicked($event)"
      [hasMore]="hasMore"
    >
    </common-worker-calendar-week-view>
  </div>
  <div class="day-view" *ngSwitchCase="CalendarView.Day">
    <common-worker-calendar-day-view
      [isLoading]="isLoading"
      [events]="_events"
      [viewDate]="activeDay"
      [eventTemplate]="eventTemplate"
    >
    </common-worker-calendar-day-view>
  </div>
  <div class="list-view" *ngSwitchCase="CalendarView.List">
    <common-worker-calendar-list-view
      [isLoading]="isLoading"
      [events]="_events"
      [eventTemplate]="eventTemplate"
      [hasMore]="hasMore"
    >
    </common-worker-calendar-list-view>
  </div>
</div>
<ng-template #customCellTemplate let-day="day" let-locale="locale">
  <div class="day" [ngClass]="{ active: isDayActive(day), past: day.isPast }">
    <ng-container *ngFor="let event of day.events; let index = index">
      <div
        [matTooltip]="event.title"
        matTooltipShowDelay="750"
        class="event-container"
        [ngClass]="{ completed: event.meta.isComplete, important: event.meta.isImportant }"
        *ngIf="index < EVENTS_PER_DAY"
      >
        <div
          class="event"
          [ngClass]="{ completed: event.meta.isComplete }"
          [ngStyle]="{
            backgroundColor: event.meta.category.cssBackgroundColor,
            borderColor: event.meta.category.cssEventColor,
            color: event.meta.category.cssFontColor
          }"
        >
          <span>{{ event.title }}</span>
        </div>
        <span
          class="status-icon"
          *ngIf="event.meta.isComplete"
          [ngStyle]="{
            color: event.meta.category.cssEventColor
          }"
          ><span class="material-icons filled"> check_circle </span>
        </span>
        <span
          [ngStyle]="{
            color: event.meta.category.cssEventColor
          }"
          class="status-icon"
          *ngIf="event.meta.isImportant && !event.meta.isComplete"
          ><span class="material-icons filled"> flag </span></span
        >
      </div>
    </ng-container>
    <div class="day-bottom" [ngClass]="{ faded: !day.inMonth, today: day.isToday }">
      <div class="more-events" *ngIf="day.events.length <= EVENTS_PER_DAY"></div>
      <div class="more-events event-container" *ngIf="day.events.length > EVENTS_PER_DAY">
        <div class="event">+ {{ day.events.length - EVENTS_PER_DAY }} more</div>
      </div>
      <span class="number"
        ><span>{{ day.date | calendarDate: 'monthViewDayNumber':locale }}</span></span
      >
    </div>
  </div>
</ng-template>
<ng-template #openDayTemplate let-events="events" let-isOpen="isOpen">
  <div class="open-day" *ngIf="isOpen && events.length > 0">
    <div class="events-list">
      <ng-container *ngFor="let event of events">
        <ng-container *ngTemplateOutlet="eventTemplate; context: { event: event }"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #eventTemplate let-event="event">
  <div class="event-container" [ngClass]="{ completed: event.meta.isComplete, important: event.meta.isImportant }">
    <span
      class="status-icon"
      *ngIf="event.meta.isComplete"
      [ngStyle]="{
        color: event.meta.category.cssEventColor
      }"
      ><span class="material-icons filled"> check_circle </span>
    </span>
    <span
      [ngStyle]="{
        color: event.meta.category.cssEventColor
      }"
      class="status-icon"
      *ngIf="event.meta.isImportant && !event.meta.isComplete"
      ><span class="material-icons filled"> flag </span></span
    >
    <div
      class="event"
      data-cy="calendar-event"
      [ngStyle]="{
        backgroundColor: event.meta.category.cssBackgroundColor,
        borderColor: event.meta.category.cssEventColor,
        color: event.meta.category.cssFontColor
      }"
    >
      <ng-container *ngIf="!event.meta.eventTemplate">
        <div class="default">
          <div class="title">
            {{ event.title }}
          </div>
          <div class="actions">
            <button
              goa-button
              buttonType="tertiary"
              buttonSize="small"
              *ngFor="let action of event.meta.actions"
              (click)="$event.stopPropagation(); action.callback(event.meta)"
            >
              {{ action.name }}
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="event.meta.eventTemplate">
        <ng-container
          [ngTemplateOutlet]="event.meta.eventTemplate"
          [ngTemplateOutletContext]="{ $implicit: event.meta }"
        ></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>
