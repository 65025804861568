import { HealthCheckService } from '@aff-apply/ui';
import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';

//import { HealthCheckService, IesGraphqlService } from '@aff-apply/ui/services';
@Component({
  selector: 'aff-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  loading = false;

  originalPageTitleKey = 'originalPageTitle';

  constructor(private router: Router, private healthCheckService: HealthCheckService, private titleService: Title) {}

  async ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      }
      if (event instanceof NavigationEnd) {
        this.loading = false;
      }
    });

    /*
    return await this.healthCheckService
      .isServicesAlive()
      .then((res) => {
        if (!res) {
          this.router.navigate(['service-error']);
        }
      })
      .catch(() => {
        this.router.navigate(['service-error']);
      });
    */
  }

  @HostListener('window:beforeprint', ['$event'])
  onBeforePrint() {
    // beforeprint event fires twice and anything I tried to set on the component didn't have the updated value the second time
    // so I wasn't able to capture the page title and just append Protected B on it and then set it back to the previous value in afterprint
    // hence the crazy overcomplicated localStorage solution so that page titles can be restored after print has finished
    const protectedBVal = ' - Protected B';
    let pageTitle = this.titleService.getTitle();
    if (pageTitle.indexOf(protectedBVal) === -1) {
      localStorage.setItem(this.originalPageTitleKey, pageTitle);
    } else {
      pageTitle = localStorage.getItem(this.originalPageTitleKey);
    }

    this.titleService.setTitle(pageTitle + protectedBVal);
  }

  @HostListener('window:afterprint', ['$event'])
  onAfterPrint() {
    const pageTitle = localStorage.getItem(this.originalPageTitleKey);
    this.titleService.setTitle(pageTitle);
  }

  //handles links generated dynamically
  @HostListener('document:click', ['$event'])
  public handleClick(event: Event): void {
    if (event.target instanceof HTMLAnchorElement) {
      const element = event.target as HTMLAnchorElement;
      if (element.className === 'routerlink') {
        event.preventDefault();
        const route = element?.getAttribute('href');
        if (route) {
          this.router.navigate([`/${route}`]);
        }
      }
    }
  }
}
