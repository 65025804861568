import { AffApplication, AffApplyRoutes } from '@aff-apply/entities';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@common/entities';
import { AppConfigService } from '@common/ui/shared-components';

@Component({
  selector: 'aff-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  hasSunset = false;

  constructor(protected readonly router: Router, private configService: AppConfigService) {
    this.hasSunset = this.configService.getFeatures()?.hasSunset;
  }

  ngOnInit() {
    const redirect = window?.sessionStorage?.getItem('redirect');
    if (redirect) {
      const val = JSON.parse(redirect);
      window.sessionStorage.removeItem('redirect');

      if (val?.expires > new Date().getTime() && val?.value) {
        if (val.value.indexOf('?') > -1) {
          window.location.href = window.location.origin + val.value;
        } else {
          this.router.navigate([val.value]);
        }
      }
    }
  }
}
