import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceModeBannerComponent } from './maintenance-mode-banner/maintenance-mode-banner.component';
import { UiSharedComponentsModule } from '@common/ui/shared-components';
import { AngularComponentsModule } from '@abgov/angular-components';


@NgModule({
  declarations: [
    MaintenanceModeBannerComponent,
    
  ],
  imports: [
    CommonModule,
    UiSharedComponentsModule,
    AngularComponentsModule,
  ],
  exports: [
    MaintenanceModeBannerComponent,
  ]
})
export class MaintenanceModeModule { }
