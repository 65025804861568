import { Expose } from 'class-transformer';
import { IsDefined, IsNumberString, Length } from 'class-validator';
import { IsKnownBank } from '../constraints/bank.validator';
import { IsKnownBranch } from '../constraints/branch.validator';

export class DirectDeposit {
  @Expose()
  @IsDefined()
  @IsNumberString()
  @Length(3, 3)
  @IsKnownBank()
  institutionNumber: string; //3 digit number, can have leading 0's

  @Expose()
  @IsDefined()
  @IsNumberString()
  @Length(5, 5)
  @IsKnownBranch()
  transitNumber: string; //5 digit number, can have leading 0's

  @Expose()
  @IsDefined()
  @IsNumberString()
  @Length(2, 12)
  accountNumber: string; //7-12 digit number, can have leading 0's
}
