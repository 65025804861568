import { Pipe, PipeTransform } from '@angular/core';

export interface IntakeLabel {
  label: string;
  spousePartnerLabel?: string;
  replacementLabel?: string;
}

@Pipe({ name: 'labelText' })
export class LabelTextPipe implements PipeTransform {
  transform(value: IntakeLabel, replacementText?: string): string {
    const spouseLabelPlaceholder = '<<spouseLabel>>';
    if (replacementText && value.spousePartnerLabel && value.spousePartnerLabel.indexOf(spouseLabelPlaceholder) > 0) {
      return value.spousePartnerLabel.replace(spouseLabelPlaceholder, replacementText.toLowerCase());
    }
    const replacementLabel = '<<replacementLabel>>';
    if (replacementText && value.replacementLabel && value.replacementLabel.indexOf(replacementLabel) > 0) {
      //need to do a replaceAll
      const target = value.replacementLabel;
      const displayLabel = target.split(replacementLabel).join(replacementText);
      return displayLabel;
      //return value.replacementLabel.replace(replacementLabel, replacementText?.trimStart().trimEnd());
    }

    return value.label;
  }
}
