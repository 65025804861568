import { StepperStep } from '../interfaces/stepper-step.interface';
import { AffApplyRoutes } from './aff-apply-routes';

export class StepperSteps {
  static readonly MySituation: StepperStep = {
    code: 'mySituation',
    name: AffApplyRoutes.MySituation.name,
    url: AffApplyRoutes.MySituation.baseUrl,
    state: 'notVisited',
    hasError: false,
    active: true,
  } as StepperStep;

  static readonly ProofOfIdentity: StepperStep = {
    code: 'proofOfIdentity',
    name: AffApplyRoutes.ProofOfIdentity.name,
    url: AffApplyRoutes.ProofOfIdentity.baseUrl,
    state: 'notVisited',
    hasError: false,
    active: true,
  } as StepperStep;

  static readonly PersonalInfo = {
    code: 'personalInfo',
    name: AffApplyRoutes.PersonalInfo.name,
    url: AffApplyRoutes.PersonalInfo.baseUrl,
    state: 'notVisited',
    hasError: false,
    active: true,
  } as StepperStep;

  static readonly SpousePartner = {
    code: 'spousePartner',
    name: AffApplyRoutes.Spouse.name,
    url: AffApplyRoutes.Spouse.baseUrl,
    state: 'notVisited',
    hasError: false,
    active: true,
  } as StepperStep;

  static readonly Dependents = {
    code: 'dependents',
    name: AffApplyRoutes.Dependents.name,
    url: AffApplyRoutes.Dependents.baseUrl,
    state: 'notVisited',
    hasError: false,
    active: true,
  } as StepperStep;

  // static readonly Income = {
  //   code: 'income',
  //   name: AffApplyRoutes.Income.name,
  //   url: AffApplyRoutes.Income.baseUrl,
  //   state: 'notVisited',
  //   hasError: false,
  //   active: true,
  // } as StepperStep;

  static readonly BankingInfo = {
    code: 'bankingInfo',
    name: AffApplyRoutes.BankingInfo.name,
    url: AffApplyRoutes.BankingInfo.baseUrl,
    state: 'notVisited',
    hasError: false,
    active: true,
  } as StepperStep;

  static readonly Review = {
    code: 'review',
    name: AffApplyRoutes.Review.name,
    url: AffApplyRoutes.Review.baseUrl,
    state: 'notVisited',
    hasError: false,
    active: true,
  } as StepperStep;

  static readonly All = [
    StepperSteps.MySituation,
    StepperSteps.ProofOfIdentity,
    StepperSteps.PersonalInfo,
    StepperSteps.SpousePartner,
    StepperSteps.Dependents,
    StepperSteps.BankingInfo,
    StepperSteps.Review,
  ];

  static resetState() {
    for (const step of this.All) {
      step.state = 'notVisited';
      step.hasError = false;
      step.active = true;
    }
  }
}
