import { AppConfigService } from '@common/ui/shared-components';
import { Apollo } from 'apollo-angular';
import { GraphqlService } from './graphql.service';
import { TrainingGraphqlService } from './training-graphql.service';
import { Features } from '@common/constants';
import { ProcessedApplication } from '@aff-apply/entities';
import { DefaultGraphqlService } from './default-graphql.service';
import { Router } from '@angular/router';
function build(appConfig: AppConfigService, apollo: Apollo, route: Router) {
  const features = appConfig.getFeatures();
  const isTraining = features[Features.IsTraining] && features[Features.IsTraining] === true;
  const redirectOnDuplicates =
    features[Features.RedirectOnDuplicates] && features[Features.RedirectOnDuplicates] === true;

  const graphqlService = new DefaultGraphqlService(apollo, route, redirectOnDuplicates);

  if (!isTraining) {
    return graphqlService;
  }

  return new TrainingGraphqlService(
    graphqlService,
    features[Features.TrainingData] as unknown as Array<ProcessedApplication>,
    route,
    redirectOnDuplicates
  );
}

export class GraphqlFactory {
  static getProvider() {
    return {
      provide: GraphqlService,
      useFactory: build,
      deps: [AppConfigService, Apollo, Router],
    };
  }
}
