import { Expose } from 'class-transformer';
import { AffApplication } from './aff-application.entity';

export class ResolutionApplication extends AffApplication {
  @Expose()
  tickets: string[];

  @Expose()
  originalApplicantName: string;

  @Expose()
  originalApplicationId: string;

  @Expose()
  originalApplicationCode: string;
}
