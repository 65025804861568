interface SessionStorageKey {
  key: string;
  description: string;
}

export const SessionStorageKeys: { [name: string]: SessionStorageKey } = {
  AffApplyApplicationSessionId: {
    key: 'affApplyAppSessionId',
    description: 'Affodability Initiative Application Session ID issued to non authenticated users',
  },
  AffApplyApplicationPrescreenData: {
    key: 'affApplyAppPrescreenData',
    description: 'Affodability Initiative Prescreen data to store data to be used in the application',
  },
  AuthenticatedApplicationSessionId: {
    key: 'authAppSessionId',
    description: 'Affodability Initiative Application Session ID issued to authenticated users',
  },
};
