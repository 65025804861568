import { Expose } from 'class-transformer';
import { IsIn, IsNotEmpty, ValidateIf } from 'class-validator';
import { NameChangeReasons, allNameChangeReasons } from '@common/constants';

export class NameChangeReason {
  @Expose()
  @IsIn(allNameChangeReasons)
  reason: string;

  @Expose()
  @IsNotEmpty()
  @ValidateIf((o) => o.reason === NameChangeReasons.deceased)
  deceasedDate: string;

  @Expose()
  @IsNotEmpty()
  @ValidateIf((o) => o.reason === NameChangeReasons.deceased)
  estateName: string;
}
