<div class="aff-panel">
  <div
    class="aff-panel-header"
    [ngClass]="{ 'aff-panel-header-primary': isPrimary, 'aff-panel-header-secondary': !isPrimary }"
  >
    <ng-content select="[header]"></ng-content>
    <ng-content select="[header-alert]"></ng-content>
  </div>
  <div class="aff-panel-content">
    <div class="aff-panel-content-title">
      <ng-content select="[content-title]"></ng-content>
    </div>
    <ng-content select="[content]"></ng-content>
  </div>
  <div class="aff-panel-footer" [ngClass]="{ hidden: !hasFooter }">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
