<fieldset class="client-intake-fieldset-control">
  <legend class="label" [ngClass]="{ hidden: showLabel === false }">
    <span>{{ label }}</span>
    <common-info-icon *ngIf="container && info" [containerElement]="container" [messageElement]="info"></common-info-icon>
    <span class="optional-label" *ngIf="isOptional">(Optional)</span>
    <div class="helper-text" *ngIf="helperText">
      {{ helperText }}
    </div>
  </legend>
  <ng-content></ng-content>
  <div class="helper-text">
    {{ helperMessage }}
  </div>
</fieldset>
