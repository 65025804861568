<div class="list-view">
  <div class="overdue event-bucket">
    <h3>
      Overdue
      <span *ngIf="_allOverdueEvents && _allOverdueEvents.length > 0" class="pill">{{ _allOverdueEvents.length }}</span>
    </h3>

    <ng-container *ngIf="_allOverdueEvents && _allOverdueEvents.length > 0; else noOverdue">
      <ng-container *ngIf="_overdueEventsOther && _overdueEventsOther.length > 0">
        <h4>Over 30 days overdue</h4>
        <ng-container *ngFor="let event of _overdueEventsOther">
          <div class="event-container">
            <ng-container *ngTemplateOutlet="eventTemplate; context: { event: event }"> </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="_overdueEvents14to30 && _overdueEvents14to30.length > 0">
        <h4>15 to 30 days overdue</h4>
        <ng-container *ngFor="let event of _overdueEvents14to30">
          <div class="event-container">
            <ng-container *ngTemplateOutlet="eventTemplate; context: { event: event }"> </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="_overdueEvents7to14 && _overdueEvents7to14.length > 0">
        <h4>8 to 14 days overdue</h4>
        <ng-container *ngFor="let event of _overdueEvents7to14">
          <div class="event-container">
            <ng-container *ngTemplateOutlet="eventTemplate; context: { event: event }"> </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="_overdueEvents0to7 && _overdueEvents0to7.length > 0">
        <h4>0 to 7 days overdue</h4>
        <ng-container *ngFor="let event of _overdueEvents0to7">
          <div class="event-container">
            <ng-container *ngTemplateOutlet="eventTemplate; context: { event: event }"> </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #noOverdue>
      <ng-container *ngIf="!isLoading">
        <div class="event-container">{{ hasMore ? 'No events yet loaded.' : 'No events overdue.' }}</div>
      </ng-container>
      <mat-progress-spinner
        *ngIf="isLoading && !_allOverdueEvents?.length"
        class="spinner"
        [mode]="'indeterminate'"
        [diameter]="50"
      >
      </mat-progress-spinner>
    </ng-template>
  </div>
  <div class="upcoming event-bucket">
    <h3>
      Upcoming
      <span *ngIf="_allUpcomingEvents && _allUpcomingEvents.length > 0" class="pill">{{
        _allUpcomingEvents.length
      }}</span>
    </h3>
    <ng-container *ngIf="_allUpcomingEvents && _allUpcomingEvents.length > 0; else noUpcoming">
      <ng-container *ngIf="_upcomingEventsToday && _upcomingEventsToday.length > 0">
        <h4>Due today</h4>
        <ng-container *ngFor="let event of _upcomingEventsToday">
          <div class="event-container">
            <ng-container *ngTemplateOutlet="eventTemplate; context: { event: event }"> </ng-container>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="_upcomingEventsOther && _upcomingEventsOther.length > 0">
        <h4>Due after today</h4>
        <ng-container *ngFor="let event of _upcomingEventsOther">
          <div class="event-container">
            <ng-container *ngTemplateOutlet="eventTemplate; context: { event: event }"> </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #noUpcoming>
      <ng-container *ngIf="!isLoading">
        <div class="event-container">{{ hasMore ? 'No events yet loaded.' : 'No events upcoming.' }}</div>
      </ng-container>
      <mat-progress-spinner
        *ngIf="isLoading && !_allUpcomingEvents?.length"
        class="spinner"
        [mode]="'indeterminate'"
        [diameter]="50"
      >
      </mat-progress-spinner>
    </ng-template>
  </div>
</div>
