import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightSearch',
})
export class HighlightSearchPipe implements PipeTransform {
  transform(value: string, search: string): string {
    let valueStr = value + ''; // Ensure numeric values are converted to strings

    //highlight all the search terms that match
    const searchFields = search?.trim()?.split(' ');
    if (search && search.length > 0) {
      searchFields
        .map((s) => s.trim())
        .filter((s) => !!s)
        .forEach((search) => {
          valueStr = valueStr.replace(
            new RegExp('(?![^&;]+;)(?!<[^<>]*)(' + search.replace(/\\/g, '\\\\') + ')(?![^<>]*>)(?![^&;]+;)', 'gi'),
            '<strong>$1</strong>'
          );
        });
    }
    return valueStr;
  }
}
