<div class='not-authorized-container'>
  <div class="flex-row not-authorized-title">
    <div>Not Authorized</div>
  </div>

  <div class="flex-row">
    <div class="flex-column sad-face">
      <mat-icon>sentiment_dissatisfied</mat-icon>
    </div>
    <div class="flex-column">
      <div class="flex-row not-authorized-message">
        Sorry, you are not authorized to view this page.
      </div>
      <div class="flex-row">
        <div>Click <a href="javascript:history.back(-1);">here</a> to go back.</div>
      </div>
    </div>
  </div>
</div>