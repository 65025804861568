import { SinFormat, StringMax, StringMin } from '@common/validation';
import { Expose } from 'class-transformer';
import { IsDefined, IsNotEmpty, ValidateIf } from 'class-validator';
import * as dayjs from 'dayjs';
export class Spouse {
  @Expose()
  @ValidateIf((o) => o.isAuthorized)
  @IsNotEmpty()
  firstName: string;

  @Expose()
  @ValidateIf((o) => o.isAuthorized)
  @IsNotEmpty()
  lastName: string;

  @Expose()
  @ValidateIf((o) => o.isAuthorized)
  @StringMin('1900-01-01') //on or after 1900-01-01
  @StringMax(dayjs().format('YYYY-MM-DD'))
  @IsNotEmpty()
  birthdate: string;

  @Expose()
  @ValidateIf((o) => o.isAuthorized)
  @IsNotEmpty()
  @SinFormat()
  socialInsuranceNumber: string;

  @Expose()
  @ValidateIf((o) => o.socialInsuranceNumber?.startsWith('9'))
  @IsNotEmpty()
  @StringMin(dayjs().format('YYYY-MM-DD'))
  sinExpiryDate: string;

  @Expose()
  @IsDefined()
  isAuthorized?: boolean;

  @Expose()
  @ValidateIf((o) => o.isAuthorized)
  @IsDefined()
  isApplyingOnBehalfSpousePartner?: boolean;
}
