import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Auth } from 'aws-amplify';

import { Observable, from } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable()
export class CognitoTokenInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(Auth.currentSession()).pipe(
      mergeMap((session) =>
        next.handle(
          request.clone({
            headers: request.headers.set('Authorization', `${session.getIdToken().getJwtToken()}`),
          })
        )
      ),
      catchError((e) => next.handle(request))
    );
  }
}
