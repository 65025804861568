import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { CalendarEvent, CalendarNativeDateFormatter, DateFormatterParams } from 'angular-calendar';
import * as dayjs from 'dayjs';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CalendarView, WorkerCalendarEventCategory, WorkerCalendarEvent } from './worker-calendar.interface';

@Injectable({ providedIn: 'root' })
export class ShortDayNameFormatter extends CalendarNativeDateFormatter {
  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date);
  }
}

@Component({
  selector: 'common-worker-calendar',
  templateUrl: './worker-calendar.component.html',
  styleUrls: ['./worker-calendar.component.scss'],
})
export class WorkerCalendarComponent implements OnInit, OnDestroy, AfterViewInit {
  private _activeDay: dayjs.Dayjs;
  private _destroy$ = new Subject<void>();

  //how many events can fit in a cell
  EVENTS_PER_DAY = 3;
  CalendarView = CalendarView;

  _currentMonthLabel;
  _previousMonthLabel;
  _nextMonthLabel;

  _showFilters = false;

  _eventCategories: WorkerCalendarEventCategory[];
  _events: CalendarEvent[] = [];

  _formGroup = this.formBuilder.group({
    activeDay: [],
  });

  /**
   * The events to show
   */
  @Input() set events(value: WorkerCalendarEvent<unknown>[]) {
    this._events =
      value?.map((e) => {
        return {
          title: e.title,
          start: e.startDate,
          // allDay: true,
          end: e.endDate,
          meta: e,
        };
      }) ?? [];

    this._eventCategories = value
      ?.map((e) => e.category)
      ?.filter((v, i, a) => a.map((v2) => v2.name).indexOf(v.name) === i);
  }

  /**
   * Day selected.  Also determines what month is showing
   */
  @Input() set activeDay(value: Date) {
    this._activeDay = dayjs(value);
    this._formGroup.get('activeDay').setValue(value, { emitEvent: false });
    this._currentMonthLabel = this._activeDay.format('MMMM');
    this._previousMonthLabel = this._activeDay.add(-1, 'month').format('MMMM');
    this._nextMonthLabel = this._activeDay.add(1, 'month').format('MMMM');
  }
  get activeDay(): Date {
    return this._activeDay.toDate();
  }

  /**
   * Display loading indicator
   */
  @Input() isLoading = false;

  /**
   * Was the limit of events reached (paging)
   */
  @Input() hasMore = false;

  /**
   * Allow new events in the past
   */
  @Input() allowEventsInPast = false;

  /**
   * Hide the legend
   */
  @Input() hideLegend = true;

  /**
   * Hide the filters
   */
  @Input() hideFilters = true;

  /**
   * Show month/week/or day view
   */
  @Input() calendarView: CalendarView = CalendarView.Month;

  @Output() eventEdited = new EventEmitter<CalendarEvent>();
  @Output() eventAdded = new EventEmitter<Date>();
  @Output() eventDeleted = new EventEmitter<CalendarEvent>();
  @Output() activeDayChanged = new EventEmitter<Date>();
  @Output() calendarViewChanged = new EventEmitter<CalendarView>();

  constructor(private formBuilder: UntypedFormBuilder, private cdr: ChangeDetectorRef) {
    this.activeDay = new Date();
  }

  ngOnInit() {
    this._formGroup
      .get('activeDay')
      .valueChanges.pipe(takeUntil(this._destroy$))
      .subscribe((value) => {
        this.updateActiveDayAndEmit(value);
      });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  ngAfterViewInit() {
    const days = document.getElementsByClassName('day');
    const EVENT_HEIGHT = 22; //from SCSS.. can we do dynamic?  We don't have data loaded yet to query
    if (days && days.length > 0) {
      this.EVENTS_PER_DAY = Math.floor(days[0].clientHeight / EVENT_HEIGHT) - 1;
      this.cdr.detectChanges();
    }
  }

  setCalendarView(view: CalendarView) {
    this.calendarView = view;
    this.calendarViewChanged.emit(view);
  }

  goToToday() {
    this.updateActiveDayAndEmit(new Date());
  }

  isInPast(date: Date) {
    return dayjs(date).isBefore(new Date(), 'day');
  }

  onDayClicked(day: Date) {
    this.updateActiveDayAndEmit(day);
  }

  isDayActive(day) {
    return this._activeDay.isSame(day.date, 'day');
  }

  previousMonthClick() {
    switch (this.calendarView) {
      case CalendarView.Week:
        this.updateActiveDayAndEmit(this._activeDay.add(-1, 'week').toDate());
        break;
      case CalendarView.Month:
        this.updateActiveDayAndEmit(this._activeDay.add(-1, 'month').toDate());
        break;
      case CalendarView.Day:
        this.updateActiveDayAndEmit(this._activeDay.add(-1, 'day').toDate());
        break;
    }
  }

  nextMonthClick() {
    switch (this.calendarView) {
      case CalendarView.Week:
        this.updateActiveDayAndEmit(this._activeDay.add(1, 'week').toDate());
        break;
      case CalendarView.Month:
        this.updateActiveDayAndEmit(this._activeDay.add(1, 'month').toDate());
        break;
      case CalendarView.Day:
        this.updateActiveDayAndEmit(this._activeDay.add(1, 'day').toDate());
        break;
    }
  }

  addEventClick() {
    this.eventAdded.emit(this._activeDay.toDate());
  }

  eventClick(event) {
    this.eventEdited.emit(event.meta);
  }

  private updateActiveDayAndEmit(activeDay: Date) {
    this.activeDay = activeDay;
    this.activeDayChanged.emit(this.activeDay);
  }
}
