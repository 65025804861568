import { Expose } from 'class-transformer';
import { IsDefined } from 'class-validator';

export class Review {
  @Expose()
  @IsDefined()
  hasBeenVisited: boolean;

  @Expose()
  isDeclared: boolean;
}
