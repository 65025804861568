import { AbstractControl, ValidatorFn } from '@angular/forms';

export class ServiceNowTicketValidator {
  static isValid: ValidatorFn = (control: AbstractControl) => {
    let isValid = true;

    if (control.value && control.value.length > 0) {
      const reg = /^AFF\d{7}$/;
      isValid = reg.test(control.value);
    }

    return isValid ? null : { serviceNowTicket: true };
  };
}
