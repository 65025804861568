import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatChip, MatChipList } from '@angular/material/chips';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'common-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  //no longer providing NG_VALUE_ACCESSOR here as we need access to NgControl in constructor
  //for invalid styling.  So we set the valueAccessor there
  // providers: [
  //   {
  //     provide: NG_VALUE_ACCESSOR,
  //     // eslint-disable-next-line @typescript-eslint/no-use-before-define
  //     useExisting: MultiSelectComponent,
  //     multi: true,
  //   },
  // ],
})
export class MultiSelectComponent implements AfterViewInit, ControlValueAccessor {
  @ViewChild(MatChipList) chipList!: MatChipList;
  value: string[] = [];
  disabled = false;
  isFocused = false;

  @Input() options: { value: string; displayValue: string }[];
  @Output() change: EventEmitter<string[]> = new EventEmitter();

  //#region ControlValueAccessor stuff

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange!: (value: string[]) => void;
  onTouch!: () => void;

  writeValue(value: string[]): void {
    // When form value set when chips list initialized
    if (this.chipList && value) {
      this.selectChips(value);
    } else if (value) {
      // When chips not initialized
      this.value = value;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  propagateChange(value: string[]) {
    if (this.onChange) {
      this.onChange(value);
      this.change.emit(value);
    }
  }

  //#endregion

  constructor(private control: NgControl, private changeDetectorRef: ChangeDetectorRef) {
    this.control.valueAccessor = this;
  }

  public get invalid(): boolean {
    return this.control ? this.control.invalid : false;
  }

  public get showError(): boolean {
    if (!this.control) return false;

    const { dirty, touched } = this.control;

    return this.invalid ? dirty || touched : false;
  }

  ngAfterViewInit() {
    this.selectChips(this.value);

    this.chipList.chipSelectionChanges
      .pipe(
        untilDestroyed(this),
        map((event) => event.source)
      )
      .subscribe((chip) => {
        if (chip.selected) this.value = [...this.value, chip.value];
        else this.value = this.value.filter((o) => o !== chip.value);

        this.onTouch();
        this.propagateChange(this.value);
      });

    this.chipList.chipFocusChanges.subscribe(() => {
      this.isFocused = this.chipList.focused;
    });

    this.chipList.chipBlurChanges.subscribe(() => {
      this.isFocused = this.chipList.focused;
      if (!this.isFocused) this.onTouch();
    });

    this.changeDetectorRef.detectChanges();
  }

  toggleSelection(chip: MatChip) {
    if (!this.disabled) {
      chip.toggleSelected();
    }
  }

  selectChips(value: string[]) {
    this.chipList.chips.forEach((chip) => chip.deselect());

    const chipsToSelect = this.chipList.chips.filter((c) => value.includes(c.value));

    chipsToSelect.forEach((chip) => chip.select());
  }
}
