import { PayeeRole } from '../enums/payee-role.enum';
import { Applicant } from './applicant.entity';
import { Dependent } from './dependent.entity';
import { Spouse } from './spouse.entity';

export type PayeeInfo = {
  payee: Applicant | Spouse | Dependent;
  payeeRole: PayeeRole;
  total: number;
  monthly: number | undefined;
  isSharedCustody: boolean;
  startingMonth: string | undefined;
  endingMonth: string | undefined;
  isDuplicate: boolean;
  payingPrograms: string[];
};
