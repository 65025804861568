import { Component, ElementRef, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NgControl,
  Validators,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { extractTouchedChanges } from '../shared/helpers/touched-changes';
import { SELECT_ONE_TEXT } from '@common/constants';
@Component({
  selector: 'common-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() showErrorMessages = true;
  @Input() hideDay = false;
  @Input() dataCyPrefix = '';
  @Input() allowDefaultSelection = false;
  @Input() defaultSelectionLabel = SELECT_ONE_TEXT;

  @Input() formControlName: string;
  @Input() formControl: AbstractControl;

  @ViewChild('yearControl') yearInput: ElementRef;

  _months = [];
  dateFormControl: AbstractControl;
  private _maxDays = 31;
  formGroup = this.fb.group(
    {
      day: this.fb.control(null, { updateOn: 'blur', validators: [Validators.min(1), Validators.max(this._maxDays)] }),
      month: this.fb.control(null),
      year: this.fb.control(null, { updateOn: 'blur' }),
    },
    { emitEvent: false }
  );
  private value: Date;
  private touched;
  private changed;
  private destroy$ = new Subject<void>();
  constructor(
    @Self()
    @Optional()
    private ngControl: NgControl,
    private fb: UntypedFormBuilder,
    private controlContainer: ControlContainer
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
    for (let i = 0; i < 12; i++) this._months.push(this.createMonth(i));
  }
  ngOnInit(): void {
    if (this.formControl) this.dateFormControl = this.formControl;
    else {
      const containnerFormGroup = this.controlContainer.control as UntypedFormGroup;
      this.dateFormControl = containnerFormGroup.get(this.formControlName);
    }

    this.formGroup.controls.month.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      // this is only for case management employment page. when select Current Employer, need to clear
      if (this.defaultSelectionLabel === 'Current Employer' && !value) this.yearControl.setValue(null);
    });

    this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.updateControlValue(value);
    });

    extractTouchedChanges(this.dateFormControl)
      .pipe(takeUntil(this.destroy$))
      .subscribe((touched: boolean) => {
        if (touched) this.formGroup.markAllAsTouched();
      });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
  private isFirstTime = true;
  writeValue(obj: Date | string | null): void {
    if (obj) this.value = (typeof obj === 'string' && new Date(obj)) || (obj as Date);
    else this.value = null;
    this.setFormValue();
    this.isFirstTime = false;
  }

  registerOnChange(fn): void {
    this.changed = fn;
  }
  registerOnTouched(fn): void {
    this.touched = fn;
  }
  setDisabledState?(): void {
    this.formGroup.disable({ emitEvent: false });
  }
  onYearMonthChanged() {
    this.resetMaxDaysValidator();
  }
  onBlur() {
    // Value for blur CVA won't fire unless touched is called, but intake only wants touched called when all fields have been touched.  This whole control needs a rewrite lot's of hacks in it
    if (
      this.value ||
      (this.formGroup.get('day').touched && this.formGroup.get('month').touched && this.formGroup.get('year').touched)
    )
      this.touched();
  }

  private setFormValue() {
    if (this.value) {
      this.formGroup.setValue(
        { day: this.value.getUTCDate(), month: this.value.getUTCMonth() + 1, year: this.value.getUTCFullYear() },
        { emitEvent: false }
      );
      this.setFormValidators(this.formGroup.value);
      this.resetMaxDaysValidator();
    } else this.formGroup.setValue({ day: this.hideDay ? '1' : null, month: null, year: null }, { emitEvent: false });
  }
  private resetMaxDaysValidator() {
    const dateValue = this.formGroup.value;

    //grab the year from the actual control as the formgroup value won't be updated until the field is blurred and is then unreliable here.
    let year = Number.parseInt(this.yearInput?.nativeElement?.value);
    if (Number.isNaN(year)) {
      //if year is NaN use 2012 (leap year)
      year = 2012;
    } else if (year < 1000) {
      //if year is not 4 digits use 2012 (leap year)
      year = 2012;
    }

    const newMaxDays = new Date(year, dateValue.month || 1, 0).getDate();
    if (this._maxDays !== newMaxDays) {
      this._maxDays = newMaxDays;
      this.dayControl.setValidators([Validators.min(1), Validators.max(this._maxDays), Validators.required]);
      this.dayControl.updateValueAndValidity();
    }
  }

  private setFormValidators(dateValue) {
    if (!dateValue?.year && !dateValue?.month && (this.hideDay || !dateValue?.day)) {
      this.yearControl.setValidators([Validators.minLength(4), Validators.maxLength(4)]);
      this.monthControl.setValidators([]);
      this.dayControl.setValidators([Validators.min(1), Validators.max(this._maxDays)]);
    } else {
      this.yearControl.setValidators([Validators.required, Validators.minLength(4), Validators.maxLength(4)]);
      this.monthControl.setValidators([Validators.required]);
      this.dayControl.setValidators([Validators.required, Validators.min(1), Validators.max(this._maxDays)]);
    }
    this.dayControl.updateValueAndValidity({ emitEvent: false });
    this.monthControl.updateValueAndValidity({ emitEvent: false });
    this.yearControl.updateValueAndValidity({ emitEvent: false });
  }

  private updateControlValue(dateValue) {
    this.setFormValidators(dateValue);

    if (this.formGroup.invalid) {
      this.dateFormControl.setErrors({ datePicker: true });
      this.changed();
    } else {
      if (dateValue.year && dateValue.month && dateValue.day)
        this.setValue(new Date(Date.UTC(dateValue.year, dateValue.month - 1, dateValue.day, 11)));
      else this.setValue(null);
    }
  }

  private setValue(newValue: Date | null) {
    this.value = newValue;
    if (this.changed) this.changed(newValue?.toISOString()?.slice(0, 10));
  }

  private createMonth(month: number) {
    return { label: new Date(2020, month, 1).toLocaleString('en-CA', { month: 'long' }), value: month + 1 };
  }

  get yearControl() {
    return this.formGroup.controls.year;
  }
  get monthControl() {
    return this.formGroup.controls.month;
  }
  get dayControl() {
    return this.formGroup.controls.day;
  }
}
