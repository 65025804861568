import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private config;
  private lastFetch: number;

  constructor(private http: HttpClient) {}

  public loadConfig() {
    const getConfig$ = this.http.get('./assets/config/configMap/config.json');
    const getFeatures$ = this.http.get('./cdn/features.json');

    return combineLatest([getConfig$, getFeatures$])
      .pipe(
        map(([loadedConfig, loadedFeatures]) => {
          const features = {
            features: {...loadedFeatures, hasSunset: this.hasSunset(loadedFeatures?.['sunset-date'])},
          };
          
          return {
            ...loadedConfig,
            ...features,
          };
        })
      )
      .toPromise()
      .then((config) => {
        this.config = config;
        this.lastFetch = Date.now();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getConfig() {
    return this.config;
  }

  getFeatures(): { [key: string]: boolean } {
    return this.config?.features;
  }

  /**
   * Get the cached config or fetch a fresh one if expired.
   * @param expirationMS lifetime of the cached config in milliseconds.
   */
  public async cachedGetConfig(expirationMS: number): Promise<any> {
    const timeSinceLast = Date.now() - this.lastFetch;
    if (!this.config || timeSinceLast > expirationMS) {
      await this.loadConfig();
    }

    return this.config;
  }

  hasSunset(sunsetDateVal): boolean {
    if (sunsetDateVal) {
      return new Date().getTime() > new Date(sunsetDateVal).getTime();
    }
      
    return false;
  }
}
