import { AbstractControl, ValidatorFn } from '@angular/forms';

export class EmailValidator {
  // validate comma separated list of emails
  static EmailListValidator: ValidatorFn = (control: AbstractControl) => {
    let validEmail = true;
    const pattern = new RegExp('^([a-z0-9\\+_\\-]+)(\\.[a-z0-9\\+_\\-]+)*@([a-z0-9\\-]+\\.)+[a-z]{2,6}$', 'i');

    if (control.value && !control.invalid) {
      const emails = control.value
        .replace(/\s/g, '')
        .split(',')
        .filter((mail: any) => mail.trim());
      for (const email of emails) {
        if (validEmail && email && !pattern.test(email)) {
          validEmail = false;
        }
      }
    }
    return validEmail ? null : { email: true };
  };
}
