<ng-container *ngIf="!hasSunset; else sunset">
  <h2>Program description</h2>
  <p>
    The Government of Alberta is committed to helping Albertans with the rising cost of inflation by providing
    affordability payments to those who qualify. Eligible seniors and families with children under 18 can apply to
    receive $600 over six months. Learn more about the Alberta
    <a href="https://www.alberta.ca/affordability-payments.aspx">affordability payments program. </a>
  </p>

  <h2>Who is eligible</h2>
  <ul class="compact">
    <li>Seniors with household incomes under $180,000 can apply for $600 per person.</li>
    <li>Families with household incomes under $180,000 can apply for $600 per child under 18.</li>
    <li>
      Albertans receiving core benefits or supports will automatically receive $600 per person, no application required.
    </li>
  </ul>

  <ul class="sub-compact">
    <li>AISH</li>
    <li>Income Support</li>
    <li>Alberta Seniors benefit</li>
    <li>Persons with Developmental Disability services</li>
    <li>Family and kinship caregivers of children under 18</li>
    <li>
      CPP Disability Note: eligibility is based on 2021 tax return information from the Canada Revenue Agency.
      Individuals who did not file a tax return or who began receiving CPPD benefits on or after January 1, 2022 need to
      apply by calling
      <span class="tel"><a href="tel:18446449955">1-844-644-9955 </a><span>1-844-644-9955</span></span>
    </li>
  </ul>

  <h2>How to apply</h2>
  Complete your application in two simple steps:

  <ol class="compact">
    <li>Answer questions about your household to determine eligibility.</li>
    <li>
      Sign in with your pending or verified Alberta.ca Account to fill out your application in a secure environment.
      <br />
      <ul class="compact-sub-item">
        <li>
          If you do not have an account, you will be prompted to create one. A valid Alberta Drivers’ Licence or ID is
          required.
        </li>
      </ul>
    </li>
    <li>
      Have Social Insurance Number(s) for yourself and spouse/partner, if applicable, ready to complete your family’s
      application.
    </li>
  </ol>

  <div class="landing-button">
    <button mat-button mat-flat-button color="primary" data-cy="apply-button" routerLink="/prescreen">
      Create application
    </button>
    <div class="info-text">
      <strong>To find out if you are eligible for this benefit, or to create your application </strong>
    </div>
  </div>
  <div class="landing-button">
    <button
      mat-button
      mat-flat-button
      color="secondary"
      data-cy="app-status-button"
      routerLink="/my-application-status"
    >
      View application
    </button>
    <div class="info-text">
      <strong> To resume your incomplete application or view your completed application and payment details</strong>
    </div>
  </div>
</ng-container>
<ng-template #sunset>
  <h2>This program is no longer accepting applications.</h2>
  <p>
    As of July 1, 2023 we are not accepting new applications. If you have questions about the Affordability Action Plan
    payments, you may contact our Information Line.
  </p>
  <p class="tel"><a href="tel:18446449955">1-844-644-9955 </a><span>Toll-free number: 1-844-644-9955</span></p>
  <ng-container>
    <p>For existing applications, sign in to view details.</p>
    <div class="landing-button">
      <button
        mat-button
        mat-flat-button
        color="secondary"
        data-cy="app-status-button"
        routerLink="/my-application-status"
      >
        Sign in
      </button>
    </div>
  </ng-container>
</ng-template>
