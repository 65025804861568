import { Component } from '@angular/core';
import { TrimInputControls } from '@common/ui/utils';

TrimInputControls.register();
@Component({
  selector: 'aff-application-page',
  templateUrl: './application-page.component.html',
  styleUrls: ['./application-page.component.scss'],
})
export class ApplicationPageComponent {}
