import { Expose } from 'class-transformer';
import { Equals, IsNotEmpty } from 'class-validator';

export class IncomeEligibilityOverride {
  @Expose()
  @IsNotEmpty()
  applicationId: string;

  @Expose()
  @IsNotEmpty()
  ticketNumber: string;

  @Expose()
  @Equals(true)
  isDeclared: boolean;
}
