import { FormGroup, ValidatorFn } from '@angular/forms';
import { isNullUndefinedOrEmpty } from '../helpers/utils';

export class RequiredValidator {
  //formGroup level validator
  static oneOfTheseRequiredValidator(inputNames: string[]): ValidatorFn {
    return function (formGroup: FormGroup) {
      const isValid = inputNames.some((inputName) => !isNullUndefinedOrEmpty(formGroup.get(inputName)?.value));
      const isAnyTouched = inputNames.some((inputName) => formGroup.get(inputName).touched);

      //update the error state of each of the fields (so it highlights if invalid)
      inputNames.forEach((inputName) => {
        const input = formGroup.get(inputName);
        if (isValid) {
          const errors = input.errors;
          if (errors) {
            delete errors['oneOfTheseRequiredValidator'];

            if (!Object.keys(errors).length) {
              input?.setErrors(null);
            } else {
              input?.setErrors(errors);
            }
          }
        } else {
          input?.setErrors({ ...input.errors, oneOfTheseRequiredValidator: true });
        }

        //if any of the "one of these" inputs has been touched, mark them all as touched
        //so the error highlight displays
        if (isAnyTouched) {
          input.markAsTouched();
        }
      });

      return isValid ? null : { oneOfTheseRequiredValidator: true };
      // if (isValid) {
      //   return null;
      // } else {
      //   //update the error state of each of the fields so it highlights
      //   inputNames.forEach((inputName) => {
      //     const input = formGroup.get(inputName);
      //     input?.setErrors({ ...input.errors, oneOfTheseRequiredValidator: true });
      //   });

      //   return { oneOfTheseRequiredValidator: true };
      // }
    };
  }
}
