import { RoleLookup } from '../entities/role-lookup.interface';

/*
Priority number controls which rolename displays.  Lowest number takes priority if a user has 2 or more roles.
For example, if a Regional Assigner (88) is also a CEC Supervisor (20), the title will show as CEC Supervisor.
*/

export class AffRoles {
  public static readonly AffAdmin: RoleLookup = { displayValue: 'Admin', code: 'AffAdmin', priority: 1 };
  public static readonly AffService: RoleLookup = { displayValue: 'Service Acct', code: 'AffService', priority: 2 };

  public static readonly AllValues = [AffRoles.AffAdmin, AffRoles.AffService];
}
