export interface StepperStep {
  code: string;
  name: string;
  url: string;
  state: string;
  hasError: boolean;
  active: boolean;
  statusMessage?: string;
  isEditable: boolean;
}
