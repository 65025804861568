import { RouteInfo } from '../interfaces/route-info.interface';

export class ResolutionRoutes {
  public static readonly Resolution: RouteInfo = {
    baseUrl: 'resolution',
    fullUrl: 'resolution',
    name: 'Resolution',
  };
  public static readonly Overview: RouteInfo = {
    baseUrl: 'overview',
    fullUrl: 'overview',
    name: 'Resolution Overview',
  };
  public static readonly Edit: RouteInfo = {
    baseUrl: 'edit',
    fullUrl: 'edit',
    name: 'Resolution Edit',
  };

  public static readonly ALL = [ResolutionRoutes.Resolution, ResolutionRoutes.Overview, ResolutionRoutes.Edit];
}
