import { AbstractControl, ValidatorFn } from '@angular/forms';
export class ExactValueValidator {
  static match(value: any, label = ''): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.invalid || !control.value) return null;
      return (control.value !== value && { exact: { expected: value, actual: control.value, label: label } }) || null;
    };
  }

  static notMatch(valueFn: () => any, label = ''): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.invalid || !control.value) return null;
      return (control.value === valueFn() && { shouldNotMatch: { actual: control.value, label: label } }) || null;
    };
  }
}
