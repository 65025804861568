export class NameChangeReasons {
  public static misspelled = 'My name was misspelled';
  public static mistake = "Someone else's name was entered into my application by mistake";
  public static deceased = 'Applicant is deceased';
  public static official = 'Official name change';
}

export const allNameChangeReasons = [
  NameChangeReasons.misspelled,
  NameChangeReasons.mistake,
  NameChangeReasons.deceased,
  NameChangeReasons.official,
];
