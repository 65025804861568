import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigService } from '@common/ui/shared-components';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HealthCheckService {
  constructor(private http: HttpClient, private configService: AppConfigService) {}

  isServicesAlive(): Promise<boolean> {
    const AFF_APPLY_SERVICE_ENDPOINT = this.configService.getConfig().AFF_APPLY_SERVICE_URL;
    const index = AFF_APPLY_SERVICE_ENDPOINT.lastIndexOf('/');
    const serviceUrl = AFF_APPLY_SERVICE_ENDPOINT.substring(0, index) + '/api/health/live';
    return (
      this.http
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .get<any>(serviceUrl)
        .pipe(take(1))
        .toPromise()
        .then((res) => {
          return res['aff-apply-api'].status === 'up';
        })
        .catch(() => {
          return false;
        })
    );
  }
}
