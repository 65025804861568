import { Directive } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { mod10Validation } from '@common/validation';

@Directive({
  selector: '[sinValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: SinValidateDirective,
      multi: true,
    },
  ],
})
export class SinValidateDirective implements Validator {
  validate(control: AbstractControl) {
    return mod10Validation(control.value, 8);
  }
}
