import { Lookup } from '@common/constants';

export class PaymentMethod {
  static readonly DirectDeposit: Lookup = { displayValue: 'Direct deposit', code: 'DirectDeposit' };
  static readonly Cheque: Lookup = { displayValue: 'I do not have a bank account', code: 'Cheque' };

  static readonly ALL = [PaymentMethod.DirectDeposit, PaymentMethod.Cheque];
}

export const PaymentMethods = {};
for (const [key, value] of Object.entries(PaymentMethod)) {
  PaymentMethods[key] = value as Lookup;
}
