import {
  AffApplication,
  AffApplyRoutes,
  FindAddressResult,
  FindVerifiedApplicationResult,
  FindVerifiedApplicationsInput,
  IncomeEligibilityOverride,
  ProcessedApplication,
  RetrieveAddressResult,
  StepperStep,
} from '@aff-apply/entities';
import { Router } from '@angular/router';
import { Utilities } from '@common/utils';
import { Injectable } from '@nestjs/common';
import { Observable, map } from 'rxjs';

@Injectable()
export abstract class GraphqlService {
  constructor(private router: Router, private redirectOnDuplicates: boolean) {}

  abstract findVerifiedApplications(
    findVerifiedApplicationsInput: FindVerifiedApplicationsInput
  ): Observable<Array<FindVerifiedApplicationResult>>;
  abstract getApplicationById(applicationId: string): Observable<AffApplication>;
  abstract getApplicationByIdFailSafe(applicationId: string): Observable<AffApplication>;

  abstract getProcessedApplicationById(applicationId: string): Observable<ProcessedApplication>;
  abstract getVerifiedApplicationById(applicationId: string): Observable<AffApplication>;
  abstract getStepperApplicationInfo(applicationId: string): Observable<AffApplication>;

  abstract getStepInfo(applicationId: string, step?: StepperStep): Observable<AffApplication>;

  abstract createApplication(affApplication: AffApplication): Observable<AffApplication>;

  abstract updateBankingInfo(affApplication: AffApplication): Observable<AffApplication>;

  abstract updateAddressInfo(affApplication: AffApplication): Observable<AffApplication>;

  abstract addDependent(affApplication: AffApplication): Observable<AffApplication>;

  abstract saveApplication(affApplication: AffApplication): Observable<AffApplication>;

  abstract findAddress(searchTerm: string): Observable<Array<FindAddressResult>>;

  abstract retrieveAddress(id: string): Observable<RetrieveAddressResult>;

  protected mapOutputForApplication(getApplication: (d) => AffApplication, removeTypes = true) {
    return map(({ data }: any) => {
      const application = getApplication(data);
      const cleaned = removeTypes ? Utilities.removeTypeNameFromObject(application) : application;
      return this.parseApplication(cleaned as AffApplication);
    });
  }

  protected parseApplication(app: AffApplication) {
    if (this.redirectOnDuplicates === true) {
      if (app.hasPossibleRelatedApplications === true) {
        this.router.navigate([AffApplyRoutes.ApplicationError.baseUrl, app.applicationCode]);
      }
    }
    return app;
  }

  abstract overrideIncomeEligibility(incomeEligibilityOverride: IncomeEligibilityOverride): Observable<boolean>;
}
