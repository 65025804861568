import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'age',
})
export class AgePipe implements PipeTransform {
  transform(dob: Date, dateOfDeath?: Date): unknown {
    const age = dayjs(dateOfDeath ?? new Date()).diff(dob, 'years');
    return age < 0 ? 0 : age;
  }
}
