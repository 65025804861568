<div [formGroup]="formGroup" class="client-intake-address">
  <div class="find-address">
    <common-form-control
      controlName="streetAddress"
      label="Mailing address"
      [isOptional]="!hideOptionalLabel && !_isRequired"
    >
      <input
        class="placement-name-input form-input"
        type="text"
        name="findAddress"
        placeholder="Start typing the first line of your address"
        aria-label="Address finder"
        matInput
        formControlName="streetAddress"
        [attr.data-cy]="dataCyPrefix + '-street'"
        [matAutocomplete]="auto"
      />
      <mat-icon class="search-icon">search</mat-icon>
      <button
        class="clear-find-address-btn link-btn"
        (click)="onClearFindAddress()"
        *ngIf="formGroup.controls.streetAddress.value?.length > 0 && auto.isOpen"
      >
        <mat-icon class="clear-icon">close</mat-icon>
      </button>

      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectAddress($event)">
        <mat-optgroup class="address-complete">
          <img src="assets/images/address-complete.svg" alt="Address complete" />
        </mat-optgroup>
        <ng-container *ngIf="isFindingAddresses">
          <mat-option class="address-option">
            <mat-progress-spinner class="spinner" mode="indeterminate" diameter="24"></mat-progress-spinner>
          </mat-option>
        </ng-container>
        <mat-option class="address-option" *ngFor="let option of findAddressResults" [value]="option">
          <div *ngIf="option.id !== null">
            <div class="text">{{ option.text }}</div>
            <div class="description">{{ option.description }}</div>
          </div>
        </mat-option>
      </mat-autocomplete>
      <!--Mailing address is required.-->
    </common-form-control>
  </div>
  <div>
    <common-form-control controlName="suiteNumber" [isOptional]="hideOptionalLabel">
      <input
        type="text"
        formControlName="suiteNumber"
        [attr.data-cy]="dataCyPrefix + '-suite'"
        placeholder="Unit number, suite, apartment"
      />
    </common-form-control>
  </div>
  <div class="postal-province-container" *ngIf="hideProvince">
    <common-form-control class="city" controlName="city" label="City" [isOptional]="!hideOptionalLabel && !_isRequired">
      <input type="text" formControlName="city" [attr.data-cy]="dataCyPrefix + '-city'" />
    </common-form-control>
    <common-form-control
      class="postal-code"
      controlName="postalCode"
      label="Postal code"
      [isOptional]="!hideOptionalLabel && !_isRequired"
    >
      <input
        [attr.data-cy]="dataCyPrefix + '-postalCode'"
        type="text"
        formControlName="postalCode"
        mask="S0S 0S0"
        placeholder="A0A 0A0"
        #postalCode
        iesPostalCodeValidator
        (input)="postalCode.value = postalCode.value.toUpperCase()"
      />
    </common-form-control>
  </div>
  <div class="postal-province-container" *ngIf="!hideProvince">
    <common-form-control class="city" controlName="city" label="City" [isOptional]="!hideOptionalLabel && !_isRequired">
      <input type="text" formControlName="city" [attr.data-cy]="dataCyPrefix + '-city'" />
    </common-form-control>
    <common-form-control
      class="province"
      label="Province"
      controlName="province"
      [isOptional]="!hideOptionalLabel && !_isRequired && _enableProvince"
    >
      <!--'Optional' label on province: show when province control is enabled & address is optional-->
      <mat-select
        formControlName="province"
        [attr.data-cy]="dataCyPrefix + '-province'"
        role="combobox"
        aria-label="province"
      >
        <mat-option *ngFor="let province of provinces" [value]="province">{{ province }}</mat-option>
      </mat-select>
    </common-form-control>
  </div>
  <div class="postal-province-container" *ngIf="!hideProvince">
    <common-form-control
      class="postal-code"
      controlName="postalCode"
      label="Postal code"
      [isOptional]="!hideOptionalLabel && !_isRequired"
    >
      <input
        [attr.data-cy]="dataCyPrefix + '-postalCode'"
        type="text"
        formControlName="postalCode"
        mask="S0S 0S0"
        [placeholder]="postalCodeFormat === 'AB' ? 'T0T 0T0' : 'A0A 0A0'"
        #postalCode
        iesPostalCodeValidator
        [postalCodeFormat]="postalCodeFormat"
        (input)="postalCode.value = postalCode.value.toUpperCase()"
      />
    </common-form-control>
  </div>
</div>
