import { FifBank } from '../entities/fif-bank.entity';
import { FifBranch } from '../entities/fif-branch.entity';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const FifData = require('./fif.json');

export class FifDataService {
  static findBankByNumber = (institutionNumber: string): FifBank | undefined => {
    const bankData = FifData.find((entry) => entry.m.i === institutionNumber)?.m;
    if (bankData) {
      return { institutionNumber: bankData.i, names: [...bankData.n] };
    }
    return undefined;
  };

  static findBranchByNumbers = (institutionNumber: string, transitNumber: string): FifBranch | undefined => {
    const branchData = FifData.find((entry) => entry.m.i === institutionNumber)?.b.find((b) => b.t === transitNumber);
    if (branchData) {
      return { transitNumber: branchData.t, address: branchData.a };
    }
    return undefined;
  };
}
