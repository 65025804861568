import { Lookup } from './lookup.interface';
export interface MartialStatusLookup extends Lookup {
  hasSpouseOrPartner?: boolean;
  label?: string;
  edbId?: string;
  lmtaDisplayValue?: string;
}
export const MaritalStatuses: { [name: string]: MartialStatusLookup } = {
  single: { displayValue: 'Single', lmtaDisplayValue: 'Single', code: 'single', edbId: '5' },
  married: {
    displayValue: 'Married',
    lmtaDisplayValue: 'Married or equivalent',
    code: 'married',
    label: 'Spouse',
    hasSpouseOrPartner: true,
    edbId: '3',
  },
  commonLaw: {
    displayValue: 'Common-law',
    lmtaDisplayValue: 'Married or equivalent',
    code: 'commonLaw',
    label: 'Partner',
    hasSpouseOrPartner: true,
    edbId: '4',
  },
  separated: {
    displayValue: 'Separated from spouse or partner',
    lmtaDisplayValue: 'Single',
    code: 'separated',
    edbId: '6',
  },
  divorced: { displayValue: 'Divorced', lmtaDisplayValue: 'Single', code: 'divorced', edbId: '2' },
  widowed: { displayValue: 'Widowed', lmtaDisplayValue: 'Single', code: 'widowed', edbId: '7' },
};

// interdependence was not wanted for the aish appliation
export const MaritalStatusWithInterdependence: { [name: string]: MartialStatusLookup } = {
  ...MaritalStatuses,

  interdependent: {
    displayValue: 'Relation of interdependence',
    lmtaDisplayValue: 'Married or equivalent',
    code: 'interdependent',
    hasSpouseOrPartner: true,
    label: 'Interdependent',
  },
};
