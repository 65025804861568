import { Pipe, PipeTransform } from '@angular/core';
import { Utilities } from '@common/utils';

@Pipe({ name: 'addMonths' })
export class AddMonthsPipe implements PipeTransform {
  transform(dateString: string, increment: number): Date | undefined {
    const date = Utilities.getDateFromString(dateString);
    if (!date) return undefined;

    date.setMonth(date.getMonth() + increment);
    return date;
  }
}
