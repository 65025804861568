import { ValidationOptions, ValidationArguments, ValidateBy } from 'class-validator';
import { DirectDeposit } from '../entities/direct-deposit.entity';
import { FifDataService } from '../services/fif-data.service';

export function IsKnownBranch(validationOptions?: ValidationOptions): PropertyDecorator {
  return ValidateBy(
    {
      name: 'IsKnownBranch',

      validator: {
        validate(value: unknown, args: ValidationArguments): boolean {
          const relatedValue: DirectDeposit = args.object as unknown as DirectDeposit;
          if (!value) return true;
          if (!relatedValue.institutionNumber) return true;
          return !!FifDataService.findBranchByNumbers(relatedValue.institutionNumber, value as string);
        },
        defaultMessage: () => 'No such transit number for this institution.',
      },
    },
    validationOptions
  );
}
