import { ValidatorConstraint, ValidatorConstraintInterface, ValidationArguments } from 'class-validator';
import { ClassValidationDecorator, registerClassValidator } from './class-level.validator';
import { isEmpty } from 'lodash';

@ValidatorConstraint()
class OneOfFieldsRequiredConstraint implements ValidatorConstraintInterface {
  validate(value: undefined, args: ValidationArguments): boolean {
    const [fields] = args.constraints;

    return fields.some((field: string) => field in args.object && !isEmpty(args.object[field]));
  }

  defaultMessage(args: ValidationArguments): string {
    const [fields] = args.constraints;

    return 'One of the following fields must have a value: ' + fields.join(', ');
  }
}

/**
 * A class level decorator to check if one of the fields specified has a value
 */
export function OneOfFieldsRequired(fields: string[]): ClassValidationDecorator {
  return registerClassValidator({
    name: 'OneOfFieldsRequired',
    validator: OneOfFieldsRequiredConstraint,
    constraints: [fields],
  });
}
