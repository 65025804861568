import { Component, Input, OnDestroy, TemplateRef, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import * as dayjs from 'dayjs';
import { CalendarEvent } from 'angular-calendar/modules/calendar.module';

const SATURDAY = 6;
const SUNDAY = 0;

@Component({
  selector: 'common-worker-calendar-week-view',
  templateUrl: './week-view.component.html',
  styleUrls: ['./week-view.component.scss'],
})
export class WorkerCalendarWeekView implements OnDestroy {
  _destroy$ = new Subject<void>();
  _weekDays: Date[] = [];
  _startDay;
  _endDay;
  _today = new Date();
  _includeWeekends = false;

  private _events: CalendarEvent[];
  @Input() set events(value) {
    this._events = value;
    this.setWeekdays();
  }
  get events() {
    return this._events;
  }
  private _viewDate = new Date();
  @Input() set viewDate(value) {
    this._viewDate = value;
    this.setWeekdays();
  }
  get viewDate() {
    return this._viewDate;
  }
  @Input() eventTemplate: TemplateRef<any>;
  @Input() isLoading = false;
  @Input() hasMore = false;

  @Output() dayClicked = new EventEmitter<Date>();

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  setWeekdays() {
    if (this.viewDate) {
      this._startDay = dayjs(this.viewDate).startOf('week');
      this._endDay = dayjs(this.viewDate).endOf('week');
      this._includeWeekends = false;
      this._weekDays = [0, 1, 2, 3, 4, 5, 6]
        .map((day) => {
          const newDay = this._startDay.add(day, 'day');
          if (newDay.day() === SUNDAY || newDay.day() === SATURDAY) {
            if (
              //check if there is an event on the weekend
              this._events?.find((e) => {
                return newDay.isSame(e.start, 'day') || newDay.isSame(e.end, 'day');
              })
            ) {
              this._includeWeekends = true;
            }
          }
          return newDay.toDate();
        })
        .filter((day) => day !== null);
    }
  }

  isToday(date) {
    return dayjs(date).isSame(this._today, 'day');
  }

  isWeekend(date) {
    return dayjs(date).day() === SUNDAY || dayjs(date).day() === SATURDAY;
  }

  isActiveDay(date) {
    return dayjs(date).isSame(this.viewDate, 'day');
  }

  setActiveDay(date) {
    this.dayClicked.emit(date);
  }

  //this is needed or otherwise ngFor rebuilds the dom on zone events and we lose clicks
  trackBy(index) {
    return index;
  }
}
