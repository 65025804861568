import * as CryptoJS from "crypto-js";

export class EncryptionUtils {
  constructor(private secretKey?: string) {
    //for testing only
    if (secretKey) {
      this.secretKey = secretKey;
    }else{
      //this.secretKey = 'vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3';
      //using https://onlinerandomtools.com/generate-random-string to generate 32 char alpha numeric (upper/lower case)
      this.secretKey = 'rpnXDyHbXOkHqdY5PgF1t87vel4VBGxL';
    }
  }
  encrypt(text: string) {
    if (!text){
      return null;
    }
    const encryptedText = CryptoJS.AES.encrypt(text, this.secretKey).toString();
    return encryptedText;
  }

  decrypt(text: string) {
    if (!text){
      return null;
    }
    //const decryptedText = CryptoJS.AES.decrypt(text, this.secretKey);
    // Decrypt
    const bytes  = CryptoJS.AES.decrypt(text, this.secretKey);
    const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedText;

  }
}
