import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@common/entities';

/**
 * Pipe for address.  Accepts a lineNumber arg.
 * line1 = street address
 * line2 = suite
 * line3 = city, province, postalcode
 * no line = suite-street, city, province, postalcode
 */
@Pipe({ name: 'address' })
export class AddressPipe implements PipeTransform {
  transform(address: Address, lineNumber?: 'line1' | 'line2'): string {
    if (!address || !address.streetAddress || !address.city || !address.postalCode || address.postalCode.length !== 6) {
      return '';
    }

    if (!lineNumber && address.suiteNumber) {
      return `${address.suiteNumber}-${address.streetAddress}, ${address.city}, ${
        address.province || 'Alberta'
      }, ${address.postalCode.slice(0, 3)} ${address.postalCode.slice(3)}`;
    }

    if (!lineNumber) {
      return `${address.streetAddress}, ${address.city}, ${address.province || 'Alberta'}, ${address.postalCode.slice(
        0,
        3
      )} ${address.postalCode.slice(3)}`;
    }

    if (lineNumber === 'line1') {
      return `${address.streetAddress} ${address.suiteNumber ? '#' + address.suiteNumber : ''}`;
    }

    if (lineNumber === 'line2') {
      return `${address.city}, ${address.province || 'Alberta'}, ${address.postalCode.slice(
        0,
        3
      )} ${address.postalCode.slice(3)}`;
    }
  }
}
