<div (focusout)="markTouched()" class="number-input-container">
  <button
    [disabled]="disabled || (min !== undefined && value <= min)"
    goa-button
    buttonType="primary"
    buttonSize="small"
    type="button"
    (click)="decrement()"
    aria-label="Subtract one"
  >
    <svg width="1em" height="100%" viewBox="0 0 100 100" stroke="#FFFFFF">
      <line x1="25%" y1="50%" x2="75%" y2="50%" stroke-width="15%" />
    </svg>
  </button>

  <input
    #numberInput
    type="number"
    [min]="min"
    [max]="max"
    [value]="value"
    [step]="step"
    (input)="handleInput($event)"
    [formControl]="formControl"
    [attr.aria-label]="ariaLabel"
  />

  <button
    goa-button
    [disabled]="disabled || (max !== undefined && value >= max)"
    buttonType="primary"
    buttonSize="small"
    type="button"
    (click)="increment()"
    aria-label="Add one"
  >
    <svg width="1em" height="100%" viewBox="0 0 100 100" stroke="#FFFFFF">
      <line x1="25%" y1="50%" x2="75%" y2="50%" stroke-width="15%" />
      <line x1="50%" y1="25%" x2="50%" y2="75%" stroke-width="15%" />
    </svg>
  </button>
</div>
