import { Expose } from 'class-transformer';
import { Equals, IsNotEmpty, IsOptional, ValidateIf } from 'class-validator';

export class ProofOfIdentity {
  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasAlbertaDriversLicense?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasAlbertaIdCard?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasCanadianCitizenshipCert?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasCanadaUkraineAuthForTravel?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasDefenseDriverPermit?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasDriversLicenseOrIdOutsideAlberta?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasMetisAlbertaCard?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasMilitaryIdCards?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasPermanentResidentCard?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasIndianStatusCard?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasTemporaryResidentOrRefugeeDoc?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasCanadianPassport?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasCertificateOfIdentity?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasForeignPassport?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasTravelDocForRefugees?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasUsPassportCard?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasFac?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasNexusCard?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasResidencyRequirements() === false)
  @Equals(true)
  hasInsurancePolicyDoc?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasResidencyRequirements() === false)
  @Equals(true)
  hasBankOrCreditCardStatements?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasResidencyRequirements() === false)
  @Equals(true)
  hasPayStub?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasResidencyRequirements() === false)
  @Equals(true)
  hasResidentialLeaseAgreement?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasResidencyRequirements() === false)
  @Equals(true)
  hasDeclarationThatIsAlbertaResident?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasResidencyRequirements() === false)
  @Equals(true)
  hasUtilityBills?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasResidencyRequirements() === false)
  @Equals(true)
  hasWrittenConfirmationFromAlbertaEdInstitute?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasResidencyRequirements() === false)
  @Equals(true)
  hasWrittenConfirmationOfAlbertaEmployement?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasBirthCert?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasMarriageCert?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasTemporaryConfirmationFromIndigenousServices?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @IsNotEmpty()
  otherIdentityDocs?: string;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @IsNotEmpty()
  otherNameAndDobDocs?: string;

  @Expose()
  @ValidateIf((o) => o.hasResidencyRequirements() === false)
  @IsNotEmpty()
  otherResidencyDocs?: string;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasSingleJourneyTravelDoc?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasResettlementDoc?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasEmergencyTravelDoc?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasStudyPermit?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasTemporaryResidentPermit?: boolean;

  @Expose()
  @ValidateIf((o) => o.hasIdentityRequirements() === false)
  @Equals(true)
  hasWorkPermit?: boolean;

  hasIdentityRequirements(): boolean {
    return (
      this.hasAlbertaDriversLicense === true ||
      this.hasAlbertaIdCard === true ||
      this.hasCanadianCitizenshipCert === true ||
      this.hasCanadaUkraineAuthForTravel === true ||
      this.hasDefenseDriverPermit === true ||
      this.hasDriversLicenseOrIdOutsideAlberta === true ||
      this.hasMetisAlbertaCard === true ||
      this.hasMilitaryIdCards === true ||
      this.hasPermanentResidentCard === true ||
      this.hasIndianStatusCard === true ||
      this.hasTemporaryResidentOrRefugeeDoc === true ||
      this.hasCanadianPassport === true ||
      this.hasCertificateOfIdentity === true ||
      this.hasForeignPassport === true ||
      this.hasTravelDocForRefugees === true ||
      this.hasUsPassportCard === true ||
      this.hasBirthCert === true ||
      this.hasMarriageCert === true ||
      this.hasTemporaryConfirmationFromIndigenousServices === true ||
      this.hasSingleJourneyTravelDoc === true ||
      this.hasResettlementDoc === true ||
      this.hasEmergencyTravelDoc === true ||
      this.hasStudyPermit === true ||
      this.hasTemporaryResidentPermit === true ||
      this.hasWorkPermit === true ||
      this.hasFac === true ||
      this.hasNexusCard === true ||
      this.otherNameAndDobDocs?.length > 0 ||
      this.otherIdentityDocs?.length > 0
    );
  }

  identityDocumentNames(): string[] {
    const namedIds = {
      hasAlbertaDriversLicense: "Alberta Driver's License",
      hasAlbertaIdCard: 'Alberta Id Card',
      hasCanadianCitizenshipCert: 'Canadian Citizenship Certificate with Photo (issued prior to Feb 1, 2012)',
      hasCanadaUkraineAuthForTravel: 'Canada-Ukraine authorization for emergency travel (CUAET)',
      hasDefenseDriverPermit: 'Department of National Defense (DND) Driver Permit (DND404)',
      hasDriversLicenseOrIdOutsideAlberta:
        'Driver’s licence or ID Card issued by an official government outside of Alberta',
      hasMetisAlbertaCard: 'Métis Nations of Alberta Card',
      hasMilitaryIdCards: 'Military ID cards NDI20 and NDI21',
      hasPermanentResidentCard: 'Permanent Resident Card',
      hasIndianStatusCard: 'Secure Certificate of Indian Status',
      hasTemporaryResidentOrRefugeeDoc: 'Temporary Resident Permit or Refugee Protection Claimant Document',
      hasCanadianPassport: 'Canadian Passport',
      hasCertificateOfIdentity:
        'Certificate of Identity (grey) – issued to Non-Canadians lawfully admitted to Canada as permanent residents who meet certain conditions',
      hasForeignPassport: 'Foreign Passport',
      hasTravelDocForRefugees:
        'Travel Document for refugees (blue) – issued to Non-Canadians who have been determined to have Convention refugee or “protected person” status in Canada',
      hasUsPassportCard: 'US Passport Card',
      hasBirthCert: 'Canadian Government-issued Birth Certificate',
      hasMarriageCert: 'Marriage Certificate',
      hasTemporaryConfirmationFromIndigenousServices:
        'Temporary Confirmation of Registration Document issued by Indigenous Services Canada',
      hasSingleJourneyTravelDoc: 'Single Journey Travel Document',
      hasResettlementDoc: 'Single Journey Travel Document for Resettlement to Canada',
      hasEmergencyTravelDoc: 'Emergency Travel Document for a Single Journey Only',
      hasStudyPermit: 'Study Permit',
      hasTemporaryResidentPermit: 'Temporary Resident Permit',
      hasWorkPermit: 'Work Permit',
      hasFac: 'Possession and Acquisition Licence or Firearms Acquisition Licence',
      hasNexusCard: 'Nexus Card',
      otherNameAndDobDocs: this.otherNameAndDobDocs,
      otherIdentityDocs: this.otherIdentityDocs,
    };

    return Object.entries(namedIds)
      .map(([k, v]) => this[k] && v)
      .filter((v) => v);
  }

  hasResidencyRequirements(): boolean {
    return (
      this.hasAlbertaDriversLicense === true ||
      this.hasAlbertaIdCard === true ||
      this.hasInsurancePolicyDoc === true ||
      this.hasBankOrCreditCardStatements === true ||
      this.hasPayStub === true ||
      this.hasResidentialLeaseAgreement === true ||
      this.hasDeclarationThatIsAlbertaResident === true ||
      this.hasUtilityBills === true ||
      this.hasWrittenConfirmationFromAlbertaEdInstitute === true ||
      this.hasWrittenConfirmationOfAlbertaEmployement === true ||
      this.otherResidencyDocs?.length > 0
    );
  }

  residencyDocumentNames(): string[] {
    const nameOfDocs = {
      hasAlbertaDriversLicense: "Alberta Driver's License",
      hasAlbertaIdCard: 'Alberta Id Card',
      hasInsurancePolicyDoc: 'Insurance Policy pink card',
      hasBankOrCreditCardStatements: 'Bank Or Credit Card Statements',
      hasPayStub: 'Pay Stub',
      hasResidentialLeaseAgreement: 'Residential Lease Agreement',
      hasDeclarationThatIsAlbertaResident: 'Verbal declaration of residency in lieu of documentation',
      hasUtilityBills: 'Utility, Gas, Phone or Cable Bills',
      hasWrittenConfirmationFromAlbertaEdInstitute: 'Written confirmation from an Alberta Educational Institution',
      hasWrittenConfirmationOfAlbertaEmployement: 'Written confirmation of Alberta Employment',
      otherResidencyDocs: this.otherResidencyDocs,
    };

    return Object.entries(nameOfDocs)
      .map(([k, v]) => this[k] && v)
      .filter((v) => v);
  }
}
