import { Expose } from 'class-transformer';
import { IsDate, IsNotEmpty, IsOptional } from 'class-validator';

export class Tombstone {
  @Expose()
  @IsNotEmpty()
  firstName: string;

  @Expose()
  @IsOptional()
  middleName?: string;

  @Expose()
  @IsNotEmpty()
  lastName: string;

  @Expose()
  @IsNotEmpty()
  birthdate: string;
}
