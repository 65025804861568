import { RouteInfo } from '../interfaces/route-info.interface';

export class ClerkSupportRoutes {
  public static readonly ClerkSupport: RouteInfo = {
    baseUrl: 'clerk-support',
    fullUrl: 'clerk-support',
    name: 'Clerk Support',
  };
  public static readonly Dashboard: RouteInfo = {
    baseUrl: 'dashboard',
    fullUrl: 'dashboard',
    name: 'Clerk Dashboard',
  };
  public static readonly ApplicationSubmitted: RouteInfo = {
    baseUrl: 'application-submitted',
    fullUrl: 'application-submitted',
    name: 'Application Submitted',
  };
  public static readonly Search: RouteInfo = {
    baseUrl: 'search',
    fullUrl: 'search',
    name: 'Search',
  };

  public static readonly ALL = [
    ClerkSupportRoutes.ClerkSupport,
    ClerkSupportRoutes.Dashboard,
    ClerkSupportRoutes.ApplicationSubmitted,
    ClerkSupportRoutes.Search,
  ];
}
