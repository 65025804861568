import { Lookup } from '@common/constants';

export class Region {
  static readonly North: Lookup = { displayValue: 'North', code: 'North' };
  static readonly South: Lookup = { displayValue: 'South', code: 'South' };

  static readonly ALL = [Region.North, Region.South];
}

export const Regions = {};
for (const [key, value] of Object.entries(Region)) {
  Regions[key] = value as Lookup;
}
