import { AppComponent } from './app.component';

export const ROUTES = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('@aff-apply/ui').then((module) => module.AffApplyModule),
      },
    ],
  },
];
