import 'reflect-metadata';

import { AngularComponentsModule } from '@abgov/angular-components';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { MaterialModule } from '@common/ui/material';
import {
  AppConfigService,
  CognitoTokenInterceptor,
  CustomReuseStrategy,
  DateInterceptor,
  ErrorIntercept,
  KeycloakUnauthInterceptor,
  UiSharedComponentsModule,
  UnauthenticatedInterceptor,
} from '@common/ui/shared-components';
import { KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';
import { ToastNoAnimation, ToastNoAnimationModule, ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { GraphQLModule } from './graphql/graphql.module';

import { Amplify } from 'aws-amplify';

export function initConfig(keycloakService: KeycloakService, appConfig: AppConfigService) {
  return async () => {
    await appConfig.loadConfig();
    // const url = appConfig.getConfig().KEYCLOAK_URL;
    // const realm = appConfig.getConfig().KEYCLOAK_REALM;
    // const clientId = appConfig.getConfig().KEYCLOAK_CLIENTID;
    // const keycloakHelper = new KeycloakInitializer(keycloakService, url, realm, clientId);
    // return keycloakHelper.initialize();
    if (window?.location?.origin) {
      //Amplify.Logger.LOG_LEVEL = 'DEBUG';

      Amplify.configure({
        Auth: {
          userPoolId: appConfig.getConfig().USER_POOL_ID,
          userPoolWebClientId: appConfig.getConfig().USER_POOL_WEBCLIENTID,
          identityPoolId: appConfig.getConfig().IDENTITY_POOL_ID,
          region: 'ca-central-1',
          //identityPoolRegion: 'ca-central-1',
          //aws_cognito_region: 'ca-central-1',
          oauth: {
            domain: appConfig.getConfig().USER_POOL_DOMAIN,
            scope: [`profile`, `openid`],
            redirectSignIn: `${window.location.origin}/dashboard`,
            redirectSignOut: window.location.origin,
            responseType: 'code',
          },
        },
      });
    }
    return null;
  };
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    GraphQLModule,
    RouterModule.forRoot(ROUTES, {
      paramsInheritanceStrategy: 'always',
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
    }),
    HttpClientModule,
    MaterialModule,
    ToastNoAnimationModule,
    ToastrModule.forRoot({
      toastComponent: ToastNoAnimation,
      disableTimeOut: false,
      timeOut: 5000,
      //maxOpened: 1,
      closeButton: true,
      autoDismiss: true,
      positionClass: 'toast-bottom-left',
    }),
    AngularComponentsModule,
    UiSharedComponentsModule,
    KeycloakAngularModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [KeycloakService, AppConfigService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CognitoTokenInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorIntercept, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: KeycloakUnauthInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
