import { AbstractControl, ValidatorFn } from '@angular/forms';

export class DateValidator {
  static maxDate(value: Date, label = '', message = null): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.invalid || !control.value) return null;
      return control.value > value ? { maxDate: { max: value, actual: control.value, label, message } } : null;
    };
  }
  static minDate(value: Date, label = '', message = null): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.invalid || !control.value) return null;
      return control.value < value ? { minDate: { min: value, actual: control.value, label, message } } : null;
    };
  }

  static maxYearValidator(value: number, label = ''): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.invalid || !control.value) return null;
      return control.value > value ? { maxDate: { max: value, actual: control.value, label: label } } : null;
    };
  }

  static minYearValidator(value: number, label = ''): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.invalid || !control.value) return null;
      return control.value < value ? { minDate: { min: value, actual: control.value, label: label } } : null;
    };
  }

  static isYearMonthFormat: ValidatorFn = (control: AbstractControl) => {
    return control.value === null ? { dateIsInvalid: true } : null;
  };
}
