//import { Address, User } from '@common/entities';
import { MaritalStatuses } from '@common/constants';
import { Expose, Type } from 'class-transformer';
import { IsDefined, IsOptional, ValidateIf, ValidateNested } from 'class-validator';

import { AffApplicationErrors } from './aff-application-errors.entity';
import { Applicant } from './applicant.entity';
import { BankingInfo } from './banking-info.entity';
import { BaseAuditableEntity } from './base-auditable.entity';
import { Dependent } from './dependent.entity';
import { Income } from './income.entity';
import { MySituation } from './my-situation.entity';
import { ProofOfIdentity } from './proof-of-identity.entity';
import { Review } from './review.entity';
import { Spouse } from './spouse.entity';
import { IncomeEligibilityOverride } from './income-eligibility-override.entity';

export class AffApplication extends BaseAuditableEntity {
  @Expose()
  applicationCode?: string;

  @Expose()
  @Type(() => MySituation)
  @ValidateNested()
  mySituation?: MySituation;

  @Expose()
  @Type(() => ProofOfIdentity)
  @ValidateNested()
  proofOfIdentity?: ProofOfIdentity;

  @Expose()
  @Type(() => Applicant)
  @ValidateNested()
  applicant: Applicant;

  @Expose()
  @Type(() => Spouse)
  @IsOptional()
  @ValidateIf((o) => o.maritalStatus && MaritalStatuses[o.maritalStatus]?.hasSpouseOrPartner)
  @ValidateNested()
  spousePartner?: Spouse;

  @Expose()
  @Type(() => Dependent)
  @IsOptional()
  @ValidateIf((o) => o.hasDependentChild)
  @ValidateNested({ each: true })
  dependents?: Dependent[];

  @Expose()
  @Type(() => Income)
  @ValidateNested()
  income: Income;

  @Expose()
  @Type(() => BankingInfo)
  @ValidateNested()
  bankingInfo: BankingInfo;

  @Expose()
  @Type(() => Review)
  @ValidateNested()
  review: Review;

  @Expose()
  @IsDefined()
  isSubmitted: boolean;

  @Expose()
  @IsOptional()
  incomeEligibilityOverride?: IncomeEligibilityOverride;

  @Expose()
  @Type(() => AffApplicationErrors)
  applicationErrors?: AffApplicationErrors;

  hasPossibleRelatedApplications?: boolean;
}
