import { AffApplyRoutes, ClerkType } from '@aff-apply/entities';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AppConfigService } from '@common/ui/shared-components';
import { KeycloakUserService } from './keycloak-user.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgramClosedRedirectGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private configService: AppConfigService,
    private keycloakService: KeycloakUserService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.runGuard(state);
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.runGuard(state);
  }

  private async runGuard(state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const currentRoute = state.url.split('?')[0];
    const redirectRoute = AffApplyRoutes.Welcome.fullUrl;
    if (currentRoute !== redirectRoute) {
      if (await this.shouldRedirect()) {
        return this.router.parseUrl(redirectRoute);
      }
    }

    return true;
  }

  private async shouldRedirect(): Promise<boolean> {
    const userInfo = await this.keycloakService.userInfo();
    return (
      this.configService.getFeatures()?.hasSunset &&
      userInfo?.clerkType !== ClerkType.GOA &&
      userInfo?.clerkType !== ClerkType.RESOLUTION
    );
  }
}
