import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AppConfigService } from '../../../ui-shared-components.services';

@Injectable({
  providedIn: 'root',
})
export class FeaturesGuard implements CanActivate {
  constructor(private configService: AppConfigService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const key = route?.data?.feature;

    if (!key) return false;

    const features = this.configService.getFeatures();

    return features && features[key] === true;
  }
}
