import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class CognitoLogoutGuard implements CanActivate {
  constructor(protected readonly router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Promise<boolean | UrlTree> | Observable<boolean | UrlTree> {
    const redirect = route.queryParamMap?.get('redirect');
    if (redirect)
      window?.sessionStorage?.setItem(
        'redirect',
        JSON.stringify({ expires: new Date().getTime() + 5000, value: redirect })
      );
    return Auth.signOut()
      .then(() => this.router.createUrlTree(['/'])) //the then part will not happen due to the nature of the oauth signout process
      .catch(() => this.router.createUrlTree(['/'])); //if the user is already signed out
  }
}
