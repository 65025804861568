import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'programName' })
export class ProgramNamePipe implements PipeTransform {
  transform(programCode: string): string {
    switch (programCode) {
      case 'IS':
        return 'Income Support';
      case 'AISH':
        return 'Assured Income for Severely Handicapped';
      case 'ASB':
        return 'Alberta Seniors Benefit';
      case 'PDD':
        return 'Persons with Developmental Disabilities';
      default:
        return programCode || '';
    }
  }
}
