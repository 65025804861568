export class PatchEntity {
  op: string;
  path: string;
  value: string; //serialize object to string for graphql compatibility
  old: string;
}

export class PatchesEntity {
  ops: Array<PatchEntity>;
  date: Date;
}
